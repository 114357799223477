import Box from "@mui/material/Box";
import ClaimableCommunityInfo from "../../components/ClaimableCommunityInfo";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import { StyledRewardCardImageStatus } from "../../components/StyledComponents";
import Tooltip from "@mui/material/Tooltip";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import moment from "moment";
import useGetScratchDetails from "../../hooks/queries/scratch/state/useGetScratchDetails";
import useSetUsed from "../../hooks/queries/scratch/mutations/useSetUsed";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ChangeEvent, useState } from "react";
import { useSnackbar } from "notistack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Scratch = () => {
  const { data: scratchDetails, isLoading: scratchDetailsLoading } =
    useGetScratchDetails();
  const { mutateAsync: scratch, isLoading: scratchLoading } = useSetUsed();
  const { enqueueSnackbar } = useSnackbar();
  const [agreement, setAgreement] = useState(false);

  const handleAgree = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
  };

  const handleScratch = () => {
    if (!agreement) {
      enqueueSnackbar({
        variant: "error",
        message:
          'Are you sure you want to scratch the NFT and mark it as redeemed?\nIf so please check the box before clicking on "Scratch"',
      });
      return;
    }
    scratch();
  };

  return (
    <Box sx={{ p: { xs: 1.5, md: 3 } }}>
      <Paper
        elevation={0}
        sx={{ width: 800, maxWidth: "100%", margin: "auto" }}
      >
        <Grid container>
          <Grid item xs={12} md={5}>
            <Card sx={{ position: "relative", boxShadow: "none" }}>
              {scratchDetails?.scratch?.status === "ended" && (
                <StyledRewardCardImageStatus>
                  <Typography variant="h6" fontWeight={900}>
                    Scratched
                  </Typography>
                </StyledRewardCardImageStatus>
              )}
              <CardMedia
                image={
                  scratchDetails?.scratch?.profile?.bannerImage || "default"
                }
                sx={{ width: "100%", aspectRatio: "1/1", boxShadow: "none" }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              pl={2}
              py={2}
              pr={2}
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <ClaimableCommunityInfo
                loading={scratchDetailsLoading}
                info={scratchDetails?.communityProfile}
              />
              {scratchDetailsLoading ? (
                <>
                  <Typography variant="subtitle2" mb={1} mt={2}>
                    <Skeleton width={180} />
                  </Typography>
                  <Typography variant="h6" mb={1}>
                    <Skeleton width={240} />
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    <Skeleton sx={{ mb: 0.5 }} />
                    <Skeleton sx={{ mb: 0.5 }} />
                    <Skeleton />
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6" fontWeight={700} mt={2}>
                    {scratchDetails?.scratch?.profile?.title}
                  </Typography>
                  <Typography variant="body2" mb={1}>
                    {scratchDetails?.scratch?.profile?.description}
                  </Typography>
                  {scratchDetails?.scratch?.claimExpirationDate && (
                    <Box>
                      <Tooltip title="Campaign end date" placement="top">
                        <Stack
                          display="inline-flex"
                          alignItems="center"
                          direction="row"
                          spacing={1}
                          mb={1}
                        >
                          <EventBusyRoundedIcon />
                          <Typography variant="subtitle2">
                            {moment(
                              scratchDetails?.scratch?.claimExpirationDate
                            )?.format("DD MMMM YYYY")}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </Box>
                  )}
                </>
              )}
              {!scratchDetailsLoading && (
                <Box mt="auto">
                  {scratchDetails &&
                    scratchDetails?.scratch?.status !== "ended" && (
                      <>
                        <FormControl
                          component="fieldset"
                          variant="standard"
                          sx={{ mb: 1 }}
                        >
                          <FormLabel
                            component="legend"
                            sx={{ fontSize: "0.8rem", mb: 0.5 }}
                          >
                            Once the reward is scratched, the reward is
                            considered to be redeemed and cannot be used. Please
                            scratch the reward only at the presents of an
                            official who will accept this reward
                          </FormLabel>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={agreement}
                                onChange={handleAgree}
                                color="primary"
                                sx={{ mt: -1 }}
                              />
                            }
                            label="I am scratching this reward at the appropriate time and place"
                            sx={{ alignItems: "flex-start" }}
                          />
                        </FormControl>
                        <LoadingButton
                          variant="contained"
                          loading={scratchLoading}
                          fullWidth
                          onClick={handleScratch}
                        >
                          Scratch
                        </LoadingButton>
                      </>
                    )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
export default Scratch;
