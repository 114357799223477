import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../helpers/ModalTransition";
import { Plan } from "../../types";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import ExpandableTypography from "../../components/ExpandableTypography";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import useGetTokenCampaignInfo from "../../hooks/queries/community/useGetTokenCampaignInfo";
import Loader from "../../components/Loader/Loader";
import Phase from "./Phase";
import Timeline from "@mui/lab/Timeline";
import { timelineItemClasses } from "@mui/lab/TimelineItem";

export type Props = {
  plan?: Plan;
  hide: () => void;
};

const ModalCampaignContract = ({ plan, hide }: Props) => {
  const { data, isLoading } = useGetTokenCampaignInfo(plan?.AStakingProgramId);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={!!plan}
      onClose={hide}
      keepMounted
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      TransitionComponent={ModalTransition}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" fontWeight="bold">
            {plan?.TokenData?.tokenMetadata && (
              <Tooltip
                title={`$${plan?.TokenData?.tokenMetadata?.symbol?.toUpperCase()}`}
                placement="top"
              >
                <Avatar
                  src={plan?.TokenData?.tokenMetadata?.uri}
                  alt={plan?.TokenData?.tokenMetadata?.name}
                  sx={{
                    width: 24,
                    height: 24,
                    flexShrink: 0,
                    verticalAlign: "text-bottom",
                    display: "inline-flex",
                    mr: "0.5rem",
                  }}
                  variant="circular"
                >
                  <InsertPhotoRoundedIcon sx={{ fontSize: "1rem" }} />
                </Avatar>
              </Tooltip>
            )}
            {plan?.PlanTitle}
          </Typography>
          <IconButton onClick={hide}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <ExpandableTypography
          text={plan?.PlanDescription?.trim() || ""}
          variant="body2"
          sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
        />
        {isLoading ? (
          <Stack alignItems="center" my={3}>
            <Loader size={36} />
          </Stack>
        ) : (
          <>
            <Typography variant="h6" fontWeight="bold" mt={2} mb={1}>
              Phases
            </Typography>

            <Timeline
              sx={{
                m: 0,
                p: 0,
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {data?.phases?.map((phase, i) => {
                let type: "Completed" | "In progress" | "Locked" = "Locked";
                if (i < data?.currentPhase) {
                  type = "Completed";
                } else if (i === data?.currentPhase) {
                  type = "In progress";
                  if (data?.status === "ended") {
                    type = "Completed";
                  }
                }
                return (
                  <Phase
                    phase={phase}
                    type={type}
                    isLast={i + 1 === data?.phases?.length}
                    index={i}
                    currentPhase={data?.currentPhase}
                    currentPhaseEndDate={data?.currentPhaseEndDate}
                    status={data?.status}
                    key={i}
                  />
                );
              })}
            </Timeline>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalCampaignContract;
