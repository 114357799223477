import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DetailsSolscan from "./DetailsSolscan";

const DetailsItem = ({
  label,
  type,
  value,
}: {
  label: string;
  type: "token" | "account" | "string";
  value: string;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" spacing={1}>
      <Typography color="text.secondary">{label}</Typography>
      {type === "string" ? (
        <Typography fontWeight={600}>{value}</Typography>
      ) : (
        <DetailsSolscan type={type} value={value} />
      )}
    </Stack>
  );
};

export default DetailsItem;
