export const getLevelBySession = (levels: any[], session: number) => {
  let level = 1;
  let remainingSessions = session;
  for (let i = 0; i < levels.length; i++) {
    const { stakingSession } = levels[i];
    if (stakingSession >= remainingSessions || levels.length === i + 1) break;
    level++;
    remainingSessions -= stakingSession;
  }
  return level;
};

export const formatNumberIntoView = (n: number, maximumDigits: number = 2) =>
  n?.toLocaleString("en-US", {
    minimumFractionDigits: maximumDigits,
    maximumFractionDigits: maximumDigits,
  });

export const getDecimalsForInterval = (n?: number) => {
  if (!n || n > 1) return 0;
  const afterDot = n?.toFixed(20).split(".")[1];
  let decimals = 1;
  for (const n of afterDot) {
    const int = parseInt(n);
    if (int > 0) break;
    decimals++;
  }
  return decimals > 18 ? 18 : decimals;
};

export const verifyDiscordRedirect = () => {
  // const redirect = "http://localhost:3000/verify";
  const redirect = "https://anybodies.com/verify";
  window.location.href = `https://discord.com/api/oauth2/authorize?client_id=915708397024854026&redirect_uri=${redirect}&response_type=code&scope=identify`;
};

export const BASE_API = "/api/v1/";

export const copyTextToClipboard = async (text: string) => {
  return "clipboard" in navigator
    ? await navigator.clipboard.writeText(text)
    : document.execCommand("copy", true, text);
};

export const stringToShorten = (str?: string) => {
  if (!str) return "";
  if (str.length < 8) return str;
  return `${str.slice(0, 4)}..${str.slice(-4)}`;
};

export const extractNftNumber = (s: string): string | null => {
  const match = s.match(/#(\d+)/);
  return match ? match[0] : null;
};

export function timeSince(date?: Date) {
  if (!date) return "";
  const now = new Date();
  const difference = now.getTime() - new Date(date).getTime();

  // Calculate seconds
  const seconds = Math.floor(difference / 1000);

  // If less than a minute, return seconds
  if (seconds < 60) {
    return `${seconds} second${seconds > 1 ? "s" : ""}`;
  }

  // Calculate minutes
  const minutes = Math.floor(seconds / 60);

  // If less than an hour, return minutes
  if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  // Calculate hours
  const hours = Math.floor(minutes / 60);

  // If less than a day, return hours
  if (hours < 24) {
    return `${hours} hour${hours > 1 ? "s" : ""}`;
  }

  // Calculate days
  const days = Math.floor(hours / 24);

  // If less than a week, return days
  if (days < 7) {
    return `${days} day${days > 1 ? "s" : ""}`;
  }

  // Calculate weeks
  const weeks = Math.floor(days / 7);

  // If less than a month, return weeks
  if (weeks < 4) {
    return `${weeks} week${weeks > 1 ? "s" : ""}`;
  }

  // Calculate months
  const months = Math.floor(weeks / 4);

  // If less than a year, return months
  if (months < 12) {
    return `${months} month${months > 1 ? "s" : ""}`;
  }

  const years = Math.floor(months / 12);
  return `${years} year${years > 1 ? "s" : ""}`;
}

export const percent = (quantity: number, percent: number) => {
  return (quantity * percent) / 100;
};

export const percentageChange = (a: number, b: number) => {
  return ((b - a) / a) * 100;
};

export const getBuyerPrice = (
  price: number,
  marketplaceFee: number,
  royalty: number
) => {
  return price + percent(price, marketplaceFee) + percent(price, royalty);
};
