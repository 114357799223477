import Typography from "@mui/material/Typography";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import {
  EllipsisTypography,
  StyledInformationBox,
} from "../../../components/StyledComponents";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import { ProjectInfoData } from "../../../types";
import { formatNumberIntoView } from "../../../utils";
import CommunityLinks from "./CommunityLinks";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import ExpandableTypography from "../../../components/ExpandableTypography";

interface Props {
  loading: boolean;
  data: ProjectInfoData;
}

const CommunityInfo = ({ loading, data }: Props) => {
  const theme = useTheme();

  return (
    <Box>
      {loading ? (
        <Skeleton
          variant="rectangular"
          sx={{
            height: {
              xs: "20dvh",
              sm: "25dvh",
              md: "30dvh",
              lg: "45dvh",
            },
          }}
        />
      ) : (
        <Box
          sx={{
            height: {
              xs: "20dvh",
              sm: "25dvh",
              md: "30dvh",
              lg: "45dvh",
            },
            backgroundColor: (theme) => theme.palette.background.paper,
            backgroundImage: `url(${data?.profile?.coverImage?.url})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      )}
      <Box p={{ xs: 1.5, md: 3 }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          justifyContent="space-between"
          mb={2}
        >
          <Stack direction="row" spacing={2}>
            {loading ? (
              <Skeleton width={80} height={80} />
            ) : (
              <Avatar
                src={data?.profile?.profileImage?.url}
                alt="Logo"
                sx={{ width: 80, height: 80 }}
                variant="rounded"
              >
                <InsertPhotoRoundedIcon fontSize="large" />
              </Avatar>
            )}
            <Stack
              justifyContent="space-evenly"
              alignItems="flex-start"
              flexGrow={1}
              width="calc(100% - 96px)"
            >
              <>
                {loading ? (
                  <Skeleton
                    variant="text"
                    height={30}
                    width={200}
                    animation="wave"
                  />
                ) : (
                  <Box
                    display="grid"
                    gridTemplateColumns="auto 1fr"
                    alignItems="center"
                  >
                    <EllipsisTypography variant="h5" fontWeight="bold">
                      {data?.profile?.projectName}
                    </EllipsisTypography>
                    {data?.profile?.verified && (
                      <VerifiedRoundedIcon color="primary" sx={{ ml: 1 }} />
                    )}
                  </Box>
                )}
                <CommunityLinks loading={loading} profile={data?.profile} />
              </>
            </Stack>
          </Stack>
          <Box width={{ xs: "100%", sm: 300 }} minWidth={240} maxWidth="100%">
            <StyledInformationBox>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="body2"
                  color={theme.palette.grey[600]}
                  fontWeight={500}
                >
                  Total Locked
                </Typography>
                <Typography variant="body2" fontWeight={700}>
                  {data?.communityStats ? (
                    formatNumberIntoView(
                      data?.communityStats?.subscriptionCount,
                      0
                    )
                  ) : (
                    <Skeleton width={60} height="100%" />
                  )}
                </Typography>
              </Stack>
            </StyledInformationBox>
            <StyledInformationBox mt={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="body2"
                  color={theme.palette.grey[600]}
                  fontWeight={500}
                >
                  Value Locked
                </Typography>
                <Typography variant="body2" fontWeight={700}>
                  {data?.communityStats ? (
                    `$${formatNumberIntoView(
                      data?.communityStats?.subsciptionsValueUSD
                    )}`
                  ) : (
                    <Skeleton width={60} height="100%" />
                  )}
                </Typography>
              </Stack>
            </StyledInformationBox>
          </Box>
        </Stack>
        {loading
          ? [1, 2].map((i) => <Skeleton key={i} height="1em" sx={{ mb: 1 }} />)
          : data?.profile?.projectDescription && (
              <ExpandableTypography text={data.profile.projectDescription} />
            )}
      </Box>
    </Box>
  );
};

export default CommunityInfo;
