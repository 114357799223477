import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { MarketplaceServiceProps, ViewMode } from "../types";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { MarketplaceNftData } from "../../../types";
import { useLocalStorage, useWallet } from "@solana/wallet-adapter-react";
import useMediaQuery from "@mui/material/useMediaQuery";

const ServiceContext = createContext<MarketplaceServiceProps>(
  {} as MarketplaceServiceProps
);

export function useMarketplace() {
  return useContext(ServiceContext);
}

export const MarketplaceService = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const nftListBoxRef = useRef<HTMLDivElement>(null);
  const { publicKey } = useWallet();
  const { marketplaceId } = useParams();
  const [viewMode, setViewMode] = useState(ViewMode.Explore);
  const [filterOpen, setFilterOpen] = useLocalStorage("filterOpen", false);
  const [nftInfoModalOpen, setNftInfoModalOpen] = useState(false);
  const [nftInfoModalData, setNftInfoModalData] =
    useState<MarketplaceNftData>();
  const [viewMaxWidth, setViewMaxWidth] = useLocalStorage("viewMaxWidth", 220);
  const [nftModalSubmitLoading, setNftModalSubmitLoading] = useState(false);
  const [activityOpen, setActivityOpen] = useLocalStorage(
    "activityOpen",
    false
  );

  useEffect(() => {
    if (!isDownSm) return;
    setActivityOpen(false);
    setFilterOpen(false);
  }, [isDownSm, setActivityOpen, setFilterOpen]);

  const handleToggleFilter = () => {
    if (!isUpMd) {
      setActivityOpen(false);
    }
    setFilterOpen((currentState) => !currentState);
  };

  const handleToggleActivity = () => {
    if (!isUpMd) {
      setFilterOpen(false);
    }
    setActivityOpen((currentState) => !currentState);
  };

  const showNftInfoModal = (data: MarketplaceNftData) => {
    setNftInfoModalOpen(true);
    setNftInfoModalData(data);
  };

  const hideNftInfoModal = () => {
    setNftInfoModalOpen(false);
    setTimeout(() => {
      setNftInfoModalData(undefined);
    }, theme.transitions.duration.leavingScreen);
  };

  const resizeCheckToolbars = useCallback(() => {
    if (!isUpMd && filterOpen && activityOpen) {
      setActivityOpen(false);
    }
  }, [activityOpen, filterOpen, isUpMd, setActivityOpen]);

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeCheckToolbars);
    resizeCheckToolbars();
    return () => window.removeEventListener("resize", resizeCheckToolbars);
  }, [resizeCheckToolbars]);

  useEffect(() => {
    if (viewMode === ViewMode.List && filterOpen && !!publicKey) {
      setFilterOpen(false);
    }
  }, [filterOpen, publicKey, setFilterOpen, viewMode]);

  useEffect(() => {
    if (!publicKey && viewMode === ViewMode.List) {
      setViewMode(ViewMode.Explore);
    }
  }, [publicKey, viewMode]);

  return (
    <ServiceContext.Provider
      value={{
        filterOpen,
        handleToggleFilter,
        nftInfoModalOpen,
        showNftInfoModal,
        hideNftInfoModal,
        nftInfoModalData,
        viewMaxWidth,
        setViewMaxWidth,
        viewMode,
        setViewMode,
        marketplaceId,
        nftModalSubmitLoading,
        setNftModalSubmitLoading,
        activityOpen,
        handleToggleActivity,
        nftListBoxRef,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
