import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <Routes />
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
