import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Contract from "../../../components/Contract/Contract";
import Divider from "@mui/material/Divider";
import Regular from "../Subscriptions/Regular/Regular";
import ContractSkeleton from "../../../components/Contract/ContractSkeleton";
import FetchError from "../../../components/FetchError";
import VerifyDiscord from "../VerifyDiscord";
import useGetDiscordVerified from "../../../hooks/queries/integrations/discord/state/useGetDiscordVerified";
import useGetCommunityInfo from "../../../hooks/queries/community/info/useGetCommunityInfo";
import useGetCommunityPlans from "../../../hooks/queries/community/info/useGetCommunityPlans";
import useGetSubscriptions from "../../../hooks/queries/community/subscriptions/state/useGetSubscriptions";

type Props = { isActive: boolean };

const MembershipsTab = ({ isActive }: Props) => {
  const { data: communityInfo } = useGetCommunityInfo();
  const {
    data: communityPlans,
    refetch: refetchCommunityPlans,
    isLoading: communityPlansLoading,
    isError: communityPlansError,
  } = useGetCommunityPlans();
  const { data: subscriptionsInfo } = useGetSubscriptions();
  const { data: discordVerified } = useGetDiscordVerified();

  return (
    <Box display={isActive ? "block" : "none"}>
      <Typography
        variant="h5"
        fontWeight="bold"
        mt={3}
        mb={2}
        px={{ xs: 1.5, md: 3 }}
      >
        Available plans
      </Typography>
      {communityPlansError ? (
        <Box my={3}>
          <FetchError handleTryAgain={refetchCommunityPlans} />
        </Box>
      ) : (
        <Box sx={{ overflowX: "auto" }} pl={{ xs: 1.5, md: 3 }}>
          <Stack direction="row">
            {communityPlansLoading ? (
              [1, 2, 3].map((i) => <ContractSkeleton key={i} />)
            ) : Object.keys(communityPlans?.plans)?.length > 0 ? (
              Object.keys(communityPlans?.plans).map((planId) => (
                <Contract
                  data={{
                    isBooster: false,
                    plan: communityPlans.plans[planId],
                  }}
                  activePlans={
                    subscriptionsInfo
                      ? subscriptionsInfo?.stakedTokens?.reduce(
                          (accumulator, current) =>
                            accumulator + (current?.PlanId === planId ? 1 : 0),
                          0
                        )
                      : 0
                  }
                  key={planId}
                />
              ))
            ) : (
              <Typography color="text.secondary">No available plans</Typography>
            )}
          </Stack>
        </Box>
      )}

      {communityInfo?.hasDiscord &&
        discordVerified &&
        !discordVerified?.verified && <VerifyDiscord />}

      <Box py={2} px={{ xs: 1.5, md: 3 }}>
        <Divider />
        <Regular />
      </Box>
    </Box>
  );
};

export default MembershipsTab;
