import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useHttpsCallable } from "../../../../services/FirebaseService";
import { MutationDefaultResponse } from "../../../../types";
import { useParams } from "react-router-dom";

export default function useSetUsed() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { uniqueLink } = useParams();
  const fbFn = useHttpsCallable("scratch/set-used");

  return useMutation(
    async () => {
      const response = (await fbFn({
        uniqueLink: `https://anybodies.com/scratch/${uniqueLink}`,
      })) as any;
      const finalResData = response?.data as MutationDefaultResponse;
      await queryClient.invalidateQueries({
        queryKey: ["scratch/details", uniqueLink],
        exact: true,
        refetchType: "active",
      });
      if (!finalResData?.success) {
        enqueueSnackbar({
          variant: "error",
          message: `${finalResData?.error?.title}\n${finalResData?.error?.description}`,
        });
        return;
      }
      return finalResData;
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Scratching completed successfully!",
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
}
