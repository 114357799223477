import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useHttpsCallable } from "../../../../services/FirebaseService";
import { ScratchDetailsResponse } from "../../../../types";
import { useSnackbar } from "notistack";

const useGetScratchDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const path = "scratch/details";
  const { uniqueLink } = useParams();
  const fn = useHttpsCallable(path);

  const getClaimableDetails = async () => {
    const { data } = await fn({
      uniqueLink: `https://anybodies.com/scratch/${uniqueLink}`,
    });
    const res = data as ScratchDetailsResponse;
    if (!res?.success) {
      throw new Error(
        `Page not found. Please check the link. It's most likely incorrect`
      );
    }
    return res?.data;
  };

  return useQuery([path, uniqueLink], getClaimableDetails, {
    staleTime: 2 * 60 * 1000, // 2 minutes
    enabled: !!uniqueLink,
    onError: (err: any) => {
      enqueueSnackbar({
        variant: "error",
        message: err?.message || "Something went wrong. Try again, please",
      });
      navigate("/");
    },
  });
};

export default useGetScratchDetails;
