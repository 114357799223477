import Box from "@mui/material/Box";
import useGetClaimableDetails from "../../hooks/queries/claimable/state/useGetClaimableDetails";
import ClaimableCommunityInfo from "./ClaimableCommunityInfo";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import useCheckClaimableEligibility from "../../hooks/queries/claimable/state/useCheckClaimableEligibility";
import Stack from "@mui/material/Stack";
import Loader from "../../components/Loader/Loader";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CustomWalletButton from "../../components/CustomWalletButton";
import { useWallet } from "@solana/wallet-adapter-react";
import useClaimableClaimMutation from "../../hooks/queries/claimable/mutations/useClaimableClaimMutation";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import { StyledRewardCardImageStatus } from "../../components/StyledComponents";
import Tooltip from "@mui/material/Tooltip";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

const Claim = () => {
  const navigate = useNavigate();
  const { publicKey } = useWallet();
  const { data: claimableDetails, isLoading: claimableDetailsLoading } =
    useGetClaimableDetails();
  const { data: checkEligibilityRes, isLoading: checkEligibilityResLoading } =
    useCheckClaimableEligibility(
      !!claimableDetails && claimableDetails?.claimable?.status === "live"
    );
  const { mutateAsync: claim, isLoading: claimLoading } =
    useClaimableClaimMutation({
      onSuccess: () => {
        navigate(`/c/${claimableDetails?.communityProfile?.VaultRoute}`);
      },
    });

  const handleClaim = () => {
    claim();
  };

  return (
    <Box sx={{ p: { xs: 1.5, md: 3 } }}>
      <Paper
        elevation={0}
        sx={{ width: 800, maxWidth: "100%", margin: "auto" }}
      >
        <Grid container>
          <Grid item xs={12} md={5}>
            <Card sx={{ position: "relative", boxShadow: "none" }}>
              {claimableDetails?.claimable?.status === "ended" && (
                <StyledRewardCardImageStatus>
                  <Typography variant="h6" fontWeight={900}>
                    Ended
                  </Typography>
                </StyledRewardCardImageStatus>
              )}
              <CardMedia
                image={
                  claimableDetails?.claimable?.profile?.bannerImage || "default"
                }
                sx={{ width: "100%", aspectRatio: "1/1", boxShadow: "none" }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              pl={2}
              py={2}
              pr={2}
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <ClaimableCommunityInfo
                loading={claimableDetailsLoading}
                info={claimableDetails?.communityProfile}
              />
              {claimableDetailsLoading ? (
                <>
                  <Typography variant="subtitle2" mb={1} mt={2}>
                    <Skeleton width={180} />
                  </Typography>
                  <Typography variant="h6" mb={1}>
                    <Skeleton width={240} />
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    <Skeleton sx={{ mb: 0.5 }} />
                    <Skeleton sx={{ mb: 0.5 }} />
                    <Skeleton />
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6" fontWeight={700} mt={2}>
                    {claimableDetails?.claimable?.profile?.title}
                  </Typography>
                  <Typography variant="body2" mb={1}>
                    {claimableDetails?.claimable?.profile?.description}
                  </Typography>
                  {claimableDetails?.claimable?.claimExpirationDate && (
                    <Box mb={1}>
                      <Tooltip title="Campaign end date" placement="top">
                        <Stack
                          display="inline-flex"
                          alignItems="center"
                          direction="row"
                          spacing={1}
                        >
                          <EventBusyRoundedIcon />
                          <Typography variant="subtitle2">
                            {moment(
                              claimableDetails?.claimable?.claimExpirationDate
                            )?.format("DD MMMM YYYY")}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </Box>
                  )}
                  {claimableDetails?.costToClaim &&
                    claimableDetails?.costToClaim?.length > 0 && (
                      <Box mb={1}>
                        <Stack
                          display="inline-flex"
                          alignItems="flex-start"
                          direction="row"
                          spacing={1}
                        >
                          <Typography
                            variant="body2"
                            lineHeight={1}
                            fontWeight={700}
                            mt={0.5}
                            whiteSpace="nowrap"
                          >
                            Cost to claim:
                          </Typography>
                          <Stack
                            direction="row"
                            alignItems="center"
                            flexWrap="wrap"
                            gap={1}
                          >
                            {claimableDetails?.costToClaim?.map((cost) => (
                              <Tooltip
                                title={`$${cost?.tokenName}`}
                                placement="top"
                              >
                                <Chip
                                  avatar={
                                    <Avatar
                                      alt="Token"
                                      src={cost?.tokenImage}
                                    />
                                  }
                                  label={cost?.amount}
                                  size="small"
                                  sx={{ fontWeight: 700 }}
                                />
                              </Tooltip>
                            ))}
                          </Stack>
                        </Stack>
                      </Box>
                    )}
                </>
              )}
              {!claimableDetailsLoading && (
                <Box mt="auto">
                  {claimableDetails?.eligibleAudience?.ruleTreeDescription && (
                    <Typography variant="caption" component="div">
                      *{" "}
                      {claimableDetails?.eligibleAudience?.ruleTreeDescription}
                    </Typography>
                  )}
                  {publicKey ? (
                    <>
                      {claimableDetails &&
                        claimableDetails?.claimable?.status !== "ended" && (
                          <>
                            {checkEligibilityResLoading ? (
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                py={2}
                                spacing={1}
                              >
                                <Typography fontWeight={700}>
                                  Checking eligibility. Please, wait..
                                </Typography>
                                <Loader size={24} />
                              </Stack>
                            ) : checkEligibilityRes?.success ? (
                              checkEligibilityRes?.data?.eligible ? (
                                <LoadingButton
                                  variant="contained"
                                  loading={claimLoading}
                                  fullWidth
                                  onClick={handleClaim}
                                >
                                  Claim now
                                </LoadingButton>
                              ) : (
                                <Alert
                                  severity="error"
                                  variant="filled"
                                  sx={{ borderRadius: "5px !important" }}
                                >
                                  <Typography fontWeight={700}>
                                    You do not meet the requirements to be
                                    eligible to claim
                                  </Typography>
                                </Alert>
                              )
                            ) : (
                              <Alert
                                severity="error"
                                variant="filled"
                                sx={{ borderRadius: "5px !important" }}
                              >
                                <AlertTitle>
                                  {checkEligibilityRes?.error?.title}
                                </AlertTitle>
                                <Typography fontWeight={700}>
                                  {checkEligibilityRes?.error?.description}
                                </Typography>
                              </Alert>
                            )}
                          </>
                        )}
                    </>
                  ) : (
                    <Box sx={{ mt: { xs: 1.5, md: 3 } }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        textAlign="center"
                        color="text.secondary"
                      >
                        Login to gain access
                      </Typography>
                      <Stack pt={2} alignItems="center">
                        <CustomWalletButton variant="gradient" />
                      </Stack>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
export default Claim;
