import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import {
  EllipsisTypography,
  StyledGridClaimedTableCell,
  StyledGridClaimedTableRow,
} from "../../../../components/StyledComponents";
import { RowData } from "./types";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import moment from "moment";
import { ClaimedReward } from "../../../../types";

const ClaimedRewardsRow = ({
  data,
  onRedeem,
}: {
  data: RowData;
  onRedeem: (
    campaignId: string,
    redeemable: {
      mintAddress: string;
      status: ClaimedReward["status"];
      redeemedImage?: string | undefined;
    }
  ) => void;
}) => {
  const handleClick = () => {
    onRedeem(data?.campaignId, {
      mintAddress: data?.mintAddress,
      status: data?.status,
      redeemedImage: data?.image,
    });
  };

  return (
    <StyledGridClaimedTableRow>
      <StyledGridClaimedTableCell
        sx={{
          justifyContent: "flex-start",
          borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
          borderBottomLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1} maxWidth="100%">
          {data?.image ? (
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1 }}
              maxWidth="100%"
            >
              <Avatar
                src={data?.image}
                alt={data?.name}
                sx={{
                  width: { xs: "100%", sm: 40 },
                  height: { xs: "100%", sm: 40 },
                  flexShrink: 0,
                }}
                variant="rounded"
              >
                <InsertPhotoRoundedIcon sx={{ fontSize: "2rem" }} />
              </Avatar>
              <Box
                textAlign={{ xs: "center", sm: "left" }}
                maxWidth={{ xs: "100%", sm: `calc(100% - 48px)` }}
                minWidth={0}
                lineHeight={1}
              >
                <EllipsisTypography fontWeight="bold">
                  {data?.name}
                </EllipsisTypography>
                <EllipsisTypography variant="caption" display="block">
                  {data?.campaignTitle}
                </EllipsisTypography>
              </Box>
            </Stack>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              maxWidth="100%"
            >
              <Skeleton width={40} height={40} />
              <Skeleton width={100} />
            </Stack>
          )}
        </Stack>
      </StyledGridClaimedTableCell>
      <StyledGridClaimedTableCell>
        <Typography variant="body2" fontWeight={700}>
          {data?.rewardType === "discount"
            ? "Discount"
            : data?.rewardType === "gift-card"
            ? "Gift card"
            : ""}
        </Typography>
      </StyledGridClaimedTableCell>
      <StyledGridClaimedTableCell>
        <Typography variant="body2" fontWeight={700}>
          {data?.redeemExpirationDate
            ? moment(data?.redeemExpirationDate).format("DD MMMM YYYY")
            : "Does not expire"}
        </Typography>
      </StyledGridClaimedTableCell>
      <StyledGridClaimedTableCell
        sx={{
          borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
          borderBottomRightRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Button
          size="small"
          onClick={handleClick}
          sx={{ mt: { xs: 1, sm: 0 } }}
          color={data?.status === "redeemed" ? "secondary" : "primary"}
        >
          {data?.status === "claimed" ? "Redeem now" : "Get code"}
        </Button>
      </StyledGridClaimedTableCell>
    </StyledGridClaimedTableRow>
  );
};

export default ClaimedRewardsRow;
