import Box from "@mui/material/Box";
import { Manifest } from "../../types";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Zoom from "@mui/material/Zoom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { StyledNft } from "../StyledComponents";

type Props = {
  data: Manifest;
  onSelect: () => void;
  selected: boolean;
  selectedText?: string;
  disabled?: boolean;
};

const NftCard = ({
  data,
  onSelect,
  selected,
  selectedText = "Selected",
  disabled,
}: Props) => {
  return (
    <StyledNft
      className={`${disabled && !selected ? "disabled" : ""} ${
        selected ? "selected" : ""
      }`}
    >
      <Box className="image" onClick={onSelect}>
        <div className="image-indicator">
          <Zoom in={!selected} className="toggle-icon">
            <AddCircleOutlineIcon />
          </Zoom>
          <Zoom in={selected} className="toggle-icon">
            <Stack direction="row" spacing={1}>
              <Typography fontWeight="bold">{selectedText}</Typography>
              <CheckRoundedIcon />
            </Stack>
          </Zoom>
        </div>
        <img src={data?.image} alt="NFT" width="100%" />
      </Box>
    </StyledNft>
  );
};

export default NftCard;
