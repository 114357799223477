import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { PlanBoosterComponentType, PlanComponentType } from "../../types";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

type Props = {
  data: PlanBoosterComponentType | PlanComponentType;
  showLevelsModal: () => void;
};

const HowItWorks = ({ data, showLevelsModal }: Props) => {
  if (!data?.isBooster && !data?.plan?.maturityActive) return null;

  return (
    <>
      <Divider sx={{ marginY: 2 }} />
      <Stack direction="row" alignItems="center" mb={1} spacing={1}>
        <HelpOutlineRoundedIcon />
        <Typography variant="body1" fontWeight="bold">
          How it works
        </Typography>
      </Stack>
      {data?.isBooster ? (
        <Typography variant="body2">
          Each booster under this plan, can boost{" "}
          {data?.plan?.LimitPlan === "limit" ? (
            <>
              a maximum of <strong>{data?.plan?.MaxUsable}</strong>{" "}
            </>
          ) : (
            "unlimited "
          )}
          memberships. After subscribing a booster make sure to activate it on
          the desired memberships.
        </Typography>
      ) : (
        <>
          <Typography variant="body2" mb={1}>
            {data?.plan?.maturityActive
              ? "Follow the steps to unlock each new level to earn rewards, perks and more!"
              : "Subscribe your collectibles to earn rewards, perks & more!"}
          </Typography>
          {!data?.isBooster && data?.plan?.maturityActive && (
            <Button variant="text" size="small" onClick={showLevelsModal}>
              Info about levels
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default HowItWorks;
