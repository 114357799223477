import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MarketplaceHeader from "./MarketplaceHeader/MarketplaceHeader";
import MarketplaceNftList from "./MarketplaceNftList";
import MarketplaceFilter from "./MarketplaceFilter/MarketplaceFilter";
import MarketplaceNftInfoDialog from "./MarketplaceNftInfoDialog";
import MarketplaceActions from "./MarketplaceActions/MarketplaceActions";
import MarketplaceActivity from "./MarketplaceActivity/MarketplaceActivity";
import { MarketplaceService } from "./services/Main";
import { MarketplaceActivityService } from "./services/Activity";
import { MarketplaceExploreService } from "./services/Explore";

const Marketplace = () => {
  return (
    <MarketplaceService>
      <MarketplaceExploreService>
        <Box
          maxHeight={{ xs: "unset", sm: "calc(100dvh - 100px)" }}
          height="100%"
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            overflow={{ xs: "unset", sm: "hidden" }}
            height="100%"
          >
            <MarketplaceFilter />
            <Stack width={{ xs: "100%", sm: 0 }} height="100%" flexGrow={1}>
              <MarketplaceHeader />
              <Stack flexGrow={1} height="100%" display="contents">
                <MarketplaceActions />
                <MarketplaceNftList />
              </Stack>
            </Stack>
            <MarketplaceActivityService>
              <MarketplaceActivity />
            </MarketplaceActivityService>
          </Stack>
        </Box>
        <MarketplaceNftInfoDialog />
      </MarketplaceExploreService>
    </MarketplaceService>
  );
};

export default Marketplace;
