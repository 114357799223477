import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Contract from "../../../components/Contract/Contract";
import Divider from "@mui/material/Divider";
import Boosters from "../Subscriptions/Boosters/Boosters";
import ContractSkeleton from "../../../components/Contract/ContractSkeleton";
import FetchError from "../../../components/FetchError";
import useGetCommunityPlans from "../../../hooks/queries/community/info/useGetCommunityPlans";
import useGetSubscriptions from "../../../hooks/queries/community/subscriptions/state/useGetSubscriptions";

type Props = { isActive: boolean };

const BoostersTab = ({ isActive }: Props) => {
  const {
    data: communityPlans,
    refetch: refetchCommunityPlans,
    isLoading: communityPlansLoading,
    isError: communityPlansError,
  } = useGetCommunityPlans();
  const { data: subscriptionsInfo } = useGetSubscriptions();

  return (
    <Box display={isActive ? "block" : "none"}>
      <Typography
        variant="h5"
        fontWeight="bold"
        mt={3}
        mb={2}
        px={{ xs: 1.5, md: 3 }}
      >
        Available plans
      </Typography>
      {communityPlansError ? (
        <Box my={3}>
          <FetchError handleTryAgain={refetchCommunityPlans} />
        </Box>
      ) : (
        <Box sx={{ overflowX: "auto" }} pl={{ xs: 1.5, md: 3 }}>
          <Stack direction="row">
            {communityPlansLoading ? (
              [1, 2, 3].map((i) => <ContractSkeleton key={i} />)
            ) : Object.keys(communityPlans?.boosters)?.length > 0 ? (
              Object.keys(communityPlans?.boosters).map((planId) => (
                <Contract
                  data={{
                    isBooster: true,
                    plan: communityPlans?.boosters?.[planId],
                  }}
                  activePlans={
                    subscriptionsInfo
                      ? subscriptionsInfo?.boosters?.reduce(
                          (accumulator, current) =>
                            accumulator + (current?.PlanId === planId ? 1 : 0),
                          0
                        )
                      : 0
                  }
                  key={planId}
                />
              ))
            ) : (
              <Typography color="text.secondary">No available plans</Typography>
            )}
          </Stack>
        </Box>
      )}
      <Box py={2} px={{ xs: 1.5, md: 3 }}>
        <Divider />
        <Boosters />
      </Box>
    </Box>
  );
};

export default BoostersTab;
