import { CSSObject, Theme, alpha, styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiBox from "@mui/material/Box";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import MuiDialog from "@mui/material/Dialog";
import MuiTypography from "@mui/material/Typography";
import MuiListItemButton from "@mui/material/ListItemButton";
import MuiAppBar from "@mui/material/AppBar";
import MuiTableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LinkProps } from "@mui/material/Link";

export const drawerWidth = 240;
export const filterWidth = 240;

export const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundImage: "none",
  backgroundColor: theme.palette.background.default,
  boxShadow: "none",
  "& .MuiToolbar-root": {
    [theme.breakpoints.down("sm")]: {
      paddingInline: theme.spacing(1.5),
      "& .MuiIconButton-root": {
        marginLeft: theme.spacing(-1),
      },
    },
  },
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

export const StyledMain = styled(MuiBox)(({ theme }) => ({
  flexGrow: 1,
  minHeight: "100dvh",
  display: "flex",
  flexDirection: "column",
}));

export const StyledLogoIcon = styled(MuiBox)(({ theme }) => ({
  width: 30,
  height: 30,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "3px",
}));

export const StyledLogoName = styled(MuiTypography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontSize: 18,
  lineHeight: 1,
  letterSpacing: "0.02em",
  fontWeight: 900,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "needHide",
})<{ needHide: boolean }>(({ needHide, theme, variant }) => ({
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  zIndex: 100,
  width: drawerWidth,
  ".MuiDrawer-paper": {
    background: theme.palette.background.default,
    justifyContent: "flex-start",
    boxSizing: "border-box",
    width: drawerWidth,
    backgroundImage: "none",
    padding: theme.spacing(1.5),
    border: 0,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
  },
  ...(variant === "permanent" &&
    needHide && {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    }),
  ...(variant === "temporary" &&
    needHide && {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    }),
}));

export const DrawerListItemButton = styled(MuiListItemButton)<LinkProps>(
  ({ theme, selected }) => ({
    justifyContent: "initial",
    borderRadius: `${theme.shape.borderRadius}px`,
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: theme.spacing(0, 1.5),
    height: 40,
    color: selected ? theme.palette.text.primary : theme.palette.text.secondary,
    fill: selected ? theme.palette.text.primary : theme.palette.text.secondary,
    "& .MuiTypography-root": {
      fontWeight: selected ? 500 : 400,
    },
    "& .MuiAvatar-root": {
      width: 22.28,
      height: 22.28,
      marginRight: theme.spacing(1.5),
      flexShrink: 0,
      borderRadius: 3,
    },
    "& .MuiSvgIcon-root": {
      color: selected
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
    },
    "&.Mui-selected": {
      paddingRight: "17px",
      borderRight: 3,
      borderColor: theme.palette.primary.main,
      cursor: "default",
      background: "rgba(255, 255, 255, 0.08)",
      ":hover": {
        background: "rgba(255, 255, 255, 0.08)",
      },
    },
  })
);

export const StyledInformationBox = styled(MuiBox)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  borderRadius: `${theme.shape.borderRadius}px`,
}));

export const NftCardTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(2),
    whiteSpace: "nowrap",
    maxWidth: "none",
  },
}));

export const SelectNftsDialog = styled(MuiDialog)(({ theme, fullScreen }) => ({
  "& .MuiPaper-root": {
    maxWidth: 1000,
  },
  "& .MuiDialogTitle-root": {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 1.5),
    },
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
    marginInline: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: `${theme.shape.borderRadius * 3}px`,
    [theme.breakpoints.down("sm")]: {
      marginInline: theme.spacing(1.5),
    },
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      position: "sticky",
      bottom: 0,
      zIndex: 2,
      boxShadow: theme.shadows[10],
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(1.5),
    },
    flexDirection: "row",
    "& >:not(:first-of-type)": {
      marginLeft: fullScreen ? 0 : theme.spacing(1),
      marginBottom: fullScreen ? theme.spacing(1) : 0,
    },
  },
}));

export const StyledContractBox = styled(MuiBox)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.grey[900],
  borderRadius: `${theme.shape.borderRadius}px`,
  maxWidth: theme.spacing(52),
  minWidth: 300,
  flexShrink: 0,
  height: "100%",
}));

export const StyledContractRewardBox = styled(MuiBox)(({ theme }) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: theme.spacing(2, 0),
  backgroundColor: "#2A2A2A",
  minWidth: 120,
}));

export const StyledContractNewBadge = styled(MuiBox)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  right: theme.spacing(-1.5),
  top: theme.spacing(-1.5),
  backgroundColor: theme.palette.primary.main,
  borderRadius: 2,
  padding: theme.spacing(0.5, 1),
}));

export const StyledContractActiveSubscriptionsBadge = styled(MuiBox)(
  ({ theme }) => ({
    background:
      "linear-gradient(90deg, #FFE000 0%, #FF9962 50.52%, #947DFF 100%)",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    color: "#000",
    padding: theme.spacing(0.5, 1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  })
);

export const StyledTableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
    backgroundColor: theme.palette.action.hover,
    "&:first-of-type": {
      borderTopLeftRadius: `${theme.shape.borderRadius}px`,
      borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
    },
    "&:last-of-type": {
      borderTopRightRadius: `${theme.shape.borderRadius}px`,
      borderBottomRightRadius: `${theme.shape.borderRadius}px`,
    },
  },
}));

export const EllipsisTypography = styled(Typography)(({ theme }) => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const StyledCommunityMiniCard = styled(MuiBox)(({ theme }) => ({
  "& .inner": {
    position: "relative",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
    borderRadius: `${theme.shape.borderRadius * 2}px`,
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    boxSizing: "border-box",
    "& .img-box": {
      width: theme.spacing(11),
      height: theme.spacing(11),
      overflow: "hidden",
      position: "relative",
      backgroundColor: theme.palette.divider,
    },
    "& .img": {
      backgroundPosition: "50%",
      transition: "transform .2s ease-in-out",
      transformOrigiht: "center",
    },
    "& .number": {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      right: theme.spacing(0.5),
      bottom: theme.spacing(0.5),
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      borderRadius: theme.spacing(2.5),
      padding: 1,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
      boxShadow: theme.shadows[1],
    },
    "& .content": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      width: `calc(100% - ${theme.spacing(11)})`,
      padding: theme.spacing(1.5, 2),
      boxSizing: "border-box",
      "&__left": {
        flex: 1,
        minWidth: 0,
        boxSizing: "border-box",
        paddingRight: theme.spacing(1.5),
      },
    },
    "&:hover": {
      "& .img": {
        transform: "scale(1.05)",
      },
    },
  },
}));

export const StyledNft = styled(MuiBox)(({ theme }) => ({
  position: "relative",
  userSelect: "none",
  "& .image-indicator": {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1,
    padding: theme.spacing(1),
    borderTopWidth: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "opacity 300ms cubic-bezier(.17, .67, .13, 1.02)",
    display: "flex",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    opacity: 0,
  },

  "& .toggle-icon": {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    margin: "auto",
    width: "100%",
    height: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transitionDelay: "75ms",
    transitionDuration: "150ms",
  },

  "&:not(.disabled)": {
    cursor: "pointer",
    "& .image": {
      "&:hover": {
        boxShadow: "none",
        "& img": {
          transform: "scale(1.1)",
        },
        "& .image-indicator": {
          opacity: 1,
        },
      },
    },
  },
  "&.selected": {
    "& .image-indicator": {
      background:
        "linear-gradient(135.84deg, rgba(255, 224, 0, 0.8) 0%, rgba(255, 153, 98, 0.8) 46.26%, rgba(148, 125, 255, 0.8) 91.43%)",
      opacity: 1,
    },
    "& .image": {
      boxShadow: "none",
      "& img": {
        transform: "scale(1.1)",
      },
    },
  },
  "&.disabled": {
    opacity: 0.7,
  },
  "& .image": {
    aspectRatio: "1",
    borderRadius: `${theme.shape.borderRadius * 2}px`,
    position: "relative",
    boxShadow: theme.shadows[5],
    overflow: "hidden",
    backgroundColor: theme.palette.divider,
    "& img": {
      display: "block",
      transition: "transform 300ms cubic-bezier(.17, .67, .13, 1.02)",
    },
  },
}));

export const StyledGridTable = styled(MuiBox)(({ theme }) => ({
  display: "grid",
  rowGap: theme.spacing(1),
  textAlign: "center",
}));

export const StyledGridTableCell = styled(MuiBox)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.action.hover,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0.5),
  },
}));

export const StyledGridTableHead = styled(MuiBox)(({ theme }) => ({
  fontWeight: "bold",
  padding: theme.spacing(0.25, 1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.7em",
}));

export const StyledGridTokensTable = styled(MuiBox)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "grid",
    rowGap: theme.spacing(1),
    textAlign: "center",
    gridTemplateColumns: "minmax(130px, 1fr) repeat(4, auto)",
    minWidth: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
}));

export const StyledGridTokensTableRow = styled(MuiBox)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "contents",
    "&:hover": {
      "& > div": {
        background: alpha(
          theme.palette.action.hover,
          theme.palette.action.activatedOpacity
        ),
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: `100%`,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    borderRadius: 3,
    display: "grid",
    gridTemplateColumns: "repeat(2, auto)",
    gridTemplateRows: "repeat(3, auto)",
    "& > *:nth-of-type(1)": {
      gridArea: "1 / 1 / 3 / 2",
    },
    "& > *:nth-of-type(2)": {
      gridArea: "4 / 1 / 5 / 3",
      justifyContent: "flex-start",
      "& .MuiTypography-root": {
        fontSize: 11,
        marginTop: theme.spacing(0.5),
        color: theme.palette.text.secondary,
        fontWeight: 700,
      },
      "& .MuiSvgIcon-root": {
        width: 20,
        height: 20,
      },
    },
    "& > *:nth-of-type(3)": {
      gridArea: "1 / 2 / 2 / 3",
      textAlign: "right",
      justifyContent: "flex-end",
      "& .MuiTypography-root": {
        fontSize: 12,
      },
    },
    "& > *:nth-of-type(4)": {
      gridArea: "2 / 2 / 3 / 3",
      textAlign: "right",
      justifyContent: "flex-end",
      "& .MuiTypography-root": {
        fontSize: 12,
      },
    },
    "& > *:nth-of-type(5)": {
      gridArea: "3 / 1 / 4 / 3",
      marginTop: theme.spacing(1),
    },
    "& .pool": {
      "&::before": {
        content: "'Pool balance: '",
        fontWeight: 500,
      },
    },
    "& .dollar-amount": {
      "&::before": {
        content: "'$'",
      },
    },
  },
}));

export const StyledGridTokensTableCell = styled(MuiBox)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    backgroundColor: theme.palette.action.hover,
  },
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    "& button": { width: "100%" },
  },
}));

export const StyledGridTokensTableHead = styled(MuiBox)(({ theme }) => ({
  fontWeight: "bold",
  padding: theme.spacing(0.25, 1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.7em",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const StyledGridClaimedTable = styled(MuiBox)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "grid",
    rowGap: theme.spacing(1),
    textAlign: "center",
    gridTemplateColumns: "minmax(130px, 1fr) repeat(3, auto)",
    minWidth: "500px",
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
}));

export const StyledGridClaimedTableRow = styled(MuiBox)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "contents",
    "&:hover": {
      "& > div": {
        background: alpha(
          theme.palette.action.hover,
          theme.palette.action.activatedOpacity
        ),
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: `calc(50% - ${theme.spacing(0.75)})`,
  },
}));

export const StyledGridClaimedTableCell = styled(MuiBox)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    backgroundColor: theme.palette.action.hover,
  },
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    "& button": { width: "100%" },
  },
}));

export const StyledGridClaimedTableHead = styled(MuiBox)(({ theme }) => ({
  fontWeight: "bold",
  padding: theme.spacing(0.25, 1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.7em",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const StyledRewardCardImage = styled(MuiBox)(({ theme }) => ({
  borderRadius: "5px 5px 0 0",
  overflow: "hidden",
  marginBottom: theme.spacing(1),
  backgroundSize: "cover",
  backgroundPosition: "center",
  aspectRatio: "1/1",
  width: "100%",
  position: "relative",
  boxShadow: theme.shadows[1],
}));

export const StyledRewardCardImageStatus = styled(MuiBox)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0,0,0,0.5)",
  backdropFilter: "blur(1px)",
}));

export const StyledUnlockYourBrandExperience = styled(MuiBox)(({ theme }) => ({
  width: "100%",
  borderRadius: `${theme.shape.borderRadius}px`,
  background:
    "linear-gradient(180deg, #947DFF 1.56%, rgba(73, 36, 246, 0) 100%)",
  marginBottom: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  aspectRatio: "auto",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    aspectRatio: "2/3",
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(3),
  },
}));

export const StyledTrendingExperiencesVideoplayer = styled(MuiBox)(
  ({ theme }) => ({
    width: "100%",
    aspectRatio: "2/3",
    borderRadius: `${theme.shape.borderRadius}px`,
    overflow: "hidden",
    backgroundColor: "#161616",
    position: "relative",
    "& video": {
      width: "100%",
      height: "100%",
    },
  })
);

export const StyledTrendingExperiencesVideoplayerHandler = styled(MuiBox)(
  ({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#FFF",
    transition: theme.transitions.create("opacity"),
    "& .MuiSvgIcon-root": {
      transition: theme.transitions.create("transform"),
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        transform: "scale(1.1)",
      },
    },
  })
);

export const SwiperSlideInner = styled(MuiBox)(({ theme }) => ({
  overflow: "hidden",
  backgroundColor: "rgba(37, 38, 44, 1)",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  padding: theme.spacing(5, 0),
  borderRadius: 15,
  [theme.breakpoints.down("md")]: {
    borderRadius: 0,
  },
}));

export const CreateCommunityBannerWrapper = styled("div")(({ theme }) => ({
  "@keyframes animateGlow": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "200% 50%",
    },
  },
  background:
    "linear-gradient(180deg, #FFE000 0%, #FF9962 27.6%, rgba(148, 125, 255, 0.7) 100%)",
  padding: 1,
  borderRadius: `${theme.shape.borderRadius * 2}px`,
  margin: theme.spacing(15, 3),
  position: "relative",
  "&::after": {
    content: "''",
    position: "absolute",
    background:
      "linear-gradient(to left, #FFE000 0%, #FF9962 50%, rgba(148, 125, 255, 1) 90%, #FFE000 100%)",
    top: 15,
    left: 0,
    right: 0,
    zIndex: -1,
    height: "100%",
    width: "100%",
    transform: "scale(0.975) translateZ(0)",
    filter: "blur(10px)",
    backgroundSize: "200%",
    animation: `animateGlow 4s linear infinite`,
  },
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(10, 3),
    "& .text": {
      fontSize: "1.5rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .text": {
      fontSize: "1.25rem",
    },
  },
}));

export const StyleRewardCustomLinearProgress = styled(LinearProgress)(
  ({ theme }) => ({
    height: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      background:
        "linear-gradient(90deg, #FFE000 0%, #FF9962 50.52%, #947DFF 100%)",
    },
  })
);

export const StyledFilterToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    backgroundColor: "#2C2E36",
    ".MuiToggleButtonGroup-grouped": {
      border: 0,
      margin: theme.spacing(0.5),
      fontWeight: "bold",
      color: theme.palette.text.secondary,
      "&.Mui-selected": {
        backgroundColor: theme.palette.background.paper,
        cursor: "default",
        color: theme.palette.text.primary,
        "&:hover": {
          backgroundColor: theme.palette.background.paper,
        },
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
        borderTop: 0,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  })
);

export const StyledFilterToggleNftView = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    ".MuiToggleButtonGroup-grouped": {
      "--filter-toggle-text-color": alpha(theme.palette.text.primary, 0.375),
      color: "var(--filter-toggle-text-color)",
      padding: theme.spacing(0.5),
      border: 0,
      "&:hover": {
        backgroundColor: "unset",
      },
      "&.Mui-selected": {
        "--filter-toggle-text-color": theme.palette.primary.main,
        backgroundColor: "unset",
        cursor: "default",
        color: "var(--filter-toggle-text-color)",
        "&:hover": {
          backgroundColor: "unset",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      ".MuiToggleButtonGroup-grouped:nth-of-type(2)": {
        display: "none",
      },
    },
  })
);

export const StyledMarketplaceToolbar = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "type",
})<{ open: boolean; type: "filter" | "activity" }>(({ theme, open, type }) => ({
  flexShrink: 0,
  borderColor: theme.palette.divider,
  backgroundColor: theme.palette.background.default,
  overflow: "hidden",
  width: filterWidth,
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    borderWidth: 0,
    borderRightWidth: type === "filter" ? 1 : 0,
    borderLeftWidth: type === "activity" ? 1 : 0,
    borderTopWidth: 1,
    borderStyle: "solid",
    display: open ? "inline-flex" : "none",
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    display: "flex",
    width: "100%",
    transition: theme.transitions.create(["transform", "visibility"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: "fixed",
    zIndex: 10,
    backgroundColor: theme.palette.background.default,
    top: 56,
    bottom: 0,
    left: 0,
    right: 0,
    transform: open ? "translateY(0)" : "translateY(150%)",
  },
}));

export const StyledMarketplaceToolbarInner = styled(MuiBox)(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  padding: theme.spacing(0, 1),
  flexGrow: 1,
}));

export const FilterMobileButtons = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: "auto",
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[5],
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

export const StyledFilterHeader = styled(MuiBox)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: theme.spacing(0.5, 0),
  padding: theme.spacing(0, 1),
}));

export const StyledFilterOpenDesktopButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open: boolean }>(({ theme, open }) => ({
  justifyContent: "space-between",
  minWidth: "auto",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    width: "auto",
  },
  ".arrow-back": {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      delay: open ? 0 : theme.transitions.duration.leavingScreen / 3,
    }),
    marginLeft: open ? 0 : -20,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const closedMixin = (
  width: number | string,
  theme: Theme
): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width,
});

export const openedMixin = (width: number, theme: Theme): CSSObject => ({
  width,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

export const StyledMarketplaceNftCardImage = styled(MuiBox)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  boxShadow: theme.shadows[5],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: "1 / 1",
  backgroundColor: theme.palette.background.paper,
}));

export const StyledMarketplaceNftCardImageHoverContainer = styled(MuiBox)(
  ({ theme }) => ({
    position: "absolute",
    left: theme.spacing(1),
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    marginBottom: 26,
    zIndex: 2,
    opacity: 0,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
    }),
  })
);

export const StyledMarketplaceNftCardImageInfo = styled(MuiBox)(
  ({ theme }) => ({
    position: "absolute",
    zIndex: 2,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    padding: theme.spacing(0.5, 1),
    backgroundColor: "rgba(41, 41, 41, 0.4)",
    backdropFilter: "blur(3px)",
    borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
    borderBottomRightRadius: `${theme.shape.borderRadius}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textShadow: "1px 1px 2px rgb(0, 0, 0)",
  })
);

export const StyledMarketplaceNftCard = styled(MuiBox, {
  shouldForwardProp(propName) {
    return propName !== "isUserListing";
  },
})<{ isUserListing?: boolean }>(({ isUserListing, theme }) => ({
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    backgroundColor: isUserListing
      ? alpha(theme.palette.primary.main, 0.1)
      : theme.palette.background.paper,
    top: theme.spacing(-0.5),
    left: theme.spacing(-0.5),
    right: theme.spacing(-0.5),
    bottom: theme.spacing(-0.5),
    borderRadius: `${theme.shape.borderRadius * 2}px`,
    boxShadow: theme.shadows[10],
    transition: theme.transitions.create("opacity"),
    border: `1px solid ${theme.palette.divider}`,
    opacity: 0,
  },
  ".image": {
    transition: theme.transitions.create("box-shadow"),
  },
  "&:hover, &.loading": {
    "::after": {
      opacity: 1,
    },
    ".image": {
      boxShadow: "none",
    },
    ".info": {
      opacity: 1,
    },
  },
}));

export const StyledMarketplaceNftListBox = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== "maxItemWidth",
})<{
  maxItemWidth: number;
}>(({ theme, maxItemWidth }) => ({
  display: "grid",
  gridGap: theme.spacing(1),
  gridTemplateColumns: `repeat(auto-fill, minmax(${maxItemWidth}px, 1fr))`,
  gridTemplateRows: "min-content",
  alignItems: "flex-start",
  flexGrow: 1,
}));

export const StyledMarketplaceNftInfoDialogAttribute = styled(MuiBox)(
  ({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    minWidth: 150,
    flex: "1 1 0%",
  })
);
