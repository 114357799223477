import type { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    gradient: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    gradient?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    gradient: true;
  }
}

export const typography: TypographyOptions = {
  fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 13,
  gradient: {
    fontWeight: 700,
    background:
      "linear-gradient(90deg, #FFE000 0%, #FF9962 50.52%, #947DFF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
};
