import { SVGProps } from "react";

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 21 27"
      {...props}
    >
      <path
        fill="#FFE000"
        d="M19.94 12.988c-.238-.487-.651-.747-1.238-.745-1.3.005-2.6-.005-3.898.006-.277.002-.343-.049-.301-.333.133-.889.214-1.786.319-2.679.163-1.382.332-2.762.492-4.144.142-1.215.278-2.43.409-3.646.024-.222.015-.444-.085-.66-.421-.896-1.5-1.057-2.287-.327-1.075.997-2.141 2.003-3.211 3.005l-.175.23c-.025.016-.053.027-.074.046-3.128 2.95-6.257 5.896-9.383 8.845-.422.4-.536.878-.318 1.398.23.543.69.795 1.302.796 1.27.003 2.54.003 3.81 0 .226 0 .398-.012.359.305-.29 2.334-.564 4.671-.838 7.008-.14 1.189-.292 2.377-.4 3.57-.057.619.347 1.132.936 1.284.554.144 1.023-.011 1.428-.39 1.09-1.023 2.186-2.04 3.279-3.06a.263.263 0 00.018-.066c.013-.007.03-.01.04-.02 3.19-3.005 6.385-6.009 9.569-9.021.418-.396.497-.893.248-1.402z"
      />
    </svg>
  );
}

export default Icon;
