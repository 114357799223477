import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const Redirect = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const w: any = window;
    switch (params.id) {
      case "Anybodies-X-DeGods":
        w.location = "https://www.instagram.com/ar/796692181322727/";
        break;
      case "Anybodies-X-TayKeith":
        w.location = "https://www.instagram.com/ar/645670066873816/";
        break;
      case "HH_TLV_23":
        w.location = "https://www.instagram.com/ar/6795046963861928/";
        break;
      default:
        navigate("/");
        break;
    }

    return;
  }, [navigate, params.id]);
  return <div style={{ height: "100dvh" }} />;
};
