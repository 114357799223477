import { BoostersHeadProps } from "./types";
import Checkbox from "@mui/material/Checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import { StyledGridTableHead } from "../../../../components/StyledComponents";

const BoostersHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  disabledCheckbox,
}: BoostersHeadProps) => {
  return (
    <>
      <StyledGridTableHead justifyContent="flex-start" sx={{ p: 0 }}>
        <Checkbox
          color="primary"
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={onSelectAllClick}
          disabled={disabledCheckbox}
        />
      </StyledGridTableHead>
      <StyledGridTableHead />
      <StyledGridTableHead>Plan</StyledGridTableHead>
      <StyledGridTableHead>
        <TableSortLabel
          active={orderBy === "boosted"}
          direction={orderBy === "boosted" ? order : "asc"}
          onClick={() => {
            onRequestSort("boosted");
          }}
        >
          Boosted memberships
        </TableSortLabel>
      </StyledGridTableHead>
      <StyledGridTableHead>Boost info</StyledGridTableHead>
      <StyledGridTableHead />
    </>
  );
};

export default BoostersHead;
