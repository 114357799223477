import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41 25"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M14.596 24.735c0-.951.01-1.854-.004-2.755-.01-.742-.61-1.198-1.29-1-.426.124-.69.462-.697.931-.01.798-.003 1.595-.003 2.393v.412c-.175.007-.31.019-.444.019H2.48c-1.029 0-1.5-.45-1.5-1.432v-6.364c1.326-.223 2.394-.827 3.15-1.891.59-.831.823-1.754.767-2.758C4.813 10.764 3.66 8.734.98 8.275V1.878C.979.947 1.46.482 2.43.48H12.602c0 .9-.002 1.759.002 2.619 0 .171 0 .35.048.512.128.437.598.722 1.056.663.51-.065.867-.427.88-.955.02-.743.008-1.488.009-2.233V.481h24.767c1.03 0 1.5.45 1.5 1.432V8.278c-1.312.217-2.372.812-3.129 1.856-.598.826-.842 1.746-.791 2.751.103 2.002 1.553 3.527 3.895 4.054.007.094.024.196.024.297.001 2.06.003 4.122 0 6.182-.001.824-.512 1.316-1.37 1.316-8.197.002-16.394 0-24.591 0h-.306Zm8.018-5.897c.459-.003.72-.278.954-.612 2.415-3.435 4.834-6.867 7.252-10.302.092-.13.184-.267.245-.413.173-.411.013-.872-.372-1.121a.985.985 0 0 0-1.19.07 1.855 1.855 0 0 0-.29.336c-2.451 3.475-4.902 6.952-7.35 10.43-.07.098-.139.201-.184.31-.264.633.225 1.308.935 1.3v.002Zm8.15.49c1.784.019 3.24-1.34 3.272-3.05.032-1.714-1.424-3.134-3.22-3.142-1.768-.007-3.22 1.35-3.246 3.033-.027 1.736 1.392 3.14 3.194 3.159Zm-8.779-7.247c1.78-.005 3.224-1.39 3.225-3.097 0-1.705-1.441-3.09-3.223-3.096-1.809-.004-3.243 1.375-3.236 3.113.007 1.712 1.447 3.084 3.234 3.08Zm-9.382-3.058v1.435c.027.552.419.943.96.957.539.012 1.002-.36 1.018-.9.03-.983.03-1.968 0-2.95-.016-.544-.482-.914-1.021-.897a.957.957 0 0 0-.955.96c-.012.465-.002.93-.002 1.396v-.001Zm0 7.133c0 .478-.01.957.002 1.435.015.55.417.943.956.959.538.016 1.002-.355 1.019-.9.03-.982.03-1.966 0-2.948-.017-.543-.48-.912-1.02-.899-.54.014-.94.405-.956.958-.012.465-.002.93-.002 1.396l.001-.001Z"
    />
    <path
      fill="#fff"
      d="M30.797 17.41c-.688 0-1.242-.533-1.236-1.188.005-.654.572-1.182 1.257-1.171.666.01 1.214.537 1.22 1.172.008.653-.553 1.189-1.241 1.189v-.001ZM20.75 8.956c.012-.659.574-1.169 1.267-1.152.667.016 1.205.548 1.2 1.185-.005.652-.573 1.185-1.256 1.176-.686-.01-1.225-.547-1.213-1.21h.001Z"
    />
  </svg>
);
export default SvgComponent;
