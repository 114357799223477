import { useMutation } from "@tanstack/react-query";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  MutationDefaultResponse,
  TxRequestResponse,
} from "../../../../../types";
import { Transaction } from "@solana/web3.js";
import { useCommunity } from "../../../../../services/CommunityService";
import { useSnackbar } from "notistack";
import useRefetchAllSubscriptions from "../state/useRefetchAllSubscriptions";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";

export default function useUnsubscribeMutation({
  onSuccess,
  isBooster = false,
}: {
  onSuccess: () => void;
  isBooster?: boolean;
}) {
  const refetchSubs = useRefetchAllSubscriptions();
  const unsubTxFbFn = useHttpsCallable("subscriptions/unsubscribe/request");
  const unsubFbFn = useHttpsCallable("subscriptions/unsubscribe/process");
  const { data: communityInfo } = useGetCommunityInfo();
  const { setLoadingModal } = useCommunity();
  const { publicKey, signTransaction } = useWallet();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async (ids: string[]) => {
      const res = await unsubTxFbFn({
        accountId: communityInfo?.accountId,
        communityId: communityInfo?.communityId,
        stakedDocIds: isBooster ? [] : ids,
        stakedMultiplierDocIds: isBooster ? ids : [],
        walletAddress: publicKey?.toString(),
      });
      const unsubscribeTxRes = res?.data as TxRequestResponse;
      if (!unsubscribeTxRes?.success) {
        throw new Error(
          `${unsubscribeTxRes?.error?.title}\n${unsubscribeTxRes?.error?.description}`
        );
      }

      const transaction = Transaction.from(
        unsubscribeTxRes?.rawTransaction?.data
      );
      if (!signTransaction) {
        throw new Error("Can't create transaction");
      }
      const signedTransaction = await signTransaction(transaction);
      const finalRes = await unsubFbFn({
        transactionRequest: {
          rawTransaction: Object.values(signedTransaction.serialize()),
          requestId: unsubscribeTxRes?.requestId,
        },
      });
      const finalResData = finalRes?.data as MutationDefaultResponse;
      if (!finalResData?.success) {
        throw new Error(
          `${finalResData?.error?.title}\n${finalResData?.error?.description}`
        );
      }
      return finalRes;
    },
    {
      onMutate: () => {
        if (!setLoadingModal) return;
        setLoadingModal({
          title: "Unsubscribe in process",
          description: "May take a while, trust the process",
        });
      },
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Request successful. Unsubscribing is in progress",
        });
        refetchSubs();
        onSuccess();
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
        if (!setLoadingModal) return;
        setLoadingModal(null);
      },
      onSettled: () => {
        if (!setLoadingModal) return;
        setLoadingModal(null);
      },
    }
  );
}
