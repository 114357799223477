import { useSnackbar } from "notistack";
import { useHttpsCallable } from "../../../services/FirebaseService";
import { TokenDelegationCommunityCampaignInfoResponse } from "../../../types";
import { useQuery } from "@tanstack/react-query";

const useGetTokenCampaignInfo = (contractId?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const path = "community/plan/token-campaign";
  const fbFn = useHttpsCallable(path);

  const getInfo = async () => {
    const { data } = await fbFn({ contractId });
    const res = data as TokenDelegationCommunityCampaignInfoResponse;
    if (!res?.success) {
      throw new Error(`Cannot get campaign info. Please, try again later..`);
    }
    return res?.data;
  };

  return useQuery([path, contractId], getInfo, {
    staleTime: 5 * 60 * 1000,
    enabled: !!contractId,
    onError: (err: any) => {
      enqueueSnackbar({
        variant: "error",
        message: err?.message || "Something went wrong. Try again, please",
      });
    },
  });
};

export default useGetTokenCampaignInfo;
