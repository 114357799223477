import { useQuery } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { ClaimedRewardsResponse } from "../../../../../types";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";

const useGetClaimedRewards = () => {
  const path = "rewards/claimed-list";
  const { publicKey } = useWallet();
  const { data: communityInfo } = useGetCommunityInfo();
  const fn = useHttpsCallable(path);

  const getClaimedRewards = async () => {
    const { data } = await fn({
      walletAddress: publicKey?.toString(),
      accountId: communityInfo?.accountId,
      communityId: communityInfo?.communityId,
    });
    const res = data as ClaimedRewardsResponse;
    if (!res?.success) {
      throw new Error("Error");
    }
    return res?.data;
  };

  return useQuery(
    [
      path,
      communityInfo?.accountId,
      communityInfo?.communityId,
      publicKey?.toString(),
    ],
    getClaimedRewards,
    {
      staleTime: 10 * 60 * 1000,
      enabled:
        !!publicKey &&
        !!communityInfo?.accountId &&
        !!communityInfo?.communityId,
    }
  );
};

export default useGetClaimedRewards;
