import type { PaletteOptions } from "@mui/material/styles/createPalette";

export const palette: PaletteOptions = {
  mode: "dark",
  primary: {
    main: "#FFE000",
  },
  secondary: {
    main: "#11F695",
  },
  divider: "rgba(255,255,255,0.1)",
  background: {
    default: "#191A1F",
    paper: "#121214",
  },
};
