import Box from "@mui/material/Box";
import "./Loader.scss";

const Loader = ({ size = 62 }: { size?: number }) => {
  const lineWidth = Math.floor(size / 5);
  const centerCircleSize = size - lineWidth * 2;
  return (
    <Box
      className="gradient-loader"
      sx={{
        width: size,
        height: size,
        borderRadius: size,
        "&::before": {
          width: centerCircleSize,
          height: centerCircleSize,
          borderRadius: centerCircleSize,
          backgroundColor: (theme) => theme.palette.background.paper,
        },
        "&::after": {
          width: `${Math.floor(size / 10)}px`,
          height: lineWidth,
          borderTopRightRadius: lineWidth,
          borderBottomRightRadius: lineWidth,
        },
      }}
    />
  );
};

export default Loader;
