import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import {
  StyledGridTable,
  StyledGridTableCell,
  StyledGridTableHead,
} from "../../../../components/StyledComponents";

const RegularSkeleton = () => {
  return (
    <Box sx={{ overflowX: "auto" }}>
      <StyledGridTable
        sx={{
          gridTemplateColumns: "48px minmax(auto, 1fr) repeat(4, auto)",
          minWidth: "650px",
        }}
      >
        <>
          <StyledGridTableHead justifyContent="flex-start" sx={{ p: 0 }}>
            <Skeleton width={20} height={20} />
          </StyledGridTableHead>
          <StyledGridTableHead />
          <StyledGridTableHead>Daily reward</StyledGridTableHead>
          <StyledGridTableHead>Level</StyledGridTableHead>
          <StyledGridTableHead>Earnings</StyledGridTableHead>
          <StyledGridTableHead />
        </>
        {[1, 2].map((i) => (
          <Box
            sx={{
              display: "contents",
            }}
            key={i}
          >
            <StyledGridTableCell
              sx={{
                p: 0,
                borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
                borderBottomLeftRadius: (theme) =>
                  `${theme.shape.borderRadius}px`,
              }}
            >
              <Skeleton width={20} height={20} />
            </StyledGridTableCell>
            <StyledGridTableCell sx={{ justifyContent: "flex-start" }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                maxWidth="100%"
              >
                <Skeleton width={60} height={60} />
                <Skeleton width={100} />
              </Stack>
            </StyledGridTableCell>
            <StyledGridTableCell>
              <Stack direction="row" spacing={1} alignItems="center">
                <Skeleton width={13} height={13} variant="circular" />
                <Skeleton width={40} height={13} />
              </Stack>
            </StyledGridTableCell>
            <StyledGridTableCell>
              <Skeleton width={30} />
            </StyledGridTableCell>
            <StyledGridTableCell>
              <Stack direction="row" spacing={1} alignItems="center">
                <Skeleton width={13} height={13} variant="circular" />
                <Skeleton width={40} height={13} />
              </Stack>
            </StyledGridTableCell>
            <StyledGridTableCell
              sx={{
                borderTopRightRadius: (theme) =>
                  `${theme.shape.borderRadius}px`,
                borderBottomRightRadius: (theme) =>
                  `${theme.shape.borderRadius}px`,
              }}
            >
              <Skeleton width={100} height={30} />
            </StyledGridTableCell>
          </Box>
        ))}
      </StyledGridTable>
    </Box>
  );
};

export default RegularSkeleton;
