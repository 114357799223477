import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import {
  StyledGridTokensTable,
  StyledGridTokensTableCell,
  StyledGridTokensTableHead,
  StyledGridTokensTableRow,
} from "../../../../components/StyledComponents";

const TokensSkeleton = () => {
  return (
    <Box sx={{ overflowX: "auto" }} mt={1.5}>
      <StyledGridTokensTable>
        <StyledGridTokensTableHead sx={{ justifyContent: "flex-start" }}>
          <Box ml="38px">Quantity</Box>
        </StyledGridTokensTableHead>
        <StyledGridTokensTableHead>Pool</StyledGridTokensTableHead>
        <StyledGridTokensTableHead>Change (24h)</StyledGridTokensTableHead>
        <StyledGridTokensTableHead>Value ($USD)</StyledGridTokensTableHead>
        <StyledGridTokensTableHead />
        <StyledGridTokensTableRow>
          <StyledGridTokensTableCell
            sx={{
              justifyContent: "flex-start",
              borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
              borderBottomLeftRadius: (theme) =>
                `${theme.shape.borderRadius}px`,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              maxWidth="100%"
            >
              <Skeleton width={30} height={30} />
              <Box textAlign="left" maxWidth={`calc(100% - 38px)`}>
                <Skeleton width={100} height={16} sx={{ mb: 0.75 }} />
                <Skeleton width={40} height={14} />
              </Box>
            </Stack>
          </StyledGridTokensTableCell>
          <StyledGridTokensTableCell>
            <Skeleton width={60} sx={{ height: { xs: 14, sm: 22 } }} />
          </StyledGridTokensTableCell>
          <StyledGridTokensTableCell>
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton width={60} sx={{ height: { xs: 14, sm: 22 } }} />
            </Stack>
          </StyledGridTokensTableCell>
          <StyledGridTokensTableCell
            sx={{
              borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
              borderBottomRightRadius: (theme) =>
                `${theme.shape.borderRadius}px`,
            }}
          >
            <Skeleton height={30} sx={{ width: { xs: "100%", sm: 82 } }} />
          </StyledGridTokensTableCell>
          <StyledGridTokensTableCell
            sx={{
              borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
              borderBottomRightRadius: (theme) =>
                `${theme.shape.borderRadius}px`,
            }}
          >
            <Skeleton height={30} sx={{ width: { xs: "100%", sm: 82 } }} />
          </StyledGridTokensTableCell>
        </StyledGridTokensTableRow>
      </StyledGridTokensTable>
    </Box>
  );
};

export default TokensSkeleton;
