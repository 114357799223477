import Stack from "@mui/material/Stack";
import Loader from "../components/Loader/Loader";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import ModalTransition from "../helpers/ModalTransition";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

type LoadingModalProps = {
  data:
    | {
        title: string;
        description: string;
      }
    | null
    | undefined;
  hide: () => void;
};

const LoadingModal = ({ data, hide }: LoadingModalProps) => {
  return (
    <Dialog
      open={!!data}
      onClose={undefined}
      TransitionComponent={ModalTransition}
      keepMounted
    >
      <DialogTitle>{data?.title}</DialogTitle>
      <DialogContent>
        <Stack alignItems="center" spacing={2} mt={1}>
          <Loader />
          <Typography>{data?.description}</Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
