import { initializeApp } from "firebase/app";

export const TRENDING_EXPERIENCES = [
  {
    title: "Tay Keith x Anybodies",
    description: "Limited drop: 1,000",
    src: "https://firebasestorage.googleapis.com/v0/b/nft-anybodies.appspot.com/o/landing%2Ftay-keith%2Fvideo.mp4?alt=media&token=c9dc3bcc-14e1-4605-a9bf-af3a4db90cb5",
    thumb:
      "https://firebasestorage.googleapis.com/v0/b/nft-anybodies.appspot.com/o/landing%2Ftay-keith%2Fthumb.png?alt=media&token=821f718b-801c-4309-a9cc-5c07eb70d948",
  },
  {
    title: "Toys R' Us",
    description: "Limited drop: 360",
    src: "https://firebasestorage.googleapis.com/v0/b/nft-anybodies.appspot.com/o/landing%2Fvideo.mp4?alt=media&token=8d24592d-f99a-43d5-b2ff-4ff154135c5b",
    thumb:
      "https://firebasestorage.googleapis.com/v0/b/nft-anybodies.appspot.com/o/landing%2Fthumb.png?alt=media&token=cdf823c3-d177-446e-a24a-96b533e179ee",
  },
  {
    title: "DeGods x Anybodies",
    description: "Limited drop: 200",
    src: "https://firebasestorage.googleapis.com/v0/b/nft-anybodies.appspot.com/o/landing%2FDegods%2Fvideo.mp4?alt=media&token=2a827489-0c40-46cc-b738-d9bcc7576e38",
    thumb:
      "https://firebasestorage.googleapis.com/v0/b/nft-anybodies.appspot.com/o/landing%2FDegods%2Fthumb.png?alt=media&token=042fae84-1d14-48d0-a79e-f67f37e69e2b",
  },
];

export const DEFAULT_API_ORIGIN = "https://anybodies-staging.web.app/";

export const FIREBASE_APP = initializeApp({
  apiKey: "AIzaSyABZVLQeN4r91nlyRHYww3Nr5yxblLRrfY",
  authDomain: "diamondvaults.io",
  projectId: "nft-anybodies",
  storageBucket: "nft-anybodies.appspot.com",
  messagingSenderId: "773321643955",
  appId: "1:773321643955:web:4d92166eafd8ab3958ddc6",
  measurementId: "G-QFKFEHZ0WL",
});

export const WS_URLS = {
  marketplace: "wss://site-demo-2.anybodies.com/marketplace/",
};
