import Box from "@mui/material/Box";
import Logo from "../../components/images/Logo";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DiscordIcon from "../../components/images/Discord";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import XIcon from "@mui/icons-material/X";
import Tooltip from "@mui/material/Tooltip";

const SOCIAL_LINKS = [
  {
    Icon: LinkedInIcon,
    link: "https://bit.ly/3nB5EdF",
    tooltip: "LinkedIn",
  },
  {
    Icon: XIcon,
    link: "https://bit.ly/3iRGCV6",
    tooltip: "X",
  },
  {
    Icon: () => <DiscordIcon width="1em" fill="#FFF" />,
    link: "https://discord.gg/hbjXv8fd3X",
    tooltip: "Discord",
  },
];

const Footer = () => {
  const location = useLocation();
  const shouldHideMenu = location.pathname.startsWith("/m/");

  const handleLink = (link: string) => {
    window.open(link, "_blank");
  };

  // if (shouldHideMenu) {
  //   return null;
  // }

  return (
    <footer style={{ marginTop: "auto" }}>
      <Box
        py={{ xs: 1, sm: 0.5 }}
        borderTop={1}
        borderColor="divider"
        sx={{
          ...(shouldHideMenu && {
            px: { xs: 1.5, md: 3 },
          }),
          ...(!shouldHideMenu && {
            mx: { xs: 1.5, md: 3 },
            mt: 3,
          }),
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={1}
          >
            <Logo height="1em" fill="#ffe000" />
            <Typography
              lineHeight="1.5em"
              textAlign={{ xs: "center", md: "left" }}
              color="text.secondary"
              variant="caption"
            >
              Copyright © {new Date().getFullYear()} Anybodies inc. All rights
              reserved.
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            {SOCIAL_LINKS.map(({ Icon, link, tooltip }) => (
              <Tooltip title={tooltip} key={tooltip}>
                <IconButton
                  key={link}
                  size="small"
                  onClick={() => {
                    handleLink(link);
                  }}
                >
                  <Icon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            ))}
          </Stack>
        </Stack>
      </Box>
    </footer>
  );
};

export default Footer;
