import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import prntBg from "../assets/banners/prnt/bg.jpg";
import prnt from "../assets/banners/prnt/logo.png";
import { Fragment } from "react";

const PROJECTS = [
  {
    label: "Okay Bears",
    link: "https://twitter.com/okaybears",
  },
  {
    label: "MadLads",
    link: "https://twitter.com/MadLads",
  },
  {
    label: "DeGods",
    link: "https://twitter.com/DeGodsNFT",
  },
  {
    label: "SagaDAO",
    link: "https://twitter.com/SagaMobileDAO",
  },
  {
    label: "AssetDash",
    link: "https://twitter.com/assetdash",
  },
  {
    label: "Trippin’ Ape Tribe",
    link: "https://twitter.com/TrippinApeNFT",
  },
  {
    label: "Fidelion",
    link: "https://twitter.com/FidelionNFT",
  },
  {
    label: "TombStoned",
    link: "https://twitter.com/TombStonedHS",
  },
  {
    label: "Bored Ape Solana Club",
    link: "https://twitter.com/BoredApeSolClub",
  },
  {
    label: "BroHalla",
    link: "https://twitter.com/BroHallaNFT",
  },
];

const Announcement = () => {
  return (
    <Box px={{ xs: 0, md: 3 }}>
      <Box
        sx={{
          overflow: "hidden",
          borderRadius: { xs: 0, md: 2 },
          mb: 2,
          backgroundColor: "rgba(37, 38, 44, 1)",
          backgroundImage: `url(${prntBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: { xs: "25dvh", sm: "35dvh", md: "50dvh" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          py: 5,
        }}
      >
        <Box
          maxWidth="100%"
          height="50%"
          margin="auto"
          px={1.5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& img": { maxHeight: "100%" },
          }}
        >
          <img src={prnt} alt="Anybodies x PRNT" />
        </Box>
      </Box>
      <Box px={{ xs: 1.5, sm: 3, md: 0 }}>
        <Typography variant="h4" fontWeight={900} mb={2}>
          ANYBODIES x PRNT
        </Typography>
        <Typography fontWeight={500} mb={1}>
          Anybodies Season 2 Drops has now begun!
          <br />
          <br />
          Following up the success of our (still ongoing){" "}
          <Link
            href="https://x.com/AnybodiesNFT/status/1688622296217305088?s=20"
            target="_blank"
          >
            $1,000,000 BONK campaign
          </Link>{" "}
          that has so far generated 10,000+ new BONK holders and injected
          $84,000 into BONK through token purchases, we’re kicking off S2
          rewards (with many more to come) by partnering with{" "}
          <Link href="https://twitter.com/printonsol" target="_blank">
            $PRNT
          </Link>{" "}
          to expand their ecosystem through a new reward avenue available to a
          select group of participating projects from nearly 100 applicants.
          <br />
          <br />
          Projects accepted into the activation include:{" "}
          {PROJECTS.map((project, index) => {
            if (index === PROJECTS.length - 1) {
              return (
                <Fragment key={project.label}>
                  {" "}
                  and{" "}
                  <Link href={project.link} target="_blank">
                    {project.label}
                  </Link>
                </Fragment>
              );
            }
            return (
              <Fragment key={project.label}>
                {index > 0 ? ", " : ""}
                <Link href={project.link} target="_blank">
                  {project.label}
                </Link>
              </Fragment>
            );
          })}
          .
        </Typography>
        <Typography variant="h6" fontWeight={700}>
          Campaign Highlights:
        </Typography>
        <ol style={{ marginBottom: 16, paddingLeft: 18, fontWeight: 500 }}>
          <li>
            Accepted projects will receive a $PRNT token reward pool with{" "}
            <strong>3,874,500 $PRNT</strong>.
          </li>
          <li>
            Each individual wallet must hold 1,500 $PRNT to be granted initial
            subscription access to receive $PRNT rewards.
          </li>
          <li>
            Phase 01 (First 30 days):
            <ol style={{ paddingLeft: 18, fontWeight: 500 }}>
              <li>
                Each subscribed community member will receive{" "}
                <strong>75 $PRNT</strong> per NFT subscribed daily.
              </li>
              <li>
                To maximize rewards per individual participant, a cap of 500
                NFTs (3 NFTs per wallet) can be subscribed per community during
                this phase.
              </li>
            </ol>
          </li>
          <li>
            Phase 02 (Second 30 days):
            <ol style={{ paddingLeft: 18, fontWeight: 500 }}>
              <li>
                Each subscribing community member will receive{" "}
                <strong>50 $PRNT</strong> per NFT subscribed daily.
              </li>
              <li>
                The NFT subscription cap raises to 1,500 NFTs (3 NFTs per
                wallet) for this phase.
              </li>
            </ol>
          </li>
          <li>
            Subscribers will be able to contribute their earned $PRNT directly
            into $PRNT’s liquidity pool via{" "}
            <Link href="https://twitter.com/HawksightCo" target="_blank">
              Hawksight
            </Link>{" "}
            to maximize rewards further. 15% of the fees generated by this
            campaign will also be added to the $PRNT liquidity pool.
          </li>
        </ol>
        <Typography variant="h6" fontWeight={700}>
          Campaign Milestones & Bonuses:
        </Typography>
        <ol style={{ marginBottom: 16, paddingLeft: 18, fontWeight: 500 }}>
          <li>
            Once a $PRNT token reward pool is created, projects can look forward
            to a 30-day milestone during the campaign.
          </li>
          <li>
            If a project has 160+ unique wallets subscribed at 30 days, it will
            progress to Phase 02.
          </li>
          <li>At 60 days, the campaign ends.</li>
          <li>
            On day 61, if a community has 450 unique wallets subscribed, then a
            snapshot will be taken to assess whether or not the community
            collectively increased its LP contribution by 50% of the total $PRNT
            allocation earned through the campaign
            <ol style={{ paddingLeft: 18, fontWeight: 500 }}>
              <li>
                If the 50% metric is achieved, each individual subscription will
                be awarded <strong>333 $PRNT</strong> distributed across 24
                hours.
              </li>
              <li>
                If the 50% metric is not achieved, the bonus reward will be lost
                and a seven-day claim window for already-accumulated awards will
                begin.{" "}
                <strong>
                  Please ensure that you claim your accumulated $PRNT across
                  each participating wallet within the window, or they will be
                  lost.
                </strong>
              </li>
            </ol>
          </li>
        </ol>
        <Typography fontWeight={500} mb={2}>
          Connect with us via the{" "}
          <Link href="https://twitter.com/AnybodiesNFT" target="_blank">
            Anybodies X account
          </Link>{" "}
          for future updates and reach out to the{" "}
          <Link href="https://www.twitter.com/anybodies_help" target="_blank">
            Anybodies support X account
          </Link>{" "}
          if any questions come up.
          <br />
          <br />
          Welcome to the Anybodies Platform and happy subscribing!
        </Typography>
      </Box>
    </Box>
  );
};

export default Announcement;
