import { useQuery } from "@tanstack/react-query";
import { CommunityStatsByRouteResponse } from "../../../../types";
import { useParams } from "react-router-dom";
import { useHttpsCallable } from "../../../../services/FirebaseService";

const useGetCommunityStats = () => {
  const path = "community/stats";
  const { communityName } = useParams();
  const fbFn = useHttpsCallable(path);

  const getCommunityInfo = async () => {
    const { data } = await fbFn({ route: communityName });
    const res = data as CommunityStatsByRouteResponse;
    if (!res?.success) {
      throw new Error(
        `Community ${communityName} not found. Please check the link. It's most likely incorrect`
      );
    }
    return res?.communityStats;
  };

  return useQuery([path, communityName], getCommunityInfo, {
    staleTime: 60 * 60 * 1000,
    enabled: !!communityName,
  });
};

export default useGetCommunityStats;
