import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import AdjustRoundedIcon from "@mui/icons-material/AdjustRounded";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material";

type Props = {
  title: string;
  description: string;
  type: "completed" | "progress" | "incomplete";
  isLast: boolean;
};

const QuestStep = ({ title, description, type, isLast }: Props) => {
  const theme = useTheme();

  const CONFIG = {
    completed: {
      dotColor: theme.palette.text.primary,
      dotBg: theme.palette.success.main,
      cardBg: "linear-gradient(90deg, #315043 0%, rgba(35,35,35,0.6) 30%)",
      cardBorderColor: "#11F695",
    },
    progress: {
      dotColor: theme.palette.background.default,
      dotBg: theme.palette.primary.main,
      cardBg: "linear-gradient(90deg, #3b3926 0%, rgba(35,35,35,0.6) 30%)",
      cardBorderColor: theme.palette.primary.main,
    },
    incomplete: {
      dotColor: theme.palette.text.disabled,
      dotBg: theme.palette.background.paper,
      cardBg: "linear-gradient(90deg, #404040 0%, rgba(35,35,35,0.6) 30%)",
      cardBorderColor: "#939393",
    },
  };

  return (
    <TimelineItem sx={{ minHeight: 0 }}>
      <TimelineSeparator sx={{ mt: 0.5 }}>
        <TimelineDot
          sx={{
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            color: CONFIG[type].dotColor,
            backgroundColor: CONFIG[type].dotBg,
            mt: 0,
            mb: 0.5,
          }}
        >
          {type === "completed" ? (
            <CheckCircleRoundedIcon />
          ) : type === "progress" ? (
            <AdjustRoundedIcon />
          ) : (
            <LockRoundedIcon />
          )}
        </TimelineDot>
        {!isLast && (
          <TimelineConnector
            sx={{
              backgroundColor:
                type === "completed" ? theme.palette.success.main : "default",
            }}
          />
        )}
      </TimelineSeparator>
      <TimelineContent
        sx={{
          mt: 0,
          mb: isLast ? 0 : 2.5,
          ml: 1.5,
          p: 0,
        }}
      >
        <Accordion
          sx={{
            borderColor: CONFIG[type].cardBorderColor,
            background: CONFIG[type].cardBg,
            backdropFilter: "blur(2px)",
            borderRadius: "5px !important",
          }}
          variant="outlined"
          color="success"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              px: 1.25,
              minHeight: 0,
              "& .MuiAccordionSummary-content": {
                width: "100%",
                flexGrow: 1,
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                my: 1.25,
                mr: 1,
              },
            }}
          >
            <Typography variant="subtitle2" fontWeight={600}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 1.25, pt: 0, pb: 1.25 }}>
            <Typography variant="body2" whiteSpace="pre-line">
              {description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  );
};

export default QuestStep;
