import { useWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from "notistack";
import { useHttpsCallable } from "../../../services/FirebaseService";
import { CommunitiesByWalletResponse } from "../../../types";
import { useQuery } from "@tanstack/react-query";

const useCommunitiesListByWallet = () => {
  const { enqueueSnackbar } = useSnackbar();
  const path = "communities/by-wallet";
  const { publicKey } = useWallet();
  const fbFn = useHttpsCallable(path);

  const getSubscriptionsList = async () => {
    const { data } = await fbFn({
      walletAddress: publicKey?.toString(),
    });
    const res = data as CommunitiesByWalletResponse;
    if (!res?.success) {
      throw new Error(`${res?.error?.title}\n${res?.error?.description}`);
    }
    return res;
  };

  return useQuery([path, publicKey?.toString()], getSubscriptionsList, {
    onError: (err: any) => {
      enqueueSnackbar({
        variant: "error",
        message: err?.message || "Something went wrong. Try again, please",
      });
    },
    enabled: !!publicKey,
  });
};

export default useCommunitiesListByWallet;
