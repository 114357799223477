import { useWallet } from "@solana/wallet-adapter-react";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { useQuery } from "@tanstack/react-query";

const useGetDiscordVerified = () => {
  const path = "verify/state";
  const fbFn = useHttpsCallable(path);
  const { publicKey } = useWallet();

  const getVerification = async () => {
    const { data } = await fbFn({ walletAddress: publicKey?.toString() });
    const res = data as {
      success: boolean;
      verified: boolean;
      discordUser: {
        username: string;
        discriminator: string;
      };
    };
    if (!res?.success) {
      throw new Error(`Cannot get`);
    }
    return res;
  };

  return useQuery([path, publicKey?.toString()], getVerification, {
    staleTime: 30 * 60 * 1000,
    enabled: !!publicKey,
  });
};
export default useGetDiscordVerified;
