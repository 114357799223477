import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CreateCommunityBannerWrapper } from "../../components/StyledComponents";

const CreateCommunityBanner = () => {
  return (
    <Box
      sx={{
        width: 1000,
        maxWidth: "100%",
        margin: "auto",
      }}
    >
      <CreateCommunityBannerWrapper>
        <Box
          bgcolor={(theme) => theme.palette.background.default}
          textAlign={"center"}
          p={{ xs: 4, md: 6 }}
          sx={{
            borderRadius: (theme) => `${theme.shape.borderRadius * 2}px`,
          }}
        >
          <Typography
            variant="h4"
            fontWeight={600}
            width="800px"
            margin="auto"
            maxWidth="100%"
            className="text"
          >
            Supercharge your community with web3-powered loyalty programs
          </Typography>
          <Button
            sx={{
              mt: { xs: 3, md: 5 },
              paddingLeft: { md: 10 },
              paddingRight: { md: 10 },
              textTransform: "uppercase",
            }}
            size="large"
            onClick={() => {
              window.open("https://manage.diamondvaults.io/", "_blank");
            }}
          >
            Get started
          </Button>
        </Box>
      </CreateCommunityBannerWrapper>
    </Box>
  );
};

export default CreateCommunityBanner;
