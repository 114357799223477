import Popper from "@mui/material/Popper";
import { ChangeEvent, useState } from "react";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TYPE_COLORS } from "./MarketplaceActivity";
import { MarketplaceServiceActivityProps } from "../types";
import Link from "@mui/material/Link";

type Props = {
  anchorEl: HTMLButtonElement | null;
  activityFilter: MarketplaceServiceActivityProps["activityFilter"];
  handleActivityFilterChange: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  handleAll: () => void;
  handleNone: () => void;
};

const MarketplaceActivityFilter = ({
  anchorEl,
  activityFilter,
  handleActivityFilterChange,
  handleAll,
  handleNone,
}: Props) => {
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Popper
      disablePortal
      open
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      sx={{ zIndex: 10, position: "relative" }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={5} sx={{ p: 1.5, minWidth: 180 }}>
            <Box
              ref={setArrowRef}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                marginTop: "-0.9em",
                fontSize: 7,
                width: "3em",
                height: "1em",
                marginLeft: "-1px",
                "&::before": {
                  content: '""',
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  borderStyle: "solid",
                  borderWidth: "0 1em 1em 1em",
                  borderColor: "transparent transparent #2a2a2c transparent",
                },
              }}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              mb={1}
            >
              <Typography fontWeight={700}>Filters</Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Link
                  color="text.secondary"
                  component="button"
                  underline="hover"
                  variant="overline"
                  lineHeight={1}
                  onClick={handleAll}
                  disabled={activityFilter.logTypes.length === 4}
                >
                  All
                </Link>
                <Link
                  color="text.secondary"
                  component="button"
                  underline="hover"
                  variant="overline"
                  lineHeight={1}
                  onClick={handleNone}
                  disabled={activityFilter.logTypes.length === 0}
                >
                  None
                </Link>
              </Stack>
            </Stack>
            <Typography
              variant="overline"
              component="div"
              lineHeight={1}
              mb={0.5}
              color="text.secondary"
            >
              By type
            </Typography>
            <Stack>
              {Object.keys(TYPE_COLORS).map((type) => {
                return (
                  <FormControlLabel
                    key={type}
                    sx={{ m: 0, userSelect: "none" }}
                    control={
                      <Checkbox
                        size="small"
                        checked={activityFilter.logTypes.includes(type as any)}
                        name={type}
                        sx={{
                          py: 0,
                          pl: 0,
                          pr: 0.5,
                          "&.Mui-checked": {
                            color: (TYPE_COLORS as any)[type],
                          },
                        }}
                        disableRipple
                        onChange={handleActivityFilterChange}
                      />
                    }
                    label={type.charAt(0).toUpperCase() + type.slice(1)}
                  />
                );
              })}
            </Stack>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default MarketplaceActivityFilter;
