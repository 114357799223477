import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

type Props = {
  setIsLedger: (is: boolean) => void;
  handleVerify: () => void;
  loading: boolean;
};

const VerifyAction = ({ setIsLedger, handleVerify, loading }: Props) => {
  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox />}
          label={
            <>
              I am using a{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.ledger.com/"
                style={{ color: "#FFF" }}
              >
                Ledger
              </a>
            </>
          }
          onChange={(e: any) => setIsLedger(e.target.checked)}
        />
      </FormGroup>
      <Button onClick={handleVerify} disabled={loading} sx={{ mt: 2 }}>
        Click here to verify
      </Button>
    </Box>
  );
};

export default VerifyAction;
