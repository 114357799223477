import { useSnackbar } from "notistack";
import {
  BoosterPlan,
  ContractForWalletItem,
  GetContractForWalletResponse,
  GetTokensMetadataResponse,
  Manifest,
  Plan,
} from "../../../../../types";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";
import { useWallet } from "@solana/wallet-adapter-react";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { useQuery } from "@tanstack/react-query";

const useNftListByWalletAndPlan = (
  planId?: Plan["AStakingProgramId"] | BoosterPlan["AStakingProgramId"],
  isBooster?: boolean
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: communityInfo } = useGetCommunityInfo();
  const path = isBooster ? "boosters/available" : "subscriptions/available";
  const pathMetadata = "solana/tokens/metadata";
  const { publicKey } = useWallet();
  const fbFnTokens = useHttpsCallable(path);
  const fbFnMetadata = useHttpsCallable(pathMetadata);

  const getWalletNftList = async () => {
    const { data: tokensData } = await fbFnTokens({
      walletAddress: publicKey?.toString(),
      accountId: communityInfo?.accountId,
      communityId: communityInfo?.communityId,
      contractId: planId,
    });

    const resTokens = tokensData as GetContractForWalletResponse;
    if (!resTokens?.success) {
      throw new Error(
        `${resTokens?.error?.title}\n${resTokens?.error?.description}`
      );
    }
    if (resTokens?.availableTokens?.length === 0) return [];
    const mintAdresses = isBooster
      ? (resTokens?.availableTokens as string[])
      : (resTokens?.availableTokens as ContractForWalletItem[])?.map(
          (t) => t?.tokenAddress
        );

    const mintAddressesGroups: string[][] = [];
    const mintAddressesGroupsSize = 10;
    while (mintAdresses.length > 0)
      mintAddressesGroups.push(mintAdresses.splice(0, mintAddressesGroupsSize));

    const tokensMetadataResponse: Array<
      Manifest & { lastKnownSession?: number }
    > = [];
    await Promise.all(
      mintAddressesGroups.map(async (minAddresses) => {
        const { data: tokensMetadata } = await fbFnMetadata(minAddresses);
        const res = tokensMetadata as GetTokensMetadataResponse;
        if (!res?.success) return;
        if (res?.metadataTokens?.length > 0) {
          res?.metadataTokens?.forEach((t) => {
            if (!isBooster) {
              const tokens =
                resTokens?.availableTokens as ContractForWalletItem[];
              const lastKnownSession =
                tokens?.find((a: any) => a?.tokenAddress === t?.mintAddress)
                  ?.lastKnownSession || 0;
              tokensMetadataResponse.push({ ...t, lastKnownSession });
              return;
            }
            tokensMetadataResponse.push(t);
          });
        }
      })
    );
    return tokensMetadataResponse;
  };

  return useQuery(
    [
      path,
      publicKey?.toString(),
      communityInfo?.accountId,
      communityInfo?.communityId,
      planId,
    ],
    getWalletNftList,
    {
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      enabled:
        !!publicKey &&
        !!communityInfo?.accountId &&
        !!communityInfo?.communityId &&
        !!planId,
    }
  );
};

export default useNftListByWalletAndPlan;
