import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MarketplaceStatsItem from "./MarketplaceStatsItem";
import useGetMarketplaceDetails from "../../../../hooks/queries/marketplace/state/useGetMarketplaceDetails";
import { useMarketplace } from "../../services/Main";

const MarketplaceStats = () => {
  const { marketplaceId } = useMarketplace();
  const { data: marketplaceDetails, isLoading: marketplaceDetailsLoading } =
    useGetMarketplaceDetails(marketplaceId);

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        display: "flex",
        py: 1,
        px: 2,
        borderRadius: "12px",
        overflowX: "auto",
        maxWidth: "100%",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        flexShrink={0}
        sx={{
          "> *": {
            "&:not(:last-child)": {
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
              paddingRight: 2,
            },
          },
        }}
      >
        <MarketplaceStatsItem
          title="Listed"
          value={
            marketplaceDetails?.currentListedCount
              ? marketplaceDetails?.currentListedCount?.toLocaleString()
              : "—"
          }
          isLoading={marketplaceDetailsLoading}
        />
        <MarketplaceStatsItem
          title="Floor price"
          value={
            marketplaceDetails?.floorInfo?.floorPrice
              ? marketplaceDetails?.floorInfo?.floorPrice?.toLocaleString()
              : "—"
          }
          isLoading={marketplaceDetailsLoading}
        />
        <MarketplaceStatsItem
          title="Volume (24H)"
          value={
            marketplaceDetails?.volumeInfo?.candleVolume
              ? marketplaceDetails?.volumeInfo?.candleVolume?.toLocaleString()
              : "—"
          }
          isLoading={marketplaceDetailsLoading}
        />
        <MarketplaceStatsItem
          title="Volume (All)"
          value={
            marketplaceDetails?.volumeInfo?.allTimeVolume
              ? marketplaceDetails?.volumeInfo?.allTimeVolume?.toLocaleString()
              : "—"
          }
          isLoading={marketplaceDetailsLoading}
        />
        <MarketplaceStatsItem
          title="Sales (24h)"
          value={
            marketplaceDetails?.sales?.salesCount
              ? marketplaceDetails?.sales?.salesCount?.toLocaleString()
              : "—"
          }
          isLoading={marketplaceDetailsLoading}
        />
        <MarketplaceStatsItem
          title="PRICE Δ (24H)"
          value={
            marketplaceDetails?.floorInfo?.floorPriceChangePercent
              ? `${marketplaceDetails?.floorInfo?.floorPriceChangePercent?.toFixed(
                  2
                )}%`
              : "—"
          }
          isLoading={marketplaceDetailsLoading}
        />
      </Stack>
    </Box>
  );
};

export default MarketplaceStats;
