import {
  StyledContractActiveSubscriptionsBadge,
  StyledContractBox,
  StyledContractRewardBox,
} from "../StyledComponents";
import {
  BoosterPlan,
  PlanBoosterComponentType,
  PlanComponentType,
} from "../../types";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useCommunity } from "../../services/CommunityService";
import { useWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from "notistack";
import Discounts from "../images/plans/Discounts";
import Redeemables from "../images/plans/Redeemables";
import Events from "../images/plans/Events";
import ExpandableTypography from "../ExpandableTypography";
import HowItWorks from "./HowItWorks";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import Tooltip from "@mui/material/Tooltip";
import SmallCoin from "./SmallCoin/SmallCoin";

interface Props {
  data: PlanBoosterComponentType | PlanComponentType;
  activePlans: number;
}

const Contract = ({ data, activePlans }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { publicKey } = useWallet();
  const {
    setNftModalData,
    setContractLevelsModalData,
    setSelectedFilterPlan,
    setSelectedBoostersFilterPlan,
    setCampaignContractModalData,
  } = useCommunity();

  const handleShowCampaignModal = () => {
    if (!setCampaignContractModalData || data?.isBooster) return;
    setCampaignContractModalData(data?.plan);
  };

  const handleView = () => {
    if (data?.isBooster) {
      if (!setSelectedBoostersFilterPlan) return;
      setSelectedBoostersFilterPlan(data?.plan?.AStakingProgramId);
    } else {
      if (!setSelectedFilterPlan) return;
      setSelectedFilterPlan(data?.plan?.AStakingProgramId);
    }
    const section = document.querySelector(
      data?.isBooster ? "#boosters" : "#subscriptions"
    );
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleSubscribe = () => {
    if (!setNftModalData) return;
    if (!publicKey) {
      enqueueSnackbar({
        variant: "info",
        message: "Login to gain access",
      });
      return;
    }
    setNftModalData(data);
  };

  const handleShowLevelsModal = () => {
    if (
      !setContractLevelsModalData ||
      data?.isBooster ||
      !data?.plan?.maturityActive
    )
      return;
    setContractLevelsModalData({
      baseDailyReward: undefined,
      step: data?.plan?.StakingDuration,
      levels: data?.plan?.maturityLevels,
    });
  };

  return (
    <Box pr={{ xs: 1.5, md: 3 }}>
      <StyledContractBox>
        <Stack justifyContent="space-between" height="100%">
          <div>
            {!!activePlans && (
              <StyledContractActiveSubscriptionsBadge>
                <Typography variant="body2" fontWeight={500} ml={1}>
                  You've got <strong>{activePlans}</strong> active subs!
                </Typography>
                <Button variant="text" color="inherit" onClick={handleView}>
                  View
                </Button>
              </StyledContractActiveSubscriptionsBadge>
            )}
            <Box p={2}>
              <Typography variant="h6" fontWeight="bold">
                {!data?.isBooster && data?.plan?.TokenData?.tokenMetadata && (
                  <Tooltip
                    title={`$${data?.plan?.TokenData?.tokenMetadata?.symbol?.toUpperCase()}`}
                    placement="top"
                  >
                    <Avatar
                      src={data?.plan?.TokenData?.tokenMetadata?.uri}
                      alt={data?.plan?.TokenData?.tokenMetadata?.name}
                      sx={{
                        width: 24,
                        height: 24,
                        flexShrink: 0,
                        verticalAlign: "text-bottom",
                        display: "inline-flex",
                        mr: "0.5rem",
                      }}
                      variant="circular"
                    >
                      <InsertPhotoRoundedIcon sx={{ fontSize: "1rem" }} />
                    </Avatar>
                  </Tooltip>
                )}
                {data?.plan?.PlanTitle}
              </Typography>
              <ExpandableTypography
                text={data?.plan?.PlanDescription?.trim() || ""}
                variant="body2"
                sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
              />
              {!data?.isBooster && !!data?.plan?.tokenCampaign && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                    <SmallCoin />
                    <Typography variant="body1" fontWeight="bold">
                      Airdrop
                    </Typography>
                  </Stack>
                  <Typography variant="body2" mb={1}>
                    Thanks to your community's efforts, this token airdrop
                    campaign is now available.
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    onClick={handleShowCampaignModal}
                  >
                    Info about campaign
                  </Button>
                </>
              )}
              {data?.isBooster && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <Typography
                    variant="body1"
                    mb={1}
                    paragraph
                    fontWeight="bold"
                  >
                    Boost info
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{
                      backgroundColor: (theme) => theme.palette.grey[700],
                      borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                      p: 2,
                    }}
                  >
                    {getBonusInfo(
                      data?.plan?.MultiplierAction,
                      data?.plan?.MultiplierValue
                    )}
                  </Stack>
                </>
              )}
              {!data?.isBooster &&
                data?.plan?.benefitsPreview &&
                !Object.keys(data?.plan?.benefitsPreview).every(
                  (k) => !data?.plan?.benefitsPreview?.[k]
                ) && (
                  <>
                    <Divider sx={{ my: 1 }} />
                    <Typography
                      variant="body1"
                      mb={1}
                      paragraph
                      fontWeight="bold"
                    >
                      Rewards
                    </Typography>
                    <Grid
                      container
                      spacing={{ xs: 1, md: 2 }}
                      mb={2}
                      flexWrap="nowrap"
                    >
                      {Object.keys(data?.plan?.benefitsPreview).map((key) =>
                        data?.plan?.benefitsPreview?.[key] ? (
                          <Grid item xs={4} key={key}>
                            <StyledContractRewardBox>
                              <Stack
                                width={40}
                                height={42}
                                alignItems="center"
                                justifyContent="center"
                                mb={1}
                              >
                                {key === "discounts" ? (
                                  <Discounts />
                                ) : key === "redeemables" ? (
                                  <Redeemables />
                                ) : (
                                  <Events />
                                )}
                              </Stack>
                              <Typography fontWeight={600}>
                                {key.charAt(0).toUpperCase() + key.slice(1)}
                              </Typography>
                            </StyledContractRewardBox>
                          </Grid>
                        ) : null
                      )}
                    </Grid>
                  </>
                )}
              <HowItWorks data={data} showLevelsModal={handleShowLevelsModal} />
              {!data?.isBooster && (
                <>
                  <Divider sx={{ my: 1 }} />
                  <Grid container spacing={1}>
                    <Grid item xs={6} textAlign="center">
                      <Typography
                        fontWeight="bold"
                        color="text.secondary"
                        variant="caption"
                        lineHeight={1}
                        mb={0.5}
                        component="div"
                      >
                        Subscriptions
                      </Typography>
                      <Typography fontWeight={900} lineHeight={1}>
                        {data?.plan?.totalSubscriptions || "0"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="center">
                      <Typography
                        fontWeight="bold"
                        color="text.secondary"
                        variant="caption"
                        lineHeight={1}
                        mb={0.5}
                        component="div"
                      >
                        Unique subscribers
                      </Typography>
                      <Typography fontWeight={900} lineHeight={1}>
                        {data?.plan?.uniqueSubscribedWallets || "0"}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </div>
          <Box sx={{ px: 2, pb: 2 }}>
            <Button fullWidth onClick={handleSubscribe}>
              Subscribe
            </Button>
            {!data?.isBooster && (
              <Typography
                variant="caption"
                color="text.secondary"
                lineHeight={1.2}
                component="div"
                mt={0.75}
              >
                {data?.plan?.rulesDetails?.maxSubscriptionsActive && (
                  <>
                    Max subscriptions:{" "}
                    <strong>
                      {data?.plan?.rulesDetails?.maxSubscriptions || "0"}
                    </strong>
                    <br />
                  </>
                )}
                {data?.plan?.rulesDetails?.maxSubscriptionsPerWalletActive && (
                  <>
                    Max subscriptions per wallet:{" "}
                    <strong>
                      {data?.plan?.rulesDetails?.maxSubscriptionsPerWallet}
                    </strong>
                    <br />
                  </>
                )}
                {data?.plan?.rulesDetails?.canSubscribeRuleIdActive && (
                  <>
                    Rule:{" "}
                    <strong>
                      {data?.plan?.rulesDetails?.canSubscribeRuleDescription}
                    </strong>
                    <br />
                  </>
                )}
              </Typography>
            )}
          </Box>
        </Stack>
      </StyledContractBox>
    </Box>
  );
};

export default Contract;

const getBonusInfo = (
  MultiplierAction: BoosterPlan["MultiplierAction"],
  MultiplierValue: BoosterPlan["MultiplierValue"]
) => {
  switch (MultiplierAction) {
    case "multiplication":
      return (
        <>
          <Typography variant="h4" textAlign="center">
            <strong>{MultiplierValue}</strong>x
          </Typography>
          <Typography>
            <strong>Multiplies</strong> a Staked NFT's daily rewards
          </Typography>
        </>
      );
    case "addition":
      return (
        <>
          <Typography variant="h4" textAlign="center">
            +<strong>{MultiplierValue}</strong>
          </Typography>
          <Typography>
            <strong>Adds</strong> a bonus to a staked NFT's daily rewards
          </Typography>
        </>
      );
    case "fixed":
      return (
        <>
          <Box textAlign="center" whiteSpace="nowrap">
            <Typography variant="h4">
              <strong>{MultiplierValue}</strong>
            </Typography>
            <Typography variant="caption">token / day</Typography>
          </Box>
          <Typography>
            <strong>Boost</strong> the daily reward of staked NFTs to fixed
            boosted value
          </Typography>
        </>
      );

    default:
      return <></>;
  }
};
