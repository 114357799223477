import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useHttpsCallable } from "../../../../services/FirebaseService";
import { CheckEligibilityResponse } from "../../../../types";
import { useWallet } from "@solana/wallet-adapter-react";

const useCheckClaimableEligibility = (enabled: boolean) => {
  const path = "claimable/check-eligibility";
  const { uniqueLink } = useParams();
  const { publicKey } = useWallet();
  const fn = useHttpsCallable(path);

  const checkEligibility = async () => {
    const { data } = await fn({
      uniqueLink,
      walletAddress: publicKey?.toString(),
    });
    return data as CheckEligibilityResponse;
  };

  return useQuery([path, uniqueLink, publicKey?.toString()], checkEligibility, {
    staleTime: 30 * 1000, // 30 sec
    enabled: !!uniqueLink && !!publicKey && enabled,
  });
};

export default useCheckClaimableEligibility;
