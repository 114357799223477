import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { MaturityLevel } from "../../types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ModalTransition from "../../helpers/ModalTransition";
import { alpha } from "@mui/material/styles";

export interface ModalLevelsProps {
  data?: {
    baseDailyReward?: number;
    step: number;
    levels: MaturityLevel[];
    currentLevel?: number;
  };
  hide: () => void;
}

const ModalLevels = ({ data, hide }: ModalLevelsProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let currentDay = 0;

  return (
    <Dialog
      open={!!data}
      onClose={hide}
      keepMounted
      fullScreen={fullScreen}
      TransitionComponent={ModalTransition}
    >
      <DialogTitle
        fontWeight={700}
        sx={{ px: { xs: 1.5, sm: 2.5 }, py: { xs: 1, sm: 2 } }}
      >
        Levels{data?.currentLevel ? "" : " simulation"}
      </DialogTitle>
      <DialogContent sx={{ px: { xs: 1.5, sm: 2.5 }, pb: { xs: 1, sm: 2 } }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    borderTopWidth: 1,
                  }}
                >
                  Stage
                  <Typography
                    variant="overline"
                    color="gray"
                    lineHeight={1}
                    fontSize={10}
                    component="div"
                  >
                    Duration
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: "bold", borderTopWidth: 1 }}
                >
                  Daily reward
                  <Typography
                    variant="overline"
                    color="gray"
                    fontSize={10}
                    component="div"
                    lineHeight={1}
                  >
                    Tokens
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.levels?.map(
                ({ stakingSession, addedRewards }, index: number) => {
                  const isCurrentLevel = index + 1 === data?.currentLevel;
                  return (
                    <TableRow
                      sx={{
                        backgroundColor: isCurrentLevel
                          ? alpha(theme.palette.divider, 0.2)
                          : "unset",
                      }}
                      key={index}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          fontWeight: "bold",
                          color: isCurrentLevel ? "primary.main" : "inherit",
                        }}
                      >
                        Level {index + 1}
                        <Typography
                          variant="overline"
                          color="gray"
                          fontSize={10}
                          component="div"
                          fontWeight="bold"
                          lineHeight={1}
                        >
                          days {index === 0 ? currentDay : currentDay + 1}
                          {data?.levels.length === index + 1
                            ? "+"
                            : ` - ${(currentDay +=
                                stakingSession * data?.step)}`}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {typeof data?.baseDailyReward === "undefined" ? (
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={0.75}
                          >
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              component="div"
                            >
                              Base reward
                            </Typography>
                            {!!addedRewards && (
                              <Box>
                                <Chip
                                  color="secondary"
                                  size="small"
                                  label={`+ ${addedRewards?.toLocaleString(
                                    "en-US"
                                  )}`}
                                  sx={{ fontWeight: 700, height: "auto" }}
                                />
                              </Box>
                            )}
                          </Stack>
                        ) : (
                          <Box>
                            <Chip
                              color="secondary"
                              size="small"
                              label={(
                                data?.baseDailyReward + addedRewards
                              )?.toLocaleString("en-US")}
                              sx={{ fontWeight: 700, height: "auto" }}
                            />
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              component="div"
                            >
                              {`Base reward${
                                addedRewards
                                  ? ` + ${addedRewards?.toLocaleString(
                                      "en-US"
                                    )}`
                                  : ""
                              }`}
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          variant="caption"
          color="text.secondary"
          component="div"
          mt={1}
        >
          * extra rewards will be added to daily base reward per sub-collection
          by level
        </Typography>
      </DialogContent>
      <DialogActions sx={{ pt: 0, px: { xs: 1.5, sm: 2.5 } }}>
        <Button color="secondary" variant="base" onClick={hide} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalLevels;
