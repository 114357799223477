import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModalTransition from "../../helpers/ModalTransition";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import useGetRewardDetails from "../../hooks/queries/community/rewards/state/useGetRewardDetails";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import moment, { Moment } from "moment";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import Chip from "@mui/material/Chip";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";
import Link from "@mui/material/Link";
import useCheckRewardEligibility from "../../hooks/queries/community/rewards/state/useCheckRewardEligibility";
import Loader from "../../components/Loader/Loader";
import Alert from "@mui/material/Alert";
import useClaimRewardMutation from "../../hooks/queries/community/rewards/mutations/useClaimRewardMutation";
import LoadingButton from "@mui/lab/LoadingButton";
import Skeleton from "@mui/material/Skeleton";
import useRedeemRewardMutation from "../../hooks/queries/community/rewards/mutations/useRedeemRewardMutation";
import { useEffect, useState } from "react";
import RewardDetailsCode from "./RewardDetailsCode";
import {
  ClaimedReward,
  RedeemResponseSuccessData,
  RewardFulfillmentTypes,
} from "../../types";
import RewardDetailsTimeline from "./RewardDetailsTimeline";
import AlertTitle from "@mui/material/AlertTitle";
import QRCode from "react-qr-code";
import { CustomView } from "react-device-detect";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { copyTextToClipboard } from "../../utils";

type Props = {
  data:
    | {
        campaignId: string;
        redeemable?: {
          mintAddress: string;
          status: ClaimedReward["status"];
          redeemedImage?: string;
        };
      }
    | undefined;
  hide: () => void;
};

const RewardDetails = ({ data, hide }: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data: rewardDetails, isLoading: rewardDetailsLoading } =
    useGetRewardDetails(data?.campaignId);
  const { data: checkEligibilityRes, isLoading: checkEligibilityResLoading } =
    useCheckRewardEligibility(
      !rewardDetails ||
        rewardDetails?.status === "ended" ||
        rewardDetails?.claimPercent >= 100 ||
        !!data?.redeemable
        ? undefined
        : data?.campaignId
    );
  const [redeemResponse, setRedeemResponse] = useState<
    RedeemResponseSuccessData | undefined
  >();
  const [isAppleCopied, setIsAppleCopied] = useState(false);
  const [isGoogleCopied, setIsGoogleCopied] = useState(false);
  const { mutateAsync: claim, isLoading: claimLoading } =
    useClaimRewardMutation();
  const { mutateAsync: redeem, isLoading: redeemLoading } =
    useRedeemRewardMutation();

  useEffect(() => {
    return setRedeemResponse(undefined);
  }, [data]);

  let endDate: Moment | undefined =
    data?.redeemable || redeemResponse
      ? moment(rewardDetails?.redeemExpirationDate)
      : moment(rewardDetails?.claimExpirationDate);
  endDate = endDate?.isValid() ? endDate : undefined;

  const handleClaim = async () => {
    if (!data?.campaignId) return;
    const response = await claim(data?.campaignId);
    if (response) {
      if (rewardDetails?.autoRedeem) {
        setRedeemResponse((response as any)?.data as RedeemResponseSuccessData);
      } else {
        hide();
      }
    }
  };

  const handleRedeem = async () => {
    if (!data?.redeemable?.mintAddress) return;
    const res = await redeem(data?.redeemable?.mintAddress);
    if (res) {
      setRedeemResponse(res);
    }
  };

  const handleCopyClick = async (text: string, type: "apple" | "google") => {
    await copyTextToClipboard(text);
    if (type === "apple") {
      setIsAppleCopied(true);
      setTimeout(() => {
        setIsAppleCopied(false);
      }, 1500);
    } else {
      setIsGoogleCopied(true);
      setTimeout(() => {
        setIsGoogleCopied(false);
      }, 1500);
    }
  };

  return (
    <Dialog
      open={!!data}
      onClose={claimLoading || redeemLoading ? undefined : hide}
      keepMounted
      fullScreen={fullScreen}
      TransitionComponent={ModalTransition}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: 1000,
        },
      }}
    >
      <DialogTitle component="div">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {rewardDetailsLoading ? (
            <Skeleton
              height={24}
              width={80}
              variant="rounded"
              sx={{ borderRadius: 16 }}
            />
          ) : (
            <Chip
              variant="filled"
              color={
                !rewardDetails ||
                rewardDetails?.status === "ended" ||
                rewardDetails?.claimPercent >= 100
                  ? "default"
                  : "success"
              }
              label={
                !rewardDetails ||
                rewardDetails?.status === "ended" ||
                rewardDetails?.claimPercent >= 100
                  ? rewardDetails && rewardDetails?.claimPercent >= 100
                    ? "Sold out"
                    : "Ended"
                  : "Live"
              }
              size="small"
              sx={{ minWidth: 80, fontWeight: 700, color: "#FFF" }}
            />
          )}
          <IconButton
            aria-label="close"
            disabled={claimLoading || redeemLoading}
            onClick={hide}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={{ xs: 1.5, sm: 3 }}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
                aspectRatio: "1/1",
              }}
            >
              {rewardDetailsLoading ? (
                <Skeleton
                  sx={{
                    aspectRatio: "1/1",
                    borderRadius: "10px",
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                <img
                  src={
                    rewardDetails?.profile?.redeemedBannerImage &&
                    (data?.redeemable?.status === "redeemed" || redeemResponse)
                      ? rewardDetails?.profile?.redeemedBannerImage
                      : rewardDetails?.profile?.bannerImage
                  }
                  alt="Reward"
                />
              )}
              {(data?.redeemable?.status === "redeemed" || redeemResponse) &&
                !rewardDetails?.profile?.redeemedBannerImage &&
                !rewardDetailsLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      backdropFilter: "blur(1px)",
                    }}
                  >
                    <Typography variant="h6" fontWeight={900}>
                      REDEEMED
                    </Typography>
                  </Box>
                )}
            </Box>
            {(data?.redeemable?.status === "redeemed" || redeemResponse) && (
              <Box textAlign="center" mt={1.5}>
                <Chip
                  label="Redeemed"
                  variant="outlined"
                  color="primary"
                  sx={{ textTransform: "uppercase" }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            {rewardDetailsLoading ? (
              <>
                <Typography variant="subtitle2" mb={1}>
                  <Skeleton width={180} />
                </Typography>
                <Typography variant="h6" mb={1}>
                  <Skeleton width={240} />
                </Typography>
                <Typography variant="body2" mb={2}>
                  <Skeleton sx={{ mb: 0.5 }} />
                  <Skeleton sx={{ mb: 0.5 }} />
                  <Skeleton />
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="subtitle2">
                  {rewardDetails?.profile?.title}
                </Typography>
                <Typography variant="h6" fontWeight={700}>
                  {rewardDetails?.tokenMetadata?.name}
                </Typography>
                <Typography variant="body2" mb={2}>
                  {rewardDetails?.profile?.description}
                </Typography>
              </>
            )}
            {rewardDetailsLoading ? (
              <Stack display="inline-flex" direction="row" spacing={1} mb={2}>
                <EventBusyRoundedIcon />
                <Typography variant="subtitle2">
                  <Skeleton width={100} />
                </Typography>
              </Stack>
            ) : (
              <Tooltip
                title={
                  data?.redeemable || redeemResponse
                    ? "Redeem end date"
                    : "Campaign end date"
                }
                placement="top"
              >
                <Stack
                  display="inline-flex"
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  mb={2}
                >
                  <EventBusyRoundedIcon />
                  <Typography variant="subtitle2">
                    {typeof endDate === "undefined"
                      ? "Does not expire"
                      : endDate?.format("DD MMMM YYYY")}
                  </Typography>
                </Stack>
              </Tooltip>
            )}
            <Stack direction="row" spacing={1}>
              <PublicRoundedIcon />
              {rewardDetailsLoading ? (
                <Skeleton width={120} sx={{ mt: "2px !important" }} />
              ) : (
                <Typography variant="subtitle2" sx={{ mt: "2px !important" }}>
                  {rewardDetails?.eligibleCountries &&
                  rewardDetails?.eligibleCountries?.length > 0
                    ? rewardDetails?.eligibleCountries
                        ?.map(({ name }) => name)
                        ?.join(", ")
                    : "Worldwide"}
                </Typography>
              )}
            </Stack>
            <Typography variant="subtitle1" fontWeight={600} mt={2}>
              How to redeem:
            </Typography>
            <RewardDetailsTimeline
              steps={rewardDetails?.redemptionInstructions?.steps}
              loading={rewardDetailsLoading}
            />
            {rewardDetails?.redemptionInstructions?.redemptionUri && (
              <Tooltip title="Here you can use your reward" placement="top">
                <Stack
                  display="inline-flex"
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  mt={2}
                >
                  <InsertLinkRoundedIcon />
                  <Link
                    href={rewardDetails?.redemptionInstructions?.redemptionUri}
                    target="_blank"
                    color="#FFF"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {rewardDetails?.redemptionInstructions?.redemptionUri}
                  </Link>
                </Stack>
              </Tooltip>
            )}

            {redeemResponse && (
              <Box mt={2}>
                {redeemResponse?.fulfillmentType ===
                  RewardFulfillmentTypes.Scratch &&
                  redeemResponse?.rewardPayload?.codes?.[0] && (
                    <Box textAlign="center" mb={1}>
                      <Typography variant="overline" fontWeight={700}>
                        Present to an official who will accept this reward
                      </Typography>
                      <Box
                        width={160}
                        height={160}
                        margin="auto"
                        p={1}
                        borderRadius={1}
                        sx={{
                          backgroundColor: "#FFF",
                          "& svg": { maxWidth: "100%", maxHeight: "100%" },
                        }}
                      >
                        <QRCode
                          value={redeemResponse?.rewardPayload?.codes?.[0]}
                        />
                      </Box>
                    </Box>
                  )}
                {redeemResponse?.fulfillmentType ===
                  RewardFulfillmentTypes.Upload && (
                  <Grid container columnSpacing={1}>
                    {redeemResponse?.rewardPayload?.headers?.map(
                      (codeName, index) => (
                        <Grid
                          item
                          xs={12}
                          md={
                            Object.keys(redeemResponse?.rewardPayload?.headers)
                              ?.length %
                              2 ===
                              1 &&
                            index + 1 ===
                              Object.keys(
                                redeemResponse?.rewardPayload?.headers
                              )?.length
                              ? 12
                              : 6
                          }
                          key={codeName}
                        >
                          <RewardDetailsCode
                            codeName={codeName}
                            code={redeemResponse?.rewardPayload?.codes?.[index]}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                )}
                <CustomView
                  condition={
                    !!redeemResponse?.rewardPayload?.googlePass ||
                    !!redeemResponse?.rewardPayload?.applePass
                  }
                >
                  <Box mt={1} mb={1} gap={12}>
                    <Box textAlign="center">
                      <Divider>
                        <Typography variant="overline" fontWeight={700}>
                          Add to your wallet
                        </Typography>
                      </Divider>
                    </Box>
                    {/* <Box mb={1} textAlign="center">
                      {redeemResponse?.rewardPayload?.applePass && (
                        <Link
                          padding={1}
                          href={redeemResponse?.rewardPayload?.applePass}
                          target="_system"
                          download
                        >
                          <AddToWallet width={160} />
                        </Link>
                      )}
                      {redeemResponse?.rewardPayload?.googlePass && (
                        <Link
                          padding={1}
                          href={redeemResponse?.rewardPayload?.googlePass}
                          target="_system"
                          download
                        >
                          <AddToGoogleWallet width={160} />
                        </Link>
                      )}
                    </Box> */}
                    <Box textAlign="center">
                      <Typography
                        variant="caption"
                        lineHeight={1.25}
                        component="div"
                        color="text.secondary"
                        mb={0.75}
                      >
                        Simply copy the pass link and paste it in your usual
                        browser.
                      </Typography>
                      {redeemResponse?.rewardPayload?.applePass && (
                        <Box mt={1} mb={1} gap={12}>
                          <Button
                            size="small"
                            variant="base"
                            color="secondary"
                            onClick={() => {
                              handleCopyClick(
                                redeemResponse?.rewardPayload?.applePass || "",
                                "apple"
                              );
                            }}
                            disabled={isAppleCopied}
                          >
                            {isAppleCopied
                              ? "Copied!"
                              : "Copy link to Apple Wallet pass"}
                          </Button>
                        </Box>
                      )}
                      {redeemResponse?.rewardPayload?.googlePass && (
                        <Box mt={1} mb={1} gap={12}>
                          <Button
                            size="small"
                            variant="base"
                            color="secondary"
                            onClick={() => {
                              handleCopyClick(
                                redeemResponse?.rewardPayload?.googlePass || "",
                                "google"
                              );
                            }}
                            disabled={isGoogleCopied}
                          >
                            {isGoogleCopied
                              ? "Copied!"
                              : "Copy link to Google Wallet pass"}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </CustomView>
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box width="100%">
          {rewardDetails?.eligibleAudience?.ruleTreeDescription &&
            !data?.redeemable && (
              <Typography variant="caption" component="div">
                * {rewardDetails?.eligibleAudience?.ruleTreeDescription}
              </Typography>
            )}
          {!!data?.redeemable && (
            <>
              {!redeemResponse && (
                <LoadingButton
                  fullWidth
                  variant="contained"
                  color={
                    data?.redeemable?.status === "redeemed"
                      ? "secondary"
                      : "primary"
                  }
                  disabled={
                    rewardDetailsLoading ||
                    (typeof endDate !== "undefined" &&
                      moment().isAfter(endDate)) ||
                    !rewardDetails?.canRedeem
                  }
                  loading={redeemLoading || rewardDetailsLoading}
                  onClick={handleRedeem}
                >
                  {!rewardDetails?.canRedeem
                    ? "Redeeming is currently unavailable"
                    : typeof endDate !== "undefined" &&
                      moment().isAfter(endDate)
                    ? "Can't get code because it has expired"
                    : data?.redeemable?.status === "claimed"
                    ? "Redeem now"
                    : "Get code"}
                </LoadingButton>
              )}
            </>
          )}
          {rewardDetails &&
            rewardDetails?.status !== "ended" &&
            rewardDetails?.claimPercent < 100 &&
            !data?.redeemable &&
            !redeemResponse && (
              <>
                {checkEligibilityResLoading ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    py={2}
                    spacing={1}
                  >
                    <Typography fontWeight={700}>
                      Checking eligibility. Please, wait..
                    </Typography>
                    <Loader size={24} />
                  </Stack>
                ) : checkEligibilityRes?.success ? (
                  checkEligibilityRes?.data?.eligible ? (
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      color={
                        rewardDetails?.autoRedeem ? "secondary" : "primary"
                      }
                      loading={claimLoading}
                      onClick={handleClaim}
                    >
                      {rewardDetails?.autoRedeem ? "Redeem now" : "Claim now"}
                    </LoadingButton>
                  ) : (
                    <Alert
                      severity="error"
                      variant="filled"
                      sx={{ borderRadius: "5px !important" }}
                    >
                      <Typography fontWeight={700}>
                        You do not meet the requirements to be eligible to claim
                      </Typography>
                    </Alert>
                  )
                ) : (
                  <Alert
                    severity="error"
                    variant="filled"
                    sx={{ borderRadius: "5px !important" }}
                  >
                    <AlertTitle>{checkEligibilityRes?.error?.title}</AlertTitle>
                    <Typography fontWeight={700}>
                      {checkEligibilityRes?.error?.description}
                    </Typography>
                  </Alert>
                )}
              </>
            )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RewardDetails;
