import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

type Props = { steps?: string[]; loading: boolean };

const RewardDetailsTimeline = ({ steps, loading }: Props) => {
  if (loading) {
    return (
      <Timeline
        sx={{
          m: 0,
          p: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {[0, 1, 2].map((step, index) => (
          <TimelineItem key={step} sx={{ minHeight: 0 }}>
            <TimelineSeparator>
              <TimelineConnector
                sx={{
                  ...(step === 0 && {
                    backgroundColor: "transparent",
                  }),
                }}
              />
              <TimelineDot sx={{ my: 0.5 }} />
              <TimelineConnector
                sx={{
                  ...(step === 2 && {
                    backgroundColor: "transparent",
                  }),
                }}
              />
            </TimelineSeparator>
            <TimelineContent sx={{ pr: 0, pl: 1.5, py: 0.5 }}>
              <Skeleton height={20} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    );
  }

  return (
    <Timeline
      sx={{
        m: 0,
        p: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {steps?.map((step, index) => (
        <TimelineItem key={index} sx={{ minHeight: 0 }}>
          <TimelineSeparator>
            <TimelineConnector
              sx={{
                ...(index === 0 && {
                  backgroundColor: "transparent",
                }),
              }}
            />
            <TimelineDot sx={{ my: 0.5 }} />
            <TimelineConnector
              sx={{
                ...(index + 1 === steps?.length && {
                  backgroundColor: "transparent",
                }),
              }}
            />
          </TimelineSeparator>
          <TimelineContent sx={{ pr: 0, pl: 1.5, py: 0.5 }}>
            <Typography variant="subtitle2">{step}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default RewardDetailsTimeline;
