import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { copyTextToClipboard } from "../../utils";
import { EllipsisTypography } from "../../components/StyledComponents";

type Props = { codeName: string; code: string };

const RewardDetailsCode = ({ codeName, code }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    await copyTextToClipboard(code);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <FormControl
      variant="filled"
      hiddenLabel
      fullWidth
      margin="dense"
      size="small"
    >
      <FilledInput
        type="text"
        fullWidth
        readOnly
        hiddenLabel
        value={code}
        startAdornment={
          <InputAdornment position="start" sx={{ maxWidth: "40%" }}>
            <EllipsisTypography
              variant="overline"
              fontWeight={600}
              color="text.secondary"
            >
              {codeName}
            </EllipsisTypography>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <Tooltip
              title={isCopied ? "Copied!" : "Copy to clypboard"}
              placement="top"
            >
              <IconButton
                size="small"
                sx={{ color: "text.secondary" }}
                onClick={handleCopyClick}
              >
                <ContentCopyRoundedIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        sx={{
          "&::before, &::after": { content: "none" },
          borderRadius: 10,
        }}
        inputProps={{
          sx: {
            textAlign: "center",
            fontWeight: 700,
            fontSize: 20,
          },
          variant: "filled",
        }}
      />
    </FormControl>
  );
};
export default RewardDetailsCode;
