import { alpha, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import Popover from "@mui/material/Popover";
import Badge from "@mui/material/Badge";
import { Plan } from "../../types";

const StyledToolbar = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
  paddingLeft: 0,
}));

type Props = {
  numSelected: number;
  onAction: () => void;
  planFilterList: { id: Plan["AStakingProgramId"]; title: Plan["PlanTitle"] }[];
  onFilterPlan: (id: string) => void;
  filterSelected: string | undefined;
};

export default function TableToolbar({
  numSelected,
  onAction,
  planFilterList,
  onFilterPlan,
  filterSelected,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <StyledToolbar
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 1,
        height: 52,
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <>
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
          <Button color="error" onClick={onAction}>
            Unsubscribe
          </Button>
        </>
      ) : (
        <>
          <Typography
            sx={{ flex: "1 1 100%" }}
            id="tableTitle"
            component="div"
            variant="h5"
            fontWeight="bold"
          >
            Subscriptions
          </Typography>
          {planFilterList?.length > 1 && (
            <div>
              <Tooltip title="Filter list" placement="top">
                <IconButton onClick={handleClick}>
                  {filterSelected ? (
                    <Badge color="primary" badgeContent=" " variant="dot">
                      <FilterListIcon />
                    </Badge>
                  ) : (
                    <FilterListIcon />
                  )}
                </IconButton>
              </Tooltip>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box p={1.5}>
                  <FormControl sx={{ minWidth: 260 }}>
                    <Typography mb={1}>Plan</Typography>
                    <Select
                      size="small"
                      value={filterSelected}
                      onChange={(e) => {
                        onFilterPlan(e.target.value as string);
                        handleClose();
                      }}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {planFilterList?.map(({ id, title }) => (
                        <MenuItem value={id} key={id}>
                          {title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Popover>
            </div>
          )}
        </>
      )}
    </StyledToolbar>
  );
}
