import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../layouts/base/Header/Header";
import MenuDrawer from "../layouts/base/MenuDrawer/MenuDrawer";
import {
  DrawerHeader,
  StyledMain,
  drawerWidth,
} from "../components/StyledComponents";
import { useState } from "react";
import Footer from "../layouts/base/Footer";

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <Header setMobileOpen={setMobileOpen} />
      <MenuDrawer mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <StyledMain
        component="main"
        sx={{
          width: {
            xs: "100%",
            md: `calc(100% - ${drawerWidth}px)`,
          },
        }}
      >
        <DrawerHeader />
        <Outlet />
        <Footer />
      </StyledMain>
    </Box>
  );
};

export default Layout;
