import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import Stack from "@mui/material/Stack";
import { EllipsisTypography } from "./StyledComponents";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

type Props = {
  communityName: string;
  profileImage: string;
  coverImage: string;
  onClick: () => void;
};

export const CommunityCard = ({
  communityName,
  profileImage,
  coverImage,
  onClick,
}: Props) => {
  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardMedia sx={{ height: 140 }} image={coverImage}>
          {!coverImage && (
            <Stack height="100%" justifyContent="center" alignItems="center">
              <NoPhotographyIcon sx={{ fontSize: "4rem", color: "#757575" }} />
            </Stack>
          )}
        </CardMedia>
        <CardContent sx={{ p: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Avatar src={profileImage} alt={communityName} variant="rounded">
              <InsertPhotoRoundedIcon sx={{ fontSize: "1.75rem" }} />
            </Avatar>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ flex: 1, minWidth: 0 }}
            >
              <EllipsisTypography variant="body1" fontWeight="bold">
                {communityName}
              </EllipsisTypography>
              <VerifiedRoundedIcon color="primary" fontSize="small" />
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const CommunityCardSkeleton = () => {
  return (
    <Card>
      <Skeleton height={140} variant="rectangular" />
      <CardContent sx={{ p: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Skeleton width={40} height={40} sx={{ flexShrink: 0 }} />
          <Skeleton width="100%" height="1rem" />
        </Stack>
      </CardContent>
      <span />
    </Card>
  );
};
