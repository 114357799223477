import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DetailsItem from "./DetailsItem";

const Details = ({
  mintAddress,
  owner,
  tokenStandard,
}: {
  mintAddress?: string;
  owner?: string;
  tokenStandard?: string;
}) => {
  const standard =
    tokenStandard === "ProgrammableNonFungible" ? "Programmable NFT" : "NFT";

  return (
    <>
      <Typography fontWeight={700}>Details</Typography>
      <Divider sx={{ mb: 1, mt: 0.5 }} />
      <Stack spacing={0.5}>
        {mintAddress && (
          <DetailsItem label="Mint address" type="token" value={mintAddress} />
        )}
        {owner && <DetailsItem label="Owner" type="account" value={owner} />}
        {standard && (
          <DetailsItem label="Token standard" type="string" value={standard} />
        )}
      </Stack>
    </>
  );
};

export default Details;
