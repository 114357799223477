import { TokensHeadProps } from "./types";
import TableSortLabel from "@mui/material/TableSortLabel";
import { StyledGridTokensTableHead } from "../../../../components/StyledComponents";

const TokensHead = ({ order, orderBy, onRequestSort }: TokensHeadProps) => {
  return (
    <>
      <StyledGridTokensTableHead sx={{ justifyContent: "flex-start" }}>
        <TableSortLabel
          active={orderBy === "quantity"}
          direction={orderBy === "quantity" ? order : "asc"}
          sx={{ ml: "38px" }}
          onClick={() => {
            onRequestSort("quantity");
          }}
        >
          Quantity
        </TableSortLabel>
      </StyledGridTokensTableHead>
      <StyledGridTokensTableHead>Pool balance</StyledGridTokensTableHead>
      <StyledGridTokensTableHead>
        <TableSortLabel
          active={orderBy === "change"}
          direction={orderBy === "change" ? order : "asc"}
          onClick={() => {
            onRequestSort("change");
          }}
        >
          Change (24h)
        </TableSortLabel>
      </StyledGridTokensTableHead>
      <StyledGridTokensTableHead>
        <TableSortLabel
          active={orderBy === "usdAmount"}
          direction={orderBy === "usdAmount" ? order : "asc"}
          onClick={() => {
            onRequestSort("usdAmount");
          }}
        >
          Value ($USD)
        </TableSortLabel>
      </StyledGridTokensTableHead>
      <StyledGridTokensTableHead />
    </>
  );
};

export default TokensHead;
