/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import NftCard from "../../components/NftCard/NftCard";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SelectNftsDialog } from "../../components/StyledComponents";
import {
  BoosterPlan,
  SubscriptionBooster,
  SubscriptionToken,
} from "../../types";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import ModalTransition from "../../helpers/ModalTransition";
import Empty from "../../components/Empty/Empty";
import Title from "./Title";
import useEditBoosterMutation from "../../hooks/queries/community/subscriptions/mutations/useEditBoosterMutation";
import useEligableToBoostList from "../../hooks/queries/community/subscriptions/state/useEligableToBoostList";

interface Props {
  data?: {
    booster?: SubscriptionBooster;
    plan?: BoosterPlan;
  };
  hide: () => void;
}

export default function ModalEditBooster({ data, hide }: Props) {
  const theme = useTheme();
  const { data: nftList, isLoading: nftListLoading } = useEligableToBoostList(
    data?.plan?.AStakingProgramId
  );
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selected, setSelected] = useState<SubscriptionToken["id"][]>([]);

  const limit =
    data?.plan?.LimitPlan === "limit" ? data?.plan?.MaxUsable : null;

  const { mutateAsync: editBoosterMutation, isLoading } =
    useEditBoosterMutation({
      onSuccess: () => {
        setSelected([]);
        hide();
      },
    });

  useEffect(() => {
    nftList?.forEach((i) => {
      if (i?.MultiplierInfo && !selected.includes(i?.id)) {
        addSelected(i?.id);
      }
    });
  }, [nftList]);

  const toggleItem = (id: SubscriptionToken["id"]) => {
    if (selected.includes(id)) {
      removeSelected(id);
    } else {
      addSelected(id);
    }
  };

  const removeSelected = (id: SubscriptionToken["id"]) => {
    setSelected((oldArray) => oldArray.filter((el) => el !== id));
  };

  const addSelected = (id: SubscriptionToken["id"]) => {
    setSelected((oldArray) => [...oldArray, id]);
  };

  const selectAll = () => {
    if (!nftList) return;
    setSelected((state) => {
      if (limit) {
        return [
          ...state,
          ...nftList
            .filter(({ id }) => !selected.includes(id))
            .slice(0, limit - selected.length)
            .map(({ id }) => id),
        ];
      }
      return nftList.map(({ id }) => id);
    });
  };

  const handleHide = () => {
    hide();
    setTimeout(() => {
      setSelected([]);
    }, theme.transitions.duration.leavingScreen);
  };

  const onApply = () => {
    const addStakedTokenDocIds: SubscriptionToken["id"][] = [];
    const removeStakedTokenDocIds: SubscriptionToken["id"][] = [];
    nftList?.forEach((t) => {
      if (t?.MultiplierInfo) {
        if (!selected.includes(t?.id)) {
          removeStakedTokenDocIds.push(t?.id);
        }
      } else if (selected.includes(t?.id)) {
        addStakedTokenDocIds.push(t?.id);
      }
    });

    if (
      addStakedTokenDocIds.length === 0 &&
      removeStakedTokenDocIds.length === 0
    ) {
      hide();
      return;
    }

    if (!data?.booster?.id) return;

    editBoosterMutation({
      addStakedTokenDocIds,
      removeStakedTokenDocIds,
      multiplierDocId: data?.booster?.id,
    });
  };

  return (
    <SelectNftsDialog
      open={!!data}
      onClose={isLoading ? undefined : handleHide}
      scroll="paper"
      TransitionComponent={ModalTransition}
      keepMounted
      fullWidth
      fullScreen={fullScreen}
    >
      <Title
        limit={data?.plan?.LimitPlan === "limit" ? data?.plan?.MaxUsable : null}
        title={data?.plan?.PlanTitle}
        description={data?.plan?.PlanDescription}
        booster={{
          img: data?.booster?.manifest?.image,
          name: data?.booster?.manifest?.name,
        }}
        loading={isLoading}
        hide={handleHide}
        showBoosterIcon
      />
      {isLoading ? (
        <Stack alignItems="center" spacing={2} marginY={4}>
          <Loader />
          <Typography>May take a while, trust the process</Typography>
        </Stack>
      ) : (
        <DialogContent sx={{ mb: { xs: 2, sm: 0 } }}>
          {nftListLoading ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              py={2}
              height="100%"
            >
              <Loader />
            </Stack>
          ) : nftList?.length === 0 ? (
            <Stack
              height="100%"
              justifyContent="center"
              sx={{
                p: { xs: 2, md: 4 },
              }}
            >
              <Empty
                text="You don't have any compatible subscriptions"
                imgWidth={500}
              />
            </Stack>
          ) : (
            <Grid container spacing={1}>
              {nftList?.map((nft) => (
                <Grid item xs={6} sm={4} md={3} lg={2} key={nft?.id}>
                  <NftCard
                    data={nft?.manifest}
                    selected={selected.includes(nft?.id)}
                    selectedText={nft?.MultiplierInfo ? "Boosted" : "Selected"}
                    onSelect={() => toggleItem(nft?.id)}
                    disabled={!!limit && selected.length >= limit}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
      )}
      {!isLoading && (
        <DialogActions>
          <Button
            onClick={() => {
              onApply();
            }}
            fullWidth
            disabled={nftListLoading}
          >
            Apply booster to selected items
          </Button>
          <Button
            variant="base"
            color="secondary"
            onClick={selectAll}
            fullWidth={fullScreen}
            disabled={
              nftListLoading ||
              (!!limit && selected.length >= limit) ||
              selected.length === nftList?.length
            }
            sx={{ whiteSpace: "nowrap" }}
          >
            Select all
          </Button>
        </DialogActions>
      )}
    </SelectNftsDialog>
  );
}
