import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useWallet } from "@solana/wallet-adapter-react";
import { ViewMode } from "../types";
import Button from "@mui/material/Button";
import { enqueueSnackbar } from "notistack";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Badge from "@mui/material/Badge";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import { useMarketplace } from "../services/Main";
import { useExploreMarketplace } from "../services/Explore";
import MarketplaceActionsListView from "./MarketplaceActionsListView";
import ToggleButton from "@mui/material/ToggleButton";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";

const MarketplaceActions = () => {
  const { publicKey } = useWallet();
  const {
    viewMode,
    setViewMode,
    handleToggleFilter,
    handleToggleActivity,
    viewMaxWidth,
    setViewMaxWidth,
    filterOpen,
    activityOpen,
  } = useMarketplace();
  const { filter } = useExploreMarketplace();

  const handleListAnItemClick = () => {
    if (!publicKey) {
      enqueueSnackbar({
        variant: "info",
        message: "Login to gain access",
      });
      return;
    }
    setViewMode(ViewMode.List);
  };

  const handleExploreClick = () => {
    setViewMode(ViewMode.Explore);
  };

  return (
    <Stack
      direction="row"
      py={0.5}
      px={1}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        position: { xs: "sticky", sm: "unset" },
        top: 52,
        zIndex: 3,
        backgroundColor: "#191a1f",
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box>
        {viewMode === ViewMode.Explore ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <ToggleButton
              value="filterOpen"
              size="small"
              disableRipple
              selected={filterOpen}
              sx={{ border: 0, p: 0.5 }}
              onClick={handleToggleFilter}
            >
              {Object.keys(filter.filterByTokenAttribute)?.length > 0 ? (
                <Badge
                  color="primary"
                  overlap="circular"
                  badgeContent=" "
                  variant="dot"
                >
                  <FilterAltRoundedIcon />
                </Badge>
              ) : (
                <FilterAltRoundedIcon />
              )}
            </ToggleButton>
            {viewMode === ViewMode.Explore && (
              <Button
                variant="gradient"
                size="small"
                sx={{ padding: "4px 12px" }}
                onClick={handleListAnItemClick}
              >
                List an item
              </Button>
            )}
          </Stack>
        ) : (
          <Button
            size="small"
            variant="text"
            color="inherit"
            startIcon={<ArrowBackIosNewRoundedIcon fontSize="small" />}
            onClick={handleExploreClick}
          >
            Explore
          </Button>
        )}
      </Box>
      <Stack direction="row" alignItems="center" spacing={1}>
        <MarketplaceActionsListView
          value={viewMaxWidth}
          setValue={setViewMaxWidth}
        />
        <ToggleButton
          value="activityOpen"
          size="small"
          disableRipple
          selected={activityOpen}
          sx={{ border: 0, p: 0.5 }}
          onClick={handleToggleActivity}
        >
          <MonitorHeartOutlinedIcon />
        </ToggleButton>
      </Stack>
    </Stack>
  );
};

export default MarketplaceActions;
