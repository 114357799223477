import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import BoosterIcon from "../../components/images/Booster";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandableTypography from "../../components/ExpandableTypography";
import { PlanRulesDetails } from "../../types";

type Props = {
  limit: number | null;
  title?: string;
  description?: string;
  showBoosterIcon?: boolean;
  booster?: {
    name?: string;
    img?: string;
  };
  rules?: PlanRulesDetails;
  loading: boolean;
  hide: () => void;
};

const Title = ({
  limit,
  title,
  description,
  showBoosterIcon,
  booster,
  loading,
  rules,
  hide,
}: Props) => {
  return (
    <DialogTitle
      component="div"
      sx={{
        "& + .MuiDialogContent-root": {
          paddingTop: (theme) => `${theme.spacing(1)}!important`,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="flex-start"
          spacing={2}
        >
          {!!booster && (
            <Box
              sx={{
                width: 150,
                height: 150,
                borderRadius: "10px",
                overflow: "hidden",
                flexShrink: 0,
                "& img": {
                  maxWidth: "100%",
                },
              }}
            >
              <img src={booster?.img} alt={booster?.name} />
            </Box>
          )}
          <Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              {showBoosterIcon && <BoosterIcon width={20} />}
              <Typography variant="h6" fontWeight="bold">
                {title}
              </Typography>
            </Stack>
            {showBoosterIcon && (
              <ExpandableTypography
                text={description?.trim() || ""}
                variant="body2"
                mt={1}
                sx={{ whiteSpace: "pre-line" }}
              />
            )}
            {limit && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {!!rules && (
                  <>
                    {rules?.maxSubscriptionsActive && (
                      <>
                        Max subscriptions:{" "}
                        <strong>{rules?.maxSubscriptions || "0"}</strong>
                        <br />
                      </>
                    )}
                    {rules?.maxSubscriptionsPerWalletActive && (
                      <>
                        Max subscriptions <strong>per wallet</strong>:{" "}
                        <strong>{rules?.maxSubscriptionsPerWallet}</strong>
                        <br />
                      </>
                    )}
                    {rules?.canSubscribeRuleIdActive && (
                      <>
                        Rule:{" "}
                        <strong>{rules?.canSubscribeRuleDescription}</strong>
                        <br />
                      </>
                    )}
                  </>
                )}
                You can select up to {limit} items at once
              </Typography>
            )}
          </Box>
        </Stack>
        <IconButton aria-label="close" onClick={hide} disabled={loading}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>
  );
};

export default Title;
