import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import { useHttpsCallable } from "../../../services/FirebaseService";
import useDebounce from "../../../hooks/useDebounce";
import { SearchCommunitiesResponse } from "../../../types";
import { EllipsisTypography } from "../../../components/StyledComponents";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const Search = styled(Box)(({ theme }) => ({
  width: 420,
  maxWidth: "100%",
  [theme.breakpoints.down("md")]: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    right: 0,
    backgroundColor: "#272729",
    paddingInline: theme.spacing(1.5),
    gap: theme.spacing(2),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Input = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  display: "flex",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const HideSearchBarButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "4px",
  top: "50%",
  marginTop: "-16px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const Loading = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  right: 10,
  top: "50%",
  marginTop: -12,
  [theme.breakpoints.down("md")]: {
    right: 40,
  },
}));

const ResultsList = styled(List)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  maxHeight: 350,
  overflowY: "auto",
  boxShadow: theme.shadows[5],
}));

const SearchInner = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: `${theme.shape.borderRadius}px`,
  backgroundColor: "rgb(71, 71, 73)",
  "&:hover": {
    backgroundColor: "rgb(93, 93, 95)",
  },
  [theme.breakpoints.down("md")]: {
    flexGrow: 1,
  },
}));

const MIN_SEARCH_LENGTH = 2;

type Props = { visible: boolean; hide: () => void };

const SearchBar = ({ visible, hide }: Props) => {
  const path = "communities/search";
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const [showResults, setShowResults] = useState<boolean>(false);
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const fbFn = useHttpsCallable(path);
  const { data, isFetching } = useQuery(
    ["list", debouncedSearchTerm],
    () => searchCommunities(debouncedSearchTerm),
    {
      onSuccess: () => {
        setShowResults(debouncedSearchTerm.length >= MIN_SEARCH_LENGTH);
      },
    }
  );

  const searchCommunities = async (searchParam: string) => {
    if (searchParam?.length < MIN_SEARCH_LENGTH) return [];
    const { data } = await fbFn({ searchParam });
    const res = data as SearchCommunitiesResponse;
    if (!res?.success) {
      throw new Error(`Cannot get list. Please try again`);
    }
    return res.communities;
  };

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const reset = useCallback(() => {
    setShowResults(false);
    setSearchValue("");
    hide();
  }, [hide]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        reset();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, reset]);

  return (
    <Search
      ref={ref}
      sx={{
        display: {
          md: "block",
          xs: visible ? "flex" : "none",
        },
      }}
    >
      <SearchInner>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Input
          placeholder="Search communities"
          inputProps={{ "aria-label": "search" }}
          value={searchValue}
          onChange={handleSearchChange}
          fullWidth
        />
        {isFetching && <Loading size={24} />}
        <HideSearchBarButton size="small" onClick={hide}>
          <CloseRoundedIcon />
        </HideSearchBarButton>
        {showResults && (
          <>
            {data && (
              <ResultsList>
                {data.length > 0 ? (
                  data.map((c) => (
                    <ListItemButton
                      key={c?.route + c?.name}
                      onClick={() => {
                        navigate(`/c/${c?.route}`);
                        reset();
                      }}
                    >
                      <Avatar
                        src={c?.imageUrl}
                        alt={c?.name}
                        sx={{ width: 30, height: 30, mr: 1.5 }}
                      >
                        <InsertPhotoRoundedIcon fontSize="small" />
                      </Avatar>
                      <EllipsisTypography>{c?.name}</EllipsisTypography>
                    </ListItemButton>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText
                      primary="Communities not found.."
                      primaryTypographyProps={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      color="text.secondary"
                    />
                  </ListItem>
                )}
              </ResultsList>
            )}
          </>
        )}
      </SearchInner>
    </Search>
  );
};

export default SearchBar;
