import { createContext, useContext } from "react";
import { Firestore, getFirestore } from "firebase/firestore";
import { Auth, getAuth } from "firebase/auth";
import { FirebaseStorage, getStorage } from "firebase/storage";
import { Functions, getFunctions, httpsCallable } from "firebase/functions";
import { DEFAULT_API_ORIGIN, FIREBASE_APP } from "../constants";

export const db = getFirestore(FIREBASE_APP);
export const auth = getAuth(FIREBASE_APP);
export const storage = getStorage(FIREBASE_APP);
export const functions = getFunctions(
  FIREBASE_APP,
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? DEFAULT_API_ORIGIN
    : window.location.origin || DEFAULT_API_ORIGIN
);

type FirebaseFeatures = {
  db: Firestore;
  auth: Auth;
  functions: Functions;
  storage: FirebaseStorage;
};

const FirebaseContext = createContext<FirebaseFeatures>({
  db,
  auth,
  functions,
  storage,
});

export function useFirebase() {
  return useContext(FirebaseContext);
}

export function useHttpsCallable(fnName: string) {
  return httpsCallable(functions, `api/v1/${fnName}`);
}

export const FirebaseService = ({ children }: any) => {
  const value: FirebaseFeatures = {
    db,
    auth,
    functions,
    storage,
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
