import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import { useSnackbar } from "notistack";
import {
  DefaultResponse,
  MarketplaceNftData,
  MutationDefaultResponse,
} from "../../../../types";

const process = (data: {
  marketplaceId?: string;
  rawTransaction: number[];
  requestId: string;
  listingId: string;
}) => {
  return fetch(
    "https://us-central1-nft-anybodies.cloudfunctions.net/marketplace/v1/api/interface/cancel-listing/process",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data,
      }),
    }
  );
};

export default function useDelist(marketplaceId?: string) {
  const queryClient = useQueryClient();
  const { publicKey, signTransaction } = useWallet();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async (data: MarketplaceNftData) => {
      const { listingInfo, ...nftData } = data;
      const response = await fetch(
        "https://us-central1-nft-anybodies.cloudfunctions.net/marketplace/v1/api/interface/cancel-listing/request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              marketplaceId,
              listingId: listingInfo?.listingId,
            },
          }),
        }
      );
      const {
        data: reqTxRes,
      }: {
        data: DefaultResponse<
          boolean,
          {
            success: true;
            data: {
              signedMessage?: string;
              listingId: string;
              rawTransaction: any;
              requestId: string;
            };
          }
        >;
      } = await response.json();
      if (!reqTxRes?.success) {
        throw new Error(
          `${reqTxRes?.error?.title}\n${reqTxRes?.error?.description}`
        );
      }

      const transaction = Transaction.from(
        reqTxRes?.data?.rawTransaction?.data
      );
      if (!signTransaction) {
        throw new Error("Can't create transaction");
      }
      const signedTransaction = await signTransaction(transaction);
      const finalRes = await process({
        marketplaceId,
        rawTransaction: Object.values(signedTransaction.serialize()),
        requestId: reqTxRes?.data?.requestId,
        listingId: reqTxRes?.data?.listingId,
      });
      const { data: finalResData }: { data: MutationDefaultResponse } =
        await finalRes?.json();
      if (!finalResData?.success) {
        throw new Error(
          `${finalResData?.error?.title}\n${finalResData?.error?.description}`
        );
      }
      queryClient.setQueryData(
        ["marketplace-wallet-profile", publicKey?.toString()],
        (currentState: MarketplaceNftData[] | undefined) => {
          if (typeof currentState === "undefined") {
            return currentState;
          }
          return [nftData, ...currentState];
        }
      );
      return finalRes;
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Delisting completed successfully!",
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
}
