import { useQuery } from "@tanstack/react-query";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { CampaignByIdResponse } from "../../../../../types";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";

const useGetRewardDetails = (campaignId: string | undefined) => {
  const path = "rewards/by-id";
  const { data: communityInfo } = useGetCommunityInfo();
  const fn = useHttpsCallable(path);

  const getRewardDetails = async () => {
    const { data } = await fn({
      accountId: communityInfo?.accountId,
      communityId: communityInfo?.communityId,
      campaignId,
    });
    const res = data as CampaignByIdResponse;
    if (!res?.success) {
      throw new Error("Error");
    }
    return res?.data;
  };

  return useQuery(
    [path, communityInfo?.communityId, campaignId],
    getRewardDetails,
    {
      staleTime: 2 * 60 * 1000, // 2 minutes
      enabled:
        !!campaignId &&
        !!communityInfo?.accountId &&
        !!communityInfo?.communityId,
    }
  );
};

export default useGetRewardDetails;
