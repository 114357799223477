import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

type Props = { handleTryAgain: () => void };

const FetchError = ({ handleTryAgain }: Props) => {
  return (
    <Box textAlign="center">
      <Typography variant="body1">
        Failed to get the requested data. Try again
      </Typography>
      <Button variant="gradient" sx={{ mt: 2 }} onClick={handleTryAgain}>
        Try again
      </Button>
    </Box>
  );
};

export default FetchError;
