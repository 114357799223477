import useMediaQuery from "@mui/material/useMediaQuery";
import { SelectNftsDialog } from "../../components/StyledComponents";
import ModalTransition from "../../helpers/ModalTransition";
import { useTheme } from "@mui/material/styles";
import { BoosterPlan, Manifest, Plan } from "../../types";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import NftCard from "../../components/NftCard/NftCard";
import Loader from "../../components/Loader/Loader";
import Empty from "../../components/Empty/Empty";
import Title from "./Title";
import useSubscribeMutation from "../../hooks/queries/community/subscriptions/mutations/useSubscribeMutation";
import Box from "@mui/material/Box";
import { getLevelBySession } from "../../utils";
import useNftListByWalletAndPlan from "../../hooks/queries/community/subscriptions/state/useNftListByWalletAndPlan";

type Props = {
  data?:
    | {
        isBooster: true;
        plan: BoosterPlan;
      }
    | {
        isBooster: false;
        plan: Plan;
      };
  hide: () => void;
};

const LIMIT = 50;

const ModalSelectNftForSubscription = ({ data, hide }: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data: nftList, isLoading: nftListLoading } =
    useNftListByWalletAndPlan(data?.plan?.AStakingProgramId, data?.isBooster);

  const { mutateAsync: subscribeMutation, isLoading } = useSubscribeMutation({
    contractId: data?.plan?.AStakingProgramId,
    isBooster: data?.isBooster,
    onSuccess: () => {
      setSelected([]);
      hide();
    },
  });

  const [selected, setSelected] = useState<Manifest["mintAddress"][]>([]);

  const toggleItem = (address: Manifest["mintAddress"]) => {
    if (selected.includes(address)) {
      removeSelected(address);
    } else {
      addSelected(address);
    }
  };

  const removeSelected = (address: Manifest["mintAddress"]) => {
    setSelected((oldArray) => oldArray.filter((el) => el !== address));
  };

  const addSelected = (address: Manifest["mintAddress"]) => {
    setSelected((oldArray) => [...oldArray, address]);
  };

  const selectAll = () => {
    if (!nftList) return;
    setSelected((state) => [
      ...state,
      ...nftList
        .filter(({ mintAddress }) => !selected.includes(mintAddress))
        .slice(0, LIMIT - selected.length)
        .map(({ mintAddress }) => mintAddress),
    ]);
  };

  const handleHide = () => {
    hide();
    setTimeout(() => {
      setSelected([]);
    }, theme.transitions.duration.leavingScreen);
  };

  const handleApply = () => {
    if (selected.length === 0) return;
    subscribeMutation(selected);
  };

  return (
    <SelectNftsDialog
      open={!!data}
      onClose={isLoading ? undefined : handleHide}
      scroll="paper"
      TransitionComponent={ModalTransition}
      keepMounted
      fullWidth
      fullScreen={fullScreen}
    >
      <Title
        limit={LIMIT}
        title={data?.plan?.PlanTitle}
        description={data?.plan?.PlanDescription}
        showBoosterIcon={data?.isBooster}
        rules={(data?.plan as Plan)?.rulesDetails}
        loading={isLoading}
        hide={handleHide}
      />
      {isLoading ? (
        <Stack alignItems="center" spacing={2} marginY={4}>
          <Loader />
          <Typography>May take a while, trust the process</Typography>
        </Stack>
      ) : (
        <DialogContent
          sx={{
            mb: { xs: 2, sm: 0 },
            minHeight: { xs: "50dvh", sm: 200 },
          }}
        >
          {nftListLoading ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              py={2}
              height="100%"
              minHeight={{ xs: "calc(50dvh - 20px)", sm: 200 - 20 }}
            >
              <Loader />
            </Stack>
          ) : nftList?.length === 0 ? (
            <Stack
              height="100%"
              justifyContent="center"
              sx={{
                p: { xs: 2, md: 4 },
              }}
            >
              <Empty
                text="You don't have any compatible collectables"
                imgWidth={500}
              />
            </Stack>
          ) : (
            <Grid container spacing={0.75}>
              {nftList
                ?.sort((a, b) =>
                  a?.mintAddress &&
                  b?.mintAddress &&
                  a?.mintAddress > b?.mintAddress
                    ? 1
                    : -1
                )
                ?.map((nft) => (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={nft?.mintAddress}>
                    <Box>
                      <NftCard
                        data={nft}
                        selected={selected.includes(nft?.mintAddress)}
                        onSelect={() => toggleItem(nft?.mintAddress)}
                        disabled={selected.length >= LIMIT}
                      />
                      {!data?.isBooster &&
                        data?.plan?.maturityActive &&
                        nft?.lastKnownSession && (
                          <Typography
                            variant="gradient"
                            textAlign="center"
                            component="div"
                          >
                            Level{" "}
                            {getLevelBySession(
                              data?.plan?.maturityLevels,
                              nft?.lastKnownSession
                            )}
                          </Typography>
                        )}
                    </Box>
                  </Grid>
                ))}
            </Grid>
          )}
        </DialogContent>
      )}
      {!isLoading && (
        <DialogActions>
          <Button
            onClick={handleApply}
            fullWidth
            disabled={selected.length === 0}
          >
            Subscribe
          </Button>
          <Button
            variant="base"
            color="secondary"
            onClick={selectAll}
            fullWidth={fullScreen}
            disabled={
              nftListLoading ||
              selected.length >= LIMIT ||
              selected.length === nftList?.length
            }
            sx={{ whiteSpace: "nowrap" }}
          >
            Select all
          </Button>
        </DialogActions>
      )}
    </SelectNftsDialog>
  );
};

export default ModalSelectNftForSubscription;
