import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import EmptyIcon from "../images/Empty";
import { PropsWithChildren } from "react";

interface EmptyProps {
  text: string;
  action?: {
    text: string;
    fn: () => void;
  };
  imgWidth?: number;
}

const Empty = ({
  text,
  action,
  imgWidth,
  children,
}: PropsWithChildren<EmptyProps>) => {
  return (
    <Box textAlign="center" m="auto">
      <Box mb={2}>
        <EmptyIcon
          width={100}
          height="100%"
          style={{
            maxWidth: "50vw",
          }}
        />
      </Box>
      <Typography fontSize={{ xs: "1.1rem", md: "1.375rem" }} fontWeight="bold">
        {text}
      </Typography>
      {action && (
        <Button
          variant="gradient"
          onClick={action.fn}
          sx={{ mt: { xs: 2, md: 4 } }}
        >
          {action.text}
        </Button>
      )}
      {children}
    </Box>
  );
};

export default Empty;
