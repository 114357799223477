import { Timestamp } from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";

export type RewardNftType = "base" | "booster";
export type RewardIsStaked = boolean;

export type DefaultErrorResult = {
  success: false;
  error: {
    title: string;
    description: string;
  };
};
export interface NFT {
  id: string;
  img: string;
}

export interface CommunityInfo {
  id: string;
  img: string;
  name: string;
  verified: boolean;
  info: {
    tvl: number;
    floorPrice: number;
    staked: number;
  };
}

export type Order = "asc" | "desc";

export type DefaultResponse<
  IsSuccess = boolean,
  SuccessType = any
> = IsSuccess extends true ? SuccessType : DefaultErrorResult;

export type CommunityInfoByRouteResponse = DefaultResponse<
  boolean,
  CommunityInfoByRouteResult
>;

export type CommunityInfoByRouteResult = {
  success: true;
  accountId: string;
  hasDiscord: boolean;
  hasLoyalty: boolean;
  communityId: string;
  vaultLegacy: VaultLegacy;
  profile: CommunityProfile;
};

export type CommunityPlansByRouteResponse = DefaultResponse<
  boolean,
  CommunityPlansByRouteResult
>;

export type CommunityPlansByRouteResult = {
  success: boolean;
  accountId: string;
  communityId: string;
  plans: { [key: string]: Plan };
  boosters: { [key: string]: BoosterPlan };
};

export type CommunityTokensByRouteResponse = DefaultResponse<
  boolean,
  CommunityTokensByRouteResult
>;

export type CommunityTokensByRouteResult = {
  success: true;
  tokens: {
    [key: string]: Token;
  };
};

export type CommunityStatsByRouteResponse = DefaultResponse<
  boolean,
  CommunityStatsByRouteResult
>;

export type CommunityStatsByRouteResult = {
  success: true;
  communityStats: CommunityStats;
};

export type CommunityByRouteResponse = DefaultResponse<
  boolean,
  CommunityByRouteResult
>;
export interface CommunityByRouteResult {
  success: true;
  accountId: string;
  communityId: string;
  communityStats: CommunityStats;
  tokens: {
    [key: string]: Token;
  };
  vaultLegacy: VaultLegacy;
  profile: CommunityProfile;
  plans: { [key: string]: Plan };
  boosters: { [key: string]: BoosterPlan };
}

export type BoosterPlan = {
  AStakingProgramId: string;
  StakingDuration: number;
  VaultRef: any;
  SupportsAutoStaking: boolean;
  StakingProgram: string;
  ProjectId: string;
  Projectref: any;
  VaultId: string;
  Created: FirebaseDate;
  Setup: boolean;
  MaxUsable: number;
  FilterOption: string;
  Hidden: boolean;
  Active: boolean;
  PlanTitle: string;
  LimitPlan: string;
  MultiplierAction: string;
  MultiplierValue: number;
  PlanDescription: string;
  LastUpdate: FirebaseDate;
};

export interface Plan {
  AStakingProgramId: string;
  StakingDuration: number;
  SupportsAutoStaking: boolean;
  ProjectId: string;
  VaultId: string;
  Created: FirebaseDate;
  VaultRef: any;
  RewardProgram: string;
  TokenRef: any;
  StakingProgram: string;
  TokenData: {
    TokenNameUpper: string;
    TokenNameLower: string;
    ProjectId: string;
    TokenName: string;
    isSol: boolean;
    TokenAddress: string;
    Projectref: any;
    DecimalPerToken: number;
    Created: FirebaseDate;
    tokenMetadata: {
      address: string;
      decimals: number;
      id: number;
      name: string;
      symbol: string;
      uri: string;
    };
  };
  Projectref: any;
  TokenId: string;
  Hidden: boolean;
  PlanTitle: string;
  HolderVerificationRoleId: string;
  Setup: boolean;
  Active: boolean;
  maturityActive: boolean;
  PlanDescription: string;
  maturityLevels: MaturityLevel[];
  benefitsPreview: {
    discounts: boolean;
    events: boolean;
    redeemables: boolean;
    [key: string]: boolean;
  };
  uniqueSubscribedWallets?: number;
  totalSubscriptions?: number;
  rulesDetails?: PlanRulesDetails;
  tokenCampaign?: boolean;
}

export type PlanRulesDetails = {
  maxSubscriptions?: number;
  maxSubscriptionsActive?: boolean;
  maxSubscriptionsPerWalletActive?: boolean;
  maxSubscriptionsPerWallet?: number;
  canSubscribeRuleIdActive?: boolean;
  canSubscribeRuleId?: string;
  canSubscribeRuleDescription?: string;
};

export type MaturityLevel = {
  addedRewards: number;
  stakingSession: number;
};

export interface CommunityProfile {
  vaultId: string;
  create: FirebaseDate;
  projectId: string;
  bookBurnerSize: number;
  bookBurner: string;
  projectName: string;
  projectDescription?: string;
  linkTwitter: string;
  linkDiscord: string;
  showDiscord: boolean;
  showSecondaryMarket: boolean;
  linkSecondaryMarket: string;
  linkWebsite: string;
  profileImage: Image;
  verifiedDate: FirebaseDate;
  verified: boolean;
  showWebsite: boolean;
  coverImage: Image;
  showTwitter: boolean;
}

export type Image = {
  path: string;
  url: string;
};

export type FirebaseDate = {
  _seconds: number;
  _nanoseconds: number;
};

export interface VaultLegacy {
  ImageURL: string;
  IsLive: boolean;
  SecondaryLink: string;
  VaultName: string;
  VaultSize: number;
  ShowMultiplier: boolean;
}

export interface Token {
  symbol: string;
  address: string;
  decimals: number;
  name: string;
  uri: string;
  id: string;
  exchangeData: ExchangeData | null;
}

export interface ExchangeData {
  updateHumanTime: Date;
  updateUnixTime: number;
  priceChange24h: number;
  value: number;
}

export interface ProjectInfoData {
  profile?: CommunityProfile;
  communityStats?: CommunityStats;
}

export interface CommunityStats {
  SOL2USD: number;
  solFloorPrice: number;
  subsciptionsValueUSD: number;
  subscriptionCount: number;
}

export type SubscriptionByWalletResponse = DefaultResponse<
  boolean,
  SubscriptionByWalletResult
>;

export interface SubscriptionByWalletResult {
  success: true;
  tokenMap: {
    [tokenAddress: string]: SubscriptionsBalance;
  };
  rewardMap: { [key: string]: SubscriptionReward };
  stakedTokens: SubscriptionToken[];
  boosters: SubscriptionBooster[];
}

export interface SubscriptionsBalance {
  amount: number;
  totalAccumulatedReward: number;
  totalMinuteReward: number;
  totalBonusMinuteReward: number;
  poolBalance: number;
}

export interface SubscriptionReward {
  finalReward: number;
  bonusRewards: number;
  minuteReward: number;
  bonusMinuteReward: number;
}

export interface SubscriptionBooster {
  id: string;
  StakingDuration: number;
  SupportsAutoStaking: boolean;
  MultiplierValue: number;
  VaultTransactionSigniture: string;
  LastUpdate: Date;
  manifest: Manifest;
  created: Date;
  PlanId: string;
  ProjectId: string;
  BusyUnstaking: boolean;
  VaultId: string;
  LimitPlan: string;
  mintAddress: string;
  returnedToOwner: boolean;
  MultiplierAction: string;
  StakingProgram: string;
  PreUnstakingCheck: boolean;
  StakerPublicKey: string;
  MaxUsable: number;
  expirationDate: Date;
  ActiveNumber: number;
  originalCreated: Date;
}

export interface Manifest {
  image: string;
  symbol: string;
  seller_fee_basis_points: number;
  animation_url: string;
  external_url: string;
  name: string;
  description: string;
  mintAddress?: string;
  attributes: ManifestAttribute[];
  collection: ManifestCollection;
  properties: ManifestProperties;
}

export interface ManifestAttribute {
  value: string;
  trait_type: string;
}

export type ManifestCollection = {
  name: string;
  family: string;
};

export type ManifestProperties = {
  creators: {
    address: string;
    share: number;
  }[];
  files: {
    type: "image/jpeg" | "image/jpg" | "video/mp4";
    uri: string;
  }[];
  category: "image" | "video";
};

export interface SubscriptionToken {
  id: string;
  StakingDuration: number;
  SupportsAutoStaking: boolean;
  VaultTransactionSigniture: string;
  redeemed: number;
  VaultId: string;
  mintAddress: string;
  returnedToOwner: boolean;
  RewardProgram: string;
  StakerPublicKey: string;
  TokenId: string;
  NotEnoughFunds: boolean;
  manifest: Manifest;
  PlanId: string;
  ProjectId: string;
  baseDailyReward: number;
  WithdrawalBusy: boolean;
  StakingProgram: string;
  TokenData: {
    RewardTokenAddress: string;
    TokenName: string;
  };
  RedeemedBonusRewards: number;
  OCP: boolean;
  isPNFT: boolean;
  OriginalVaultTransactionSigniture: string;
  originalCreated: Date;
  lastUpdate: Date;
  created: Date;
  BusyUnstaking: boolean;
  PreUnstakingCheck: boolean;
  dailyReward: number;
  expirationDate: Date;
  stakingSession: number;
  AccumulatedBonusRewards: number;
  LastUpdate: Date;
  MultiplierInfo: {
    ExpirationDate: FirebaseDate;
    MultiplierAction: string;
    MultiplierDaliyReward: number;
    Created: FirebaseDate;
  };
}

export type CommunitiesByWalletResponse = DefaultResponse<
  boolean,
  CommunitiesByWalletResult
>;

export interface CommunitiesByWalletResult {
  success: true;
  communities: CommunityByWalletItem[];
  eligibleCommunities: CommunityByWalletItem[];
}

export interface CommunityByWalletItem {
  name: string;
  imageUrl: string;
  route: string;
}

export type GetContractForWalletResponse = DefaultResponse<
  boolean,
  GetContractForWalletResult
>;

export type GetContractForWalletResult = {
  success: true;
  availableTokens: string[] | ContractForWalletItem[];
};

export type ContractForWalletItem = {
  lastKnownSession: number;
  tokenAddress: string;
};

export type GetTokensMetadataResponse = DefaultResponse<
  boolean,
  {
    success: true;
    metadataTokens: Manifest[];
  }
>;

export type GetBoostableTokensResponse = DefaultResponse<
  boolean,
  {
    success: true;
    boostableTokens: string[];
  }
>;

export type SubscribeRequestResponse = DefaultResponse<
  boolean,
  {
    success: true;
    transactionRequests: { rawTransaction: any; requestId: string }[];
  }
>;

export type TxRequestResponse = DefaultResponse<
  boolean,
  {
    success: true;
    rawTransaction: any;
    requestId: string;
  }
>;

export type MutationDefaultResponse = DefaultResponse<
  boolean,
  {
    success: true;
  }
>;

export type PlanBoosterComponentType = {
  isBooster: true;
  plan: BoosterPlan;
};

export type PlanComponentType = {
  isBooster: false;
  plan: Plan;
};

export type SearchCommunitiesResponse = DefaultResponse<
  boolean,
  {
    success: true;
    communities: {
      imageUrl: string;
      name: string;
      route: string;
      subscribed: boolean;
    }[];
  }
>;

export type TopCommunitiesResponse = DefaultResponse<
  boolean,
  {
    success: true;
    communities: {
      floorPrice: number;
      accountId: string;
      route: string;
      profile: {
        showTwitter: boolean;
        showWebsite: boolean;
        linkTwitter: string;
        linkWebsite: string;
        showSecondaryMarket: boolean;
        coverImage: string;
        verified: boolean;
        linkSecondaryMarket: string;
        linkDiscord: string;
        profileImage: string;
        projectName: string;
        showDiscord: boolean;
      };
      vaultSize: number;
      TVL: number;
      communityId: string;
      totalStaked: number;
    }[];
  }
>;
export type ExploreCommunitiesResponse = DefaultResponse<
  boolean,
  {
    success: true;
    communities: {
      accountId: string;
      communityId: string;
      route: string;
      communityName: string;
      displayName: string;
      profileImage: string;
      coverImage: string;
      TVL: number;
      floorPrice: number;
      totalStaked: number;
      vaultSize: number;
    }[];
  }
>;

export type LoyaltyQuestsDetailResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: LoyaltyQuest[];
  }
>;

export type LoyaltyQuest = {
  _id: string;
  created: Date;
  title: string;
  description: string;
  banner?: FirebaseImage;
  missions: LoyaltyQuestMission[];
};

export type LoyaltyQuestMission = {
  title: string;
  description: string;
  ruleTreeId: string;
};

export type FirebaseImage = {
  assets: {
    path: string;
    url: string;
    _id: string;
  }[];
  created: Date;
  manifest: string;
  _id: string;
};

export type RewardListResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: RewardListItem[];
  }
>;

export type RewardListItem = {
  claimPercent: number;
  distributionType: string;
  tokenMetadata: {
    name: string;
  };
  profile: {
    bannerImage: string;
    description: string;
    title: string;
  };
  claimExpirationDate: Date;
  redeemExpirationDate?: Date;
  rewardCategory: string;
  rewardType: string;
  status: "live" | "ended";
  autoRedeem: boolean;
  _id: string;
};

export type Country = {
  code: string;
  name: string;
};

export enum RewardSources {
  System = "system",
  Shopify = "shopify",
}

export enum RewardTypesSystem {
  Discount = "discount",
  GiftCard = "gift-card",
}

export enum RewardTypesShopify {
  Product = "shopify-product",
  GiftCard = "shopify-gift-card",
}

export type RewardTypes = RewardTypesSystem | RewardTypesShopify;

export enum EligableTypes {
  Unlimited = "unlimited",
  Hashlist = "hashlist",
  WalletAddresses = "walletAddresses",
}

export enum DistributionTypes {
  Giveaway = "giveaway",
  Raffle = "raffle",
}

export type ClaimingTransaction = {
  type: "sol" | "splToken";
  tokenAddress?: string;
  amount: number | string;
};

export type CampaignByIdResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: CampaignDetails;
  }
>;

export type CampaignDetails = {
  claimExpirationDate: Date;
  eligibleCountries: Country[];
  profile: {
    redeemedBannerImage?: string;
    bannerImage: string;
    description: string;
    title: string;
  };
  rewardCategory: string;
  rewardType: RewardTypes;
  status: "draft" | "live" | "ended";
  eligibleAudience?: {
    eligibleHashlists: any;
    maxClaimsPerWalletAddress: number;
    type: EligableTypes;
    ruleTreeId?: string;
    ruleTreeDescription?: string;
  };
  distributionType?: DistributionTypes;
  redeemExpirationDate?: Date;
  redemptionInstructions?: {
    redemptionInstructions: string[];
    steps: string[];
    redemptionUri?: string;
  };
  claimingPaymentConfig?: {
    destinationWalletAddress: string;
    transactions: ClaimingTransaction[];
  };
  tokenMetadata: {
    name: string;
  };
  claimPercent: number;
  autoRedeem: boolean;
  canRedeem?: boolean;
};

export type CheckEligibilityResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: { eligible: boolean };
  }
>;

export type ClaimedRewardsResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: ClaimedReward[];
  }
>;

export type ClaimedReward = {
  accountId: string;
  campaignId: string;
  claimedDate: Timestamp;
  communityId: string;
  created: Timestamp;
  distributionType: DistributionTypes;
  mintAddress: string;
  redeemExpirationDate: Timestamp;
  tokenMetadata: Manifest;
  rewardType: RewardTypes;
  status: "claimed" | "redeemed";
  campaignTitle: string;
};

export type RedeemResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: RedeemResponseSuccessData;
  }
>;

export type RedeemResponseSuccessData = {
  fulfillmentType: RewardFulfillmentTypes;
  mintAddress: string;
  rewardPayload: {
    applePass?: string;
    googlePass?: string;
    codes: string[];
    headers: string[];
  };
};

export enum RewardFulfillmentTypes {
  Upload = "upload",
  Scratch = "scratch",
}

export type ClaimableDetailsResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: ClaimableDetails;
  }
>;

export type ClaimableDetails = {
  accountId: string;
  communityId: string;
  claimPageId: string;
  claimableId: string;
  claimable: {
    claimExpirationDate?: Date;
    publishedDate: Date;
    status: "live" | "ended";
    distributionType: string;
    profile: {
      title: string;
      description: string;
      bannerImage: string;
    };
    claimedCount: number;
    totalAvailableReward: number;
    tokenMetadata: {
      _id: string;
      accountId: string;
      communityId: string;
      assetTitle: string;
      blockchain: string;
      digitalAssetConfig: {
        created: Date;
        manifest: string;
        assets: { url: string; path: string; _id: string }[];
        _id: string;
      };
      attributes: { trait_type: string; value: string }[];
      creators: { address: string; share: number }[];
      name: string;
      description: string;
      symbol: string;
      assetCollection: { name: string; family: string };
      sellerFeeBasisPoints: number;
      createdDate: Date;
      lastUpdated: Date;
    };
  };
  communityProfile: {
    VaultRoute: string;
    projectName: string;
    linkTwitter: string;
    linkDiscord: string;
    linkWebsite: string;
    linkSecondaryMarket: string;
    showDiscord: boolean;
    showSecondaryMarket: boolean;
    showTwitter: boolean;
    showWebsite: boolean;
    profileImage: Image;
    verified: boolean;
  };
  eligibleAudience: any;
  costToClaim?: {
    amount: number;
    tokenImage: string;
    tokenName: string;
  }[];
};

export type ScratchDetailsResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: ScratchDetails;
  }
>;

export type ScratchDetails = {
  accountId: string;
  communityId: string;
  claimPageId: string;
  claimableId: string;
  scratch: {
    claimExpirationDate?: Date;
    publishedDate: Date;
    status: "live" | "ended";
    distributionType: string;
    profile: {
      title: string;
      description: string;
      bannerImage: string;
    };
    claimedCount: number;
    totalAvailableReward: number;
    tokenMetadata: {
      _id: string;
      accountId: string;
      communityId: string;
      assetTitle: string;
      blockchain: string;
      digitalAssetConfig: {
        created: Date;
        manifest: string;
        assets: { url: string; path: string; _id: string }[];
        _id: string;
      };
      attributes: { trait_type: string; value: string }[];
      creators: { address: string; share: number }[];
      name: string;
      description: string;
      symbol: string;
      assetCollection: { name: string; family: string };
      sellerFeeBasisPoints: number;
      createdDate: Date;
      lastUpdated: Date;
    };
  };
  communityProfile: {
    VaultRoute: string;
    projectName: string;
    linkTwitter: string;
    linkDiscord: string;
    linkWebsite: string;
    linkSecondaryMarket: string;
    showDiscord: boolean;
    showSecondaryMarket: boolean;
    showTwitter: boolean;
    showWebsite: boolean;
    profileImage: Image;
    verified: boolean;
  };
  eligibleAudience: any;
};

export type MarketplaceWalletProfileResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: MarketplaceWalletProfileData;
  }
>;

export type MarketplaceWalletProfileData = {
  eligableForListing: MarketplaceNftData[];
};

export type MarketplaceNftData = {
  mintAddress: string;
  attributes: ManifestAttribute[];
  description: string;
  name: string;
  symbol: string;
  token_standard: "ProgrammableNonFungible" | "V1_NFT" | "FungibleAsset";
  links: {
    image: string;
    external_url: string;
    animation_url: string;
  };
  royalty: {
    royalty_model: "creators";
    target: any;
    percent: number;
    basis_points: number;
    primary_sale_happened: boolean;
    locked: boolean;
  };
  ownership: {
    frozen: boolean;
    delegated: boolean;
    delegate: any;
    ownership_model: "single";
    owner: string;
  };
  creators: [
    {
      address: string;
      share: number;
      verified: boolean;
    }
  ];
  listingInfo?: {
    listingId: string;
    sellerAddress: string;
    created: Date;
    price: number;
    status: string;
    previousListing?: string; // previous listing id
    previousPrice?: number;
    cancelDate?: Date;
    buyerAddress?: string;
    saleDate?: Date;
    txid: string;
  };
  tokenRankDetails?: {
    absoluteRarity: number;
    filteredRarity: number;
    rank: number;
    rankExplain: {
      attribute: string;
      timesSeen: number;
      totalSeen: number;
      value: string;
      valuePerc: number;
    }[];
  };
};

export type MarketplaceExploreResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: MarketplaceNftData[];
  }
>;

export type MarketplaceActivityResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: MarketplaceActivityItem[];
  }
>;

export type MarketplaceActivityItem = {
  type: "delist" | "list" | "buy" | "reprice";
  created: Date;
  data: MarketplaceNftData;
};

export type TokenDelegationPhase = {
  dailyReward: number;
  duration: number;
  rule: string;
  ruleDescription: string;
  maxSubscriptions: number;
  maxSubscriptionsPerWallet: number;
  entryRule?: string;
  entryRuleDescription?: string;
};

export type MarketplaceDetailsResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: {
      marketplaceFee: number;
      profile: {
        title: string;
        description: string;
        image: {
          id: string;
          url: string;
        };
      };
      currentListedCount: number;
      attributeMap: {
        [trait_type: string]: {
          value: string;
          floorPrice: number;
        }[];
      };
      floorInfo: {
        floorPrice: number;
        floorPriceChangePercent: number;
      };
      volumeInfo: {
        allTimeVolume: number;
        volumeChangePercent: number;
        candleVolume: number;
      };
      sales: {
        allTimeSalesCount: number;
        salesCount: number;
        priceChangePercent: number;
      };
      links: CommunityProfile;
    };
  }
>;

export type SolanaExchangeRateResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: {
      lastUpdate: Date;
      usdValue: number;
    };
  }
>;

export type TokenDelegationCommunityCampaignInfoResponse = DefaultResponse<
  boolean,
  {
    success: true;
    data: {
      campaignId: string;
      title: string;
      description: string;
      currentPhase: number;
      currentPhaseEndDate: Date;
      budget: number;
      status: "live" | "ended";
      tokenMetadata: {
        symbol: string;
        address: string;
        decimals: number;
        name: string;
        id: string;
        uri: string;
      };
      tokenId: string;
      phases: TokenDelegationPhase[];
    };
  }
>;

export type LevelsModalData = {
  baseDailyReward?: number;
  step: number;
  levels: MaturityLevel[];
  currentLevel?: number;
};

export type CommunityServiceType = {
  editBoosterModalData?:
    | {
        booster?: SubscriptionBooster;
        plan?: BoosterPlan;
      }
    | undefined;
  setEditBoosterModalData?: Dispatch<
    SetStateAction<
      | {
          booster?: SubscriptionBooster;
          plan?: BoosterPlan;
        }
      | undefined
    >
  >;
  nftModalData?: PlanBoosterComponentType | PlanComponentType | undefined;
  setNftModalData?: Dispatch<
    SetStateAction<PlanBoosterComponentType | PlanComponentType | undefined>
  >;
  contractLevelsModalData?: LevelsModalData;
  setContractLevelsModalData?: Dispatch<
    SetStateAction<LevelsModalData | undefined>
  >;
  loadingModal?: null | { title: string; description: string };
  setLoadingModal?: Dispatch<
    SetStateAction<null | { title: string; description: string } | undefined>
  >;
  selectedFilterPlan?: string;
  setSelectedFilterPlan?: Dispatch<SetStateAction<string>>;
  selectedBoostersFilterPlan?: string;
  setSelectedBoostersFilterPlan?: Dispatch<SetStateAction<string>>;
  rewardDetailsData?:
    | {
        campaignId: string;
        redeemable?: {
          mintAddress: string;
          status: ClaimedReward["status"];
          redeemedImage?: string;
        };
      }
    | undefined;
  setRewardDetailsData?: Dispatch<
    SetStateAction<
      | {
          campaignId: string;
          redeemable?: {
            mintAddress: string;
            status: ClaimedReward["status"];
            redeemedImage?: string;
          };
        }
      | undefined
    >
  >;
  campaignContractModalData?: Plan;
  setCampaignContractModalData?: Dispatch<SetStateAction<Plan | undefined>>;
};
