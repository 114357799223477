import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FilterType,
  OwnerFilter,
  OrderBy,
  ViewMode,
  MarketplaceServiceExploreProps,
} from "../types";
import {
  MarketplaceActivityItem,
  MarketplaceExploreResponse,
  MarketplaceNftData,
} from "../../../types";
import { useWallet } from "@solana/wallet-adapter-react";
import useWebSocket from "react-use-websocket";
import { WS_URLS } from "../../../constants";
import { useSnackbar } from "notistack";
import { useMarketplace } from "./Main";
import { useQueryClient } from "@tanstack/react-query";

const INIT_LIMIT = 30;
const LIMIT = 15;

const explore = async (filter: FilterType, signal?: AbortSignal) => {
  const response = await fetch(
    "https://us-central1-nft-anybodies.cloudfunctions.net/marketplace/v1/api/analytics/listing",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal,
      body: JSON.stringify({
        data: filter,
      }),
    }
  );
  const { data }: { data: MarketplaceExploreResponse } = await response.json();
  if (!data?.success) {
    throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
  }
  return data?.data;
};

export const SORT_BY: { label: string; value: OrderBy }[] = [
  {
    label: "Price: Low to high",
    value: {
      field: "price",
      order: "asc",
    },
  },
  {
    label: "Price: High to low",
    value: {
      field: "price",
      order: "desc",
    },
  },
  {
    label: "Listed: New to old",
    value: {
      field: "created",
      order: "desc",
    },
  },
  {
    label: "Listed: Old to new",
    value: {
      field: "created",
      order: "asc",
    },
  },
];

export const OWNER_TYPES = [
  { label: "All", value: OwnerFilter.All },
  { label: "My listed", value: OwnerFilter.Self },
];

export const VIEW_MODES = [
  { label: "Explore", value: ViewMode.Explore },
  { label: "List", value: ViewMode.List },
];

const ServiceContext = createContext<MarketplaceServiceExploreProps>(
  {} as MarketplaceServiceExploreProps
);

export function useExploreMarketplace() {
  return useContext(ServiceContext);
}

const filterAndSortList = (list: MarketplaceNftData[], order: OrderBy) => {
  list = list.filter(
    (v: MarketplaceNftData, i: number, a: MarketplaceNftData[]) =>
      a.findIndex((v2) => v2.mintAddress === v.mintAddress) === i
  );
  if (order.field === "price") {
    // price low to high
    if (order.order === "asc") {
      list = list.sort((a: MarketplaceNftData, b: MarketplaceNftData) => {
        if (a.listingInfo!.price < b.listingInfo!.price) return -1;
        if (a.listingInfo!.price > b.listingInfo!.price) return 1;

        const dateA = new Date(a.listingInfo!.created);
        const dateB = new Date(b.listingInfo!.created);

        return dateB.getTime() - dateA.getTime();
      });
      // price high to low
    } else if (order.order === "desc") {
      list = list.sort((a: MarketplaceNftData, b: MarketplaceNftData) => {
        if (a.listingInfo!.price > b.listingInfo!.price) return -1;
        if (a.listingInfo!.price < b.listingInfo!.price) return 1;

        const dateA = new Date(a.listingInfo!.created);
        const dateB = new Date(b.listingInfo!.created);

        return dateB.getTime() - dateA.getTime();
      });
    }
  }
  if (order.field === "created") {
    // old to new
    if (order.order === "asc") {
      list = list.sort((a: MarketplaceNftData, b: MarketplaceNftData) => {
        const dateA = new Date(a.listingInfo!.created);
        const dateB = new Date(b.listingInfo!.created);
        return dateA.getTime() - dateB.getTime();
      });
      // new to old
    } else if (order.order === "desc") {
      list = list.sort((a: MarketplaceNftData, b: MarketplaceNftData) => {
        const dateA = new Date(a.listingInfo!.created);
        const dateB = new Date(b.listingInfo!.created);
        return dateB.getTime() - dateA.getTime();
      });
    }
  }
  return list;
};

export const MarketplaceExploreService = ({ children }: PropsWithChildren) => {
  const queryClient = useQueryClient();
  const didUnmount = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    nftInfoModalData,
    hideNftInfoModal,
    showNftInfoModal,
    marketplaceId,
    nftModalSubmitLoading,
  } = useMarketplace();
  const { publicKey } = useWallet();
  const [filter, setFilter] = useState<
    MarketplaceServiceExploreProps["filter"]
  >({
    marketplaceIds: [],
    skip: 0,
    limit: 0,
    status: ["listed"],
    orderBy: {
      field: "price",
      order: "asc",
    },
    filterByTokenAttribute: {},
    sellerAddresses: [],
    buyerAddresses: [],
    mintAddresses: [],
  });
  const [exploreLoading, setExploreLoading] = useState(true);
  const [exploreList, setExploreList] = useState<MarketplaceNftData[]>([]);
  const [hasExploreNextPage, setHasExploreNextPage] = useState<boolean>(true);
  const [exploreNextPageLoadingLimit, setExploreNextPageLoadingLimit] =
    useState(0);

  useWebSocket(`${WS_URLS.marketplace + marketplaceId}/logs`, {
    onMessage: (event: WebSocketEventMap["message"]) => {
      const messageData = JSON.parse(event.data) as MarketplaceActivityItem;
      if (!messageData) {
        return;
      }
      queryClient.invalidateQueries({
        queryKey: ["marketplace", marketplaceId],
        exact: true,
        refetchType: "active",
      });
      onWsEvent(messageData);
      switch (messageData?.type) {
        case "buy":
        case "delist": {
          if (
            typeof nftInfoModalData !== "undefined" &&
            nftInfoModalData?.listingInfo?.listingId ===
              messageData?.data?.listingInfo?.listingId &&
            !nftModalSubmitLoading
          ) {
            hideNftInfoModal();
            enqueueSnackbar({
              variant: "info",
              message: `Sorry, this nft has been ${
                messageData?.type === "buy" ? "sold" : "delisted"
              }`,
            });
          }
          break;
        }
        case "list": {
          if (
            nftInfoModalData?.mintAddress === messageData?.data?.mintAddress &&
            publicKey?.toString() !==
              messageData?.data?.listingInfo?.sellerAddress
          ) {
            showNftInfoModal(messageData?.data);
          }
          break;
        }
        case "reprice": {
          if (
            typeof nftInfoModalData !== "undefined" &&
            nftInfoModalData?.listingInfo?.listingId ===
              messageData?.data?.listingInfo?.previousListing
          ) {
            showNftInfoModal(messageData?.data);
          }
          break;
        }
      }
    },
    onOpen: () => {
      console.info("WebSocket connection established.");
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => didUnmount.current === false,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  const exploreNextPage = useCallback(
    async (limit: number = LIMIT) => {
      if (
        !marketplaceId ||
        exploreLoading ||
        !hasExploreNextPage ||
        !!exploreNextPageLoadingLimit
      )
        return;
      setExploreNextPageLoadingLimit(limit);
      const data = await explore({
        ...filter,
        marketplaceIds: [marketplaceId],
        skip: exploreList.length,
        limit,
      });
      setHasExploreNextPage(data?.length === limit);
      setExploreList((currentState) => {
        const updatedList = [...currentState, ...data];
        return filterAndSortList(updatedList, filter.orderBy);
      });
      setExploreNextPageLoadingLimit(0);
    },
    [
      exploreList.length,
      exploreLoading,
      exploreNextPageLoadingLimit,
      filter,
      hasExploreNextPage,
      marketplaceId,
    ]
  );

  const changeFilter = useCallback(
    (key: keyof FilterType, value: FilterType[keyof FilterType]) => {
      setFilter((currentState) => ({ ...currentState, [key]: value }));
      setExploreLoading(true);
      setExploreList([]);
    },
    []
  );

  const addNewListing = useCallback(
    (data: MarketplaceNftData) => {
      setExploreList((currentState) => {
        if (Object.keys(filter.filterByTokenAttribute).length > 0) {
          const matchesFilterAttribute = Object.keys(
            filter.filterByTokenAttribute
          ).every((key) => {
            const value = filter.filterByTokenAttribute[key];
            return (data as MarketplaceNftData)?.attributes?.find(
              (i) => i.trait_type === key && value.includes(i.value)
            );
          });
          if (!matchesFilterAttribute) return currentState;
        }
        const newList = JSON.parse(JSON.stringify(currentState));
        if (currentState?.length === 0) {
          newList.push(data);
          return newList;
        }
        const firstItem = currentState?.[0];
        const lastItem = currentState?.[currentState.length - 1];
        if (!firstItem?.listingInfo || !lastItem?.listingInfo)
          return currentState;
        if (filter.orderBy.field === "price") {
          const price = (data as MarketplaceNftData)?.listingInfo
            ?.price as number;
          // price low to high
          if (filter.orderBy.order === "asc") {
            if (price <= lastItem?.listingInfo?.price || !hasExploreNextPage) {
              newList.push(data);
            }
            // price high to low
          } else if (
            price >= lastItem?.listingInfo?.price ||
            !hasExploreNextPage
          ) {
            newList.push(data);
          }
        }
        if (filter.orderBy.field === "created") {
          // old to new
          if (filter.orderBy.order === "asc") {
            if (!hasExploreNextPage) {
              newList.push(data);
            }
            // new to old
          } else if (filter.orderBy.order === "desc") {
            newList.unshift(data);
          }
        }
        return filterAndSortList(newList, filter.orderBy);
      });
    },
    [filter.filterByTokenAttribute, filter.orderBy, hasExploreNextPage]
  );

  const removeListing = (data: MarketplaceNftData) => {
    setExploreList((currentState) =>
      currentState.filter((nft) => nft.mintAddress !== data.mintAddress)
    );
  };

  const onWsEvent = useCallback(
    (data: MarketplaceActivityItem) => {
      switch (data?.type) {
        case "reprice":
          removeListing(data?.data);
          addNewListing(data?.data);
          break;
        case "buy":
        case "delist": {
          removeListing(data?.data);
          break;
        }
        case "list": {
          addNewListing(data?.data);
          break;
        }
      }
    },
    [addNewListing]
  );

  const clearFilters = () => {
    changeFilter("filterByTokenAttribute", {});
  };

  useEffect(() => {
    if (!marketplaceId || !exploreLoading) return;
    const abortController = new AbortController();
    const signal = abortController.signal;
    (async () => {
      const data = await explore(
        {
          ...filter,
          marketplaceIds: [marketplaceId],
          limit: INIT_LIMIT,
        },
        signal
      );
      setHasExploreNextPage(data?.length === INIT_LIMIT);
      setExploreList(data);
      setExploreLoading(false);
    })();

    return () => {
      abortController.abort();
    };
  }, [exploreLoading, filter, marketplaceId]);

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (!publicKey && filter.sellerAddresses.length > 0) {
      changeFilter("sellerAddresses", []);
    }
  }, [changeFilter, filter.sellerAddresses.length, publicKey]);

  useEffect(() => {
    if (publicKey && filter?.sellerAddresses?.length > 0) {
      changeFilter("sellerAddresses", [publicKey?.toString()]);
    }
  }, [changeFilter, filter?.sellerAddresses?.length, publicKey]);

  useEffect(() => {
    if (!marketplaceId) return;
    changeFilter("marketplaceIds", [marketplaceId]);
  }, [changeFilter, marketplaceId]);

  return (
    <ServiceContext.Provider
      value={{
        filter,
        changeFilter,
        exploreList,
        exploreLoading,
        exploreNextPage,
        hasExploreNextPage,
        exploreNextPageLoadingLimit,
        clearFilters,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
