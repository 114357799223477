import Box from "@mui/material/Box";
import { LevelsModalData, Order, Plan } from "../../../../types";
import { getDecimalsForInterval, getLevelBySession } from "../../../../utils";
import { useCallback, useEffect, useState } from "react";
import SubscriptionsHead from "./RegularHead";
import { RowData } from "./types";
import SubscriptionRow from "./RegularRow";
import Empty from "../../../../components/Empty/Empty";
import { useWallet } from "@solana/wallet-adapter-react";
import Stack from "@mui/material/Stack";
import useUnsubscribeMutation from "../../../../hooks/queries/community/subscriptions/mutations/useUnsubscribeMutation";
import TableToolbar from "../../TableToolbar";
import useGetCommunityTokens from "../../../../hooks/queries/community/info/useGetCommunityTokens";
import RegularSkeleton from "./RegularSkeleton";
import { StyledGridTable } from "../../../../components/StyledComponents";
import Typography from "@mui/material/Typography";
import { useCommunity } from "../../../../services/CommunityService";
import CustomWalletButton from "../../../../components/CustomWalletButton";
import useGetSubscriptions from "../../../../hooks/queries/community/subscriptions/state/useGetSubscriptions";
import useGetCommunityPlans from "../../../../hooks/queries/community/info/useGetCommunityPlans";

export default function Regular() {
  const { publicKey } = useWallet();
  const {
    data: subscriptionsInfo,
    isLoading: subscriptionsInfoLoading,
    dataUpdatedAt,
  } = useGetSubscriptions();
  const { data: communityPlans } = useGetCommunityPlans();
  const { data: communityTokens } = useGetCommunityTokens();
  const {
    selectedFilterPlan,
    setSelectedFilterPlan,
    setContractLevelsModalData,
  } = useCommunity();

  const { mutateAsync: unsubscribeMutation } = useUnsubscribeMutation({
    onSuccess: () => {
      setSelected([]);
    },
  });

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof RowData>("earnings");
  const [selected, setSelected] = useState<string[]>([]);
  const [finalRows, setFinalRows] = useState<RowData[] | undefined>();
  const [planFilterList, setPlanFilterList] = useState<
    { id: Plan["AStakingProgramId"]; title: Plan["PlanTitle"] }[]
  >([]);

  const setupFinalRows = useCallback(() => {
    if (!subscriptionsInfo) return;
    const rows: RowData[] = [];
    subscriptionsInfo?.stakedTokens?.forEach((token) => {
      if (selectedFilterPlan && selectedFilterPlan !== token?.PlanId) return;
      const plan = communityPlans?.plans?.[token?.PlanId];
      const rewardInfo = subscriptionsInfo?.rewardMap?.[token?.id];
      const secondEarnings =
        (rewardInfo?.minuteReward + rewardInfo?.bonusMinuteReward) / 60;
      const timePassed = (new Date().getTime() - dataUpdatedAt) / 1000;
      const earnings =
        rewardInfo?.finalReward +
        rewardInfo?.bonusRewards +
        secondEarnings * timePassed;
      const decimals = getDecimalsForInterval(secondEarnings);
      rows.push({
        id: token?.id,
        planId: token?.PlanId,
        level:
          plan?.maturityActive && plan?.maturityLevels && token?.stakingSession
            ? getLevelBySession(plan?.maturityLevels, token?.stakingSession)
            : 0,
        baseDailyReward: token?.baseDailyReward || 0,
        dailyReward: token?.dailyReward || 0,
        earnings,
        secondEarnings,
        decimals,
        otherData: { ...token },
      });
    });

    if (order === "asc") {
      setFinalRows(rows.sort((a, b) => (a[orderBy] > b[orderBy] ? 1 : -1)));
    } else {
      setFinalRows(rows.sort((a, b) => (a[orderBy] > b[orderBy] ? -1 : 1)));
    }
  }, [
    communityPlans?.plans,
    dataUpdatedAt,
    order,
    orderBy,
    selectedFilterPlan,
    subscriptionsInfo,
  ]);

  useEffect(() => {
    setupFinalRows();
  }, [setupFinalRows]);

  useEffect(() => {
    if (!subscriptionsInfo?.rewardMap || !finalRows?.length) return;
    const interval = setInterval(() => {
      setFinalRows((rows) =>
        rows?.map((r) => {
          const rewardInfo = subscriptionsInfo?.rewardMap?.[r?.id];
          if (!rewardInfo?.minuteReward || !r?.otherData?.SupportsAutoStaking)
            return r;
          return {
            ...r,
            earnings:
              r?.earnings +
              (rewardInfo?.minuteReward + rewardInfo?.bonusMinuteReward) / 60,
          };
        })
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [subscriptionsInfo?.rewardMap, finalRows]);

  useEffect(() => {
    const incomeFilterPlansIds: string[] = [];
    subscriptionsInfo?.stakedTokens?.forEach((token: any) => {
      if (!incomeFilterPlansIds.includes(token?.PlanId)) {
        incomeFilterPlansIds.push(token?.PlanId);
      }
    });
    setPlanFilterList(
      incomeFilterPlansIds?.length === 0
        ? []
        : incomeFilterPlansIds.map((id) => {
            const plan = communityPlans?.plans?.[id];
            return {
              id: plan?.AStakingProgramId || "",
              title: plan?.PlanTitle || "",
            };
          })
    );
    if (setSelectedFilterPlan) {
      setSelectedFilterPlan("");
    }
  }, [
    communityPlans?.plans,
    setSelectedFilterPlan,
    subscriptionsInfo?.stakedTokens,
  ]);

  useEffect(() => {
    return () => {
      setOrder("asc");
      setOrderBy("earnings");
      setFinalRows(undefined);
      setSelected([]);
    };
  }, [subscriptionsInfo]);

  const handleRequestSort = (property: keyof RowData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allSelected: string[] = [];
      finalRows?.forEach((n) => {
        if (!n?.otherData?.SupportsAutoStaking) return;
        allSelected.push(n.id);
      });
      setSelected(allSelected);
      return;
    }
    setSelected([]);
  };

  const onItemSelect = (id: RowData["id"]) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleShowLevelsModal = (data: LevelsModalData) => {
    if (!setContractLevelsModalData) return;
    setContractLevelsModalData(data);
  };

  return (
    <Box id="subscriptions">
      <TableToolbar
        numSelected={selected.length}
        planFilterList={planFilterList}
        onAction={() => {
          unsubscribeMutation(selected);
        }}
        filterSelected={selectedFilterPlan}
        onFilterPlan={(id) => {
          if (!setSelectedFilterPlan) return;
          setSelectedFilterPlan(id);
        }}
      />
      {publicKey ? (
        <>
          {subscriptionsInfoLoading || typeof finalRows === "undefined" ? (
            <RegularSkeleton />
          ) : (
            <>
              {subscriptionsInfo?.stakedTokens?.length === 0 ? (
                <Empty text="You don't have any subscriptions" />
              ) : (
                <Box sx={{ overflowX: "auto" }}>
                  <StyledGridTable
                    sx={{
                      gridTemplateColumns:
                        "48px minmax(auto, 1fr) repeat(4, auto)",
                      minWidth: "650px",
                    }}
                  >
                    <SubscriptionsHead
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      numSelected={selected.length}
                      rowCount={finalRows?.length || 0}
                      order={order}
                      orderBy={orderBy}
                      disabledCheckbox={
                        !finalRows ||
                        finalRows?.every(
                          (i) => !i?.otherData?.SupportsAutoStaking
                        )
                      }
                    />
                    {finalRows?.map((token) => {
                      return (
                        <SubscriptionRow
                          key={token?.id}
                          data={token}
                          plan={
                            communityPlans?.plans?.[token?.otherData?.PlanId]
                          }
                          tokenInfo={
                            communityTokens?.[
                              token?.otherData?.TokenData?.RewardTokenAddress
                            ]
                          }
                          isItemSelected={selected.includes(token?.id)}
                          onItemSelect={onItemSelect}
                          onUnsubscribe={() => unsubscribeMutation([token?.id])}
                          onShowLevelsModal={handleShowLevelsModal}
                        />
                      );
                    })}
                  </StyledGridTable>
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <Box sx={{ my: 3, px: { xs: 1.5, md: 3 } }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            textAlign="center"
            color="text.secondary"
          >
            Login to gain access
          </Typography>
          <Stack pt={2} alignItems="center">
            <CustomWalletButton variant="gradient" />
          </Stack>
        </Box>
      )}
    </Box>
  );
}
