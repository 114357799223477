import Stack from "@mui/material/Stack";
import { MarketplaceActivityItem as Activity } from "../../../types";
import { alpha } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { EllipsisTypography } from "../../../components/StyledComponents";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { TYPE_COLORS } from "./MarketplaceActivity";
import SolanaLogo from "../../../components/images/SolanaLogo";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import Skeleton from "@mui/material/Skeleton";

type Props = {
  data: Activity & { timePassed?: string };
  price?: number;
  isUserActivity: boolean;
};

const MarketplaceActivityItem = ({ data, price, isUserActivity }: Props) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      spacing={0.5}
      mb={0.5}
      pr={1}
      ml={1}
      sx={{
        borderTopLeftRadius: (theme) => theme.shape.borderRadius,
        borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
        ...(isUserActivity && {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
        }),
      }}
      key={`${data?.type}-${data?.data?.listingInfo?.listingId}`}
    >
      <Avatar
        src={data?.data?.links?.image}
        variant="rounded"
        sx={{ width: 60, height: 60 }}
      />
      <Box flexGrow={1} overflow="hidden">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <EllipsisTypography variant="body2">
            {data?.data?.name}
          </EllipsisTypography>
          <Link
            href={`https://solscan.io/tx/${data?.data?.listingInfo?.txid}`}
            target="_blank"
            underline="hover"
            variant="caption"
            color="text.secondary"
            sx={{ whiteSpace: "nowrap" }}
          >
            {data?.timePassed}
          </Link>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} mt={0.5}>
          <Typography
            variant="overline"
            fontWeight={700}
            lineHeight={1}
            component="div"
            color={TYPE_COLORS?.[data?.type]}
          >
            {data?.type}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <SolanaLogo fontSize="10px" />
            {typeof price === "undefined" ? (
              <Skeleton width={32} height={11} />
            ) : (
              <Typography variant="overline" lineHeight={1} fontWeight={500}>
                {price?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 4,
                })}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Typography variant="caption" lineHeight={1}>
          {data?.type === "buy" ? (
            <>
              <Link
                href={`https://solscan.io/account/${data?.data?.listingInfo?.sellerAddress}`}
                target="_blank"
                underline="hover"
              >
                {data?.data?.listingInfo?.sellerAddress?.slice(0, 4)}
              </Link>
              <ArrowForwardRoundedIcon
                color="disabled"
                fontSize="inherit"
                sx={{ verticalAlign: "middle", mx: 0.25 }}
              />
              <Link
                href={`https://solscan.io/account/${data?.data?.listingInfo?.buyerAddress}`}
                target="_blank"
                underline="hover"
              >
                {data?.data?.listingInfo?.buyerAddress?.slice(0, 4)}
              </Link>
            </>
          ) : (
            <>
              By{" "}
              <Link
                href={`https://solscan.io/account/${data?.data?.listingInfo?.sellerAddress}`}
                target="_blank"
                underline="hover"
              >
                {data?.data?.listingInfo?.sellerAddress?.slice(0, 4)}
              </Link>
            </>
          )}
        </Typography>
      </Box>
    </Stack>
  );
};

export default MarketplaceActivityItem;
