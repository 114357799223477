import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import { useSnackbar } from "notistack";
import { useHttpsCallable } from "../../../../services/FirebaseService";
import { MutationDefaultResponse } from "../../../../types";
import { sign } from "tweetnacl";
import { useParams } from "react-router-dom";

export default function useClaimableClaimMutation({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const queryClient = useQueryClient();
  const txFbFn = useHttpsCallable("claimable/request");
  const processFbFn = useHttpsCallable("claimable/process");
  const { publicKey, signTransaction, signMessage } = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const { uniqueLink } = useParams();

  return useMutation(
    async () => {
      const res = await txFbFn({
        uniqueLink,
        walletAddress: publicKey?.toString(),
      });
      const reqTxRes = res?.data as any;
      if (!reqTxRes?.success) {
        throw new Error(
          `${reqTxRes?.error?.title}\n${reqTxRes?.error?.description}`
        );
      }

      let finalRes;

      if (reqTxRes?.data?.hasOwnProperty("signedMessage")) {
        let message = new TextEncoder().encode(reqTxRes?.data?.signedMessage);
        message = new Uint8Array(Array.from(message));
        // `publicKey` will be null if the wallet isn't connected
        if (!publicKey) throw new Error("Wallet not connected!");
        // `signMessage` will be undefined if the wallet doesn't support it
        if (!signMessage)
          throw new Error("Wallet does not support message signing!");

        // Sign the bytes using the wallet
        const signature = await signMessage(message);
        // Verify that the bytes were signed using the private key that matches the known public key
        if (!sign.detached.verify(message, signature, publicKey?.toBytes()))
          throw new Error("Invalid signature!");

        finalRes = await processFbFn({
          rawTransaction: Array.from(signature),
          requestId: reqTxRes?.data?.requestId,
        });
      } else {
        const transaction = Transaction.from(
          reqTxRes?.data?.rawTransaction?.data
        );
        if (!signTransaction) {
          throw new Error("Can't create transaction");
        }
        const signedTransaction = await signTransaction(transaction);
        finalRes = await processFbFn({
          rawTransaction: Object.values(signedTransaction.serialize()),
          requestId: reqTxRes?.data?.requestId,
        });
      }
      const finalResData = finalRes?.data as MutationDefaultResponse;
      if (!finalResData?.success) {
        throw new Error(
          `${finalResData?.error?.title}\n${finalResData?.error?.description}`
        );
      }
      return finalRes;
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Claiming completed successfully. Check your wallet",
        });
        onSuccess();
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["claimable/details", uniqueLink],
          exact: true,
          refetchType: "active",
        });
        queryClient.invalidateQueries({
          queryKey: [
            "claimable/check-eligibility",
            uniqueLink,
            publicKey?.toString(),
          ],
          exact: true,
          refetchType: "active",
        });
        queryClient.invalidateQueries({
          queryKey: ["communities/by-wallet", publicKey?.toString()],
          exact: true,
          refetchType: "active",
        });
      },
    }
  );
}
