import ToggleButton from "@mui/material/ToggleButton";
import { StyledFilterToggleNftView } from "../../../components/StyledComponents";
import Box from "@mui/material/Box";

const MarketplaceActionsListView = ({
  value,
  setValue,
}: {
  value: number;
  setValue: (value: number) => void;
}) => {
  const handleChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    value: number | undefined
  ) => {
    if (!value) return;
    setValue(value);
  };

  return (
    <StyledFilterToggleNftView
      value={value}
      exclusive
      size="small"
      onChange={handleChange}
    >
      {[220, 180, 120].map((value, index) => {
        const repeat = index + 2;
        const elements = repeat ** 2;
        return (
          <ToggleButton value={value} fullWidth disableRipple key={value}>
            <Box
              sx={{
                display: "grid",
                gridTemplate: `repeat(${repeat}, 1fr) / repeat(${repeat}, 1fr)`,
                gridGap: "5%",
                width: 20,
                height: 20,
                "> span": {
                  backgroundColor: "var(--filter-toggle-text-color)",
                  borderRadius: "15%",
                },
              }}
            >
              {Array.from(Array(elements).keys()).map((i) => (
                <span key={i} />
              ))}
            </Box>
          </ToggleButton>
        );
      })}
    </StyledFilterToggleNftView>
  );
};

export default MarketplaceActionsListView;
