import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import { EllipsisTypography } from "../../components/StyledComponents";
import { ClaimableDetails } from "../../types";
import CardActionArea from "@mui/material/CardActionArea";
import { useNavigate } from "react-router-dom";

type Props = {
  loading: boolean;
  info?: ClaimableDetails["communityProfile"];
};

const ClaimableCommunityInfo = ({ loading, info }: Props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/c/${info?.VaultRoute}`);
  };
  return (
    <Stack direction="row">
      <CardActionArea
        sx={{ width: "auto", m: -1, p: 1, borderRadius: 1 }}
        onClick={handleClick}
      >
        <Stack direction="row" spacing={1}>
          {loading ? (
            <Skeleton width={32} height={32} />
          ) : (
            <Avatar
              src={info?.profileImage?.url}
              alt="Logo"
              sx={{ width: 32, height: 32 }}
              variant="rounded"
            >
              <InsertPhotoRoundedIcon fontSize="large" />
            </Avatar>
          )}
          <Stack
            justifyContent="space-evenly"
            flexGrow={1}
            width="calc(100% - 40px)"
          >
            {loading ? (
              <Skeleton
                variant="text"
                height={30}
                width={200}
                animation="wave"
              />
            ) : (
              <Stack direction="row" alignItems="center">
                <EllipsisTypography fontWeight="bold">
                  {info?.projectName}
                </EllipsisTypography>
                {info?.verified && (
                  <VerifiedRoundedIcon
                    color="primary"
                    sx={{ ml: 0.75, fontSize: "1rem" }}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardActionArea>
    </Stack>
  );
};

export default ClaimableCommunityInfo;
