import {
  StyledFilterHeader,
  StyledMarketplaceToolbar,
  StyledMarketplaceToolbarInner,
} from "../../../components/StyledComponents";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useWallet } from "@solana/wallet-adapter-react";
import Box from "@mui/material/Box";
import Loader from "../../../components/Loader/Loader";
import Link from "@mui/material/Link";
import { useMarketplace } from "../services/Main";
import { useMarketplaceActivity } from "../services/Activity";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import { ActivityFilterLogTypes } from "../types";
import MarketplaceActivityFilter from "./MarketplaceActivityFilter";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Badge from "@mui/material/Badge";
import MarketplaceActivityItem from "./MarketplaceActivityItem";
import useGetMarketplaceDetails from "../../../hooks/queries/marketplace/state/useGetMarketplaceDetails";
import { getBuyerPrice } from "../../../utils";

const MarketplaceActivity = () => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const { activityOpen, handleToggleActivity, marketplaceId } =
    useMarketplace();
  const { data: marketplaceDetails } = useGetMarketplaceDetails(marketplaceId);
  const {
    activityLoading,
    setActivityLoading,
    activityList,
    activityNextPage,
    hasNextPage,
    nextPageLoadingLimit,
    setActivityFilter,
    activityFilter,
  } = useMarketplaceActivity();
  const { publicKey } = useWallet();

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (entry.isIntersecting && entry.intersectionRatio > 0) {
        activityNextPage();
      }
    },
    [activityNextPage]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback);

    let observerRefValue: HTMLDivElement | null = null;
    if (bottomRef.current) {
      observer.observe(bottomRef.current);
      observerRefValue = bottomRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [observerCallback]);

  const toggleActivityFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl((currentState) => (currentState ? null : event.currentTarget));
    setOpen((currentState) => !currentState);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleActivityFilterChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setActivityLoading(true);
    setActivityFilter((currentState) => {
      return checked
        ? {
            currentState,
            logTypes: [
              ...currentState.logTypes,
              event.target.name as ActivityFilterLogTypes,
            ],
          }
        : {
            currentState,
            logTypes: currentState.logTypes.filter(
              (type) => type !== (event.target.name as ActivityFilterLogTypes)
            ),
          };
    });
  };

  const handleFilterAll = () => {
    setActivityLoading(true);
    setActivityFilter((currentState) => {
      return {
        ...currentState,
        logTypes: Object.keys(TYPE_COLORS) as ActivityFilterLogTypes[],
      };
    });
  };

  const handleFilterNone = () => {
    setActivityLoading(true);
    setActivityFilter((currentState) => {
      return {
        ...currentState,
        logTypes: [],
      };
    });
  };

  return (
    <StyledMarketplaceToolbar type="activity" open={activityOpen}>
      <StyledFilterHeader>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6" fontWeight={900}>
            Activity
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway}
          >
            <div>
              <IconButton size="small" onClick={toggleActivityFilter}>
                {activityFilter.logTypes.length === 4 ? (
                  <FilterAltRoundedIcon />
                ) : (
                  <Badge
                    color="primary"
                    overlap="circular"
                    badgeContent=" "
                    variant="dot"
                  >
                    <FilterAltRoundedIcon />
                  </Badge>
                )}
              </IconButton>
              {open && (
                <MarketplaceActivityFilter
                  anchorEl={anchorEl}
                  activityFilter={activityFilter}
                  handleActivityFilterChange={handleActivityFilterChange}
                  handleAll={handleFilterAll}
                  handleNone={handleFilterNone}
                />
              )}
            </div>
          </ClickAwayListener>
          <IconButton size="small" onClick={handleToggleActivity}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
      </StyledFilterHeader>
      <StyledMarketplaceToolbarInner mx={-1}>
        {activityLoading ? (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <Loader size={32} />
          </Stack>
        ) : (
          <>
            {activityList?.length === 0 ? (
              <Stack alignItems="center" justifyContent="center" height="100%">
                <Typography
                  variant="subtitle2"
                  textAlign="center"
                  color="text.secondary"
                >
                  {activityFilter.logTypes.length === 0
                    ? "No filters selected"
                    : "No activities"}
                </Typography>
              </Stack>
            ) : (
              <>
                {activityList?.map((activity) => {
                  const buyerPrice =
                    typeof activity?.data?.listingInfo?.price === "undefined" ||
                    typeof marketplaceDetails?.marketplaceFee === "undefined" ||
                    typeof activity?.data?.royalty?.basis_points === "undefined"
                      ? undefined
                      : getBuyerPrice(
                          activity?.data?.listingInfo?.price,
                          marketplaceDetails?.marketplaceFee,
                          activity?.data?.royalty?.basis_points / 100
                        );
                  const isUserActivity =
                    !!publicKey &&
                    (activity?.data?.listingInfo?.sellerAddress ===
                      publicKey?.toString() ||
                      activity?.data?.listingInfo?.buyerAddress ===
                        publicKey?.toString());

                  return (
                    <MarketplaceActivityItem
                      data={activity}
                      isUserActivity={isUserActivity}
                      price={buyerPrice}
                      key={`${activity?.data?.listingInfo?.txid}`}
                    />
                  );
                })}
                <Stack alignItems="center" ref={bottomRef}>
                  {nextPageLoadingLimit ? (
                    <Box my={1}>
                      <Loader size={24} />
                    </Box>
                  ) : hasNextPage ? (
                    <Box my={1}>
                      <Link
                        component="button"
                        underline="hover"
                        textAlign="center"
                        onClick={() => activityNextPage()}
                      >
                        Load more
                      </Link>
                    </Box>
                  ) : null}
                </Stack>
              </>
            )}
          </>
        )}
      </StyledMarketplaceToolbarInner>
    </StyledMarketplaceToolbar>
  );
};

export default MarketplaceActivity;

export const TYPE_COLORS = {
  buy: "success.main",
  list: "error",
  delist: "text.secondary",
  reprice: "info.dark",
};
