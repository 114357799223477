import { Route, Routes } from "react-router-dom";
import Community from "../pages/Community/Community";
import Home from "../pages/Home/Home";
import Layout from "../pages/Layout";
import ExploreCommunities from "../pages/ExploreCommunities";
import Verify from "../pages/Verify/Verify";
import { Redirect } from "../pages/Redirect/Redirect";
import Claimable from "../pages/Claimable/Claimable";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import Scratch from "../pages/Scratch/Scratch";
import Announcement from "../pages/Announcement";
import Marketplace from "../pages/Marketplace/Marketplace";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/c" element={<ExploreCommunities />} />
        <Route path="/c/:communityName" element={<Community />} />
        {/* <Route path="/m" element={<Marketplaces />} /> */}
        <Route path="/m/:marketplaceId" element={<Marketplace />} />
        <Route path="/claim/:uniqueLink" element={<Claimable />} />
        <Route path="/scratch/:uniqueLink" element={<Scratch />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/redirect/:id" element={<Redirect />} />
        <Route path="/ar/:id" element={<Redirect />} />
        <Route path="/announcement/prnt" element={<Announcement />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Routing;
