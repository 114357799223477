import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import HomeIcon from "../../../components/images/HomeIcon";
import {
  DrawerHeader,
  DrawerListItemButton,
  StyledDrawer,
} from "../../../components/StyledComponents";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";

import { Dispatch, SetStateAction } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import MenuCommunityList from "./MenuCommunityList";
import PublicIcon from "@mui/icons-material/Public";
import Stack from "@mui/material/Stack";
import useCommunitiesListByWallet from "../../../hooks/queries/regular/useCommunitiesListByWallet";
import DataSaverOnRoundedIcon from "@mui/icons-material/DataSaverOnRounded";
import Box from "@mui/material/Box";

const MENU = [
  {
    label: "Home",
    Icon: () => (
      <Stack
        width={22.28}
        height={22.28}
        justifyContent="center"
        alignItems="center"
      >
        <HomeIcon height="1.15em" />
      </Stack>
    ),
    path: "/",
  },
  {
    label: "Explore",
    Icon: PublicIcon,
    path: "/c",
  },
];

interface Props {
  mobileOpen: boolean;
  setMobileOpen: Dispatch<SetStateAction<boolean>>;
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windowProp?: () => Window;
}

const MenuDrawer = (props: Props) => {
  const { windowProp } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isLoading: communitiesByWalletAddressLoading,
    data: communitiesByWalletAddress,
  } = useCommunitiesListByWallet();
  const { publicKey } = useWallet();
  const shouldHideMenu = location.pathname.startsWith("/m/");

  const drawerContent = (
    <>
      <DrawerHeader />
      <List sx={{ pt: 0, pb: 1.5 }}>
        {MENU.map(({ label, Icon, path }) => (
          <ListItem disablePadding key={label}>
            <DrawerListItemButton
              selected={location.pathname === path}
              onClick={() => {
                props.setMobileOpen(false);
                navigate(path);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 1.5,
                }}
              >
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} sx={{ m: 0 }} />
            </DrawerListItemButton>
          </ListItem>
        ))}
      </List>
      {publicKey && (
        <>
          <MenuCommunityList
            loading={communitiesByWalletAddressLoading}
            title="Subscriptions"
            list={communitiesByWalletAddress?.communities}
            currentPath={location.pathname}
            hideMenu={() => {
              props.setMobileOpen(false);
            }}
          />
          <MenuCommunityList
            loading={communitiesByWalletAddressLoading}
            title="Available"
            list={communitiesByWalletAddress?.eligibleCommunities}
            currentPath={location.pathname}
            hideMenu={() => {
              props.setMobileOpen(false);
            }}
          />
        </>
      )}
      <Box mt="auto">
        <Divider />
        <List sx={{ pt: 1.5 }}>
          <ListItem disablePadding>
            <DrawerListItemButton
              LinkComponent="a"
              href="https://studio.anybodies.com/"
              target="_blank"
              sx={{
                background:
                  "linear-gradient(90deg, #FFE000 0%, #FF9962 50.52%, #947DFF 100%)",
                color: "#000",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 1.5,
                }}
              >
                <DataSaverOnRoundedIcon
                  sx={{
                    fill: "#000",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="Create community"
                sx={{ m: 0, fontWeight: 700 }}
              />
            </DrawerListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <DrawerListItemButton
              LinkComponent="a"
              href="https://anybodies-help-center.gitbook.io/help-center/"
              target="_blank"
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 1.5,
                }}
              >
                <HelpRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Help center" sx={{ m: 0 }} />
            </DrawerListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );

  const container =
    windowProp !== undefined ? () => windowProp().document.body : undefined;

  return (
    <>
      {/* Mobile */}
      <StyledDrawer
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
          onBackdropClick: () => {
            props?.setMobileOpen(false);
          },
        }}
        needHide={!shouldHideMenu}
      >
        {drawerContent}
      </StyledDrawer>

      {/* Desktop */}
      {!shouldHideMenu && (
        <StyledDrawer needHide variant="permanent" open>
          {drawerContent}
        </StyledDrawer>
      )}
    </>
  );
};

export default MenuDrawer;
