import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SellRoundedIcon from "@mui/icons-material/SellRounded";
import Typography from "@mui/material/Typography";
import SolanaLogo from "../../../../components/images/SolanaLogo";
import { useWallet } from "@solana/wallet-adapter-react";
import Button from "@mui/material/Button";
import { useEffect, useMemo, useState } from "react";
import ListSettings from "./ListSettings";
import useDelist from "../../../../hooks/queries/marketplace/mutations/useDelist";
import LoadingButton from "@mui/lab/LoadingButton";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import useBuy from "../../../../hooks/queries/marketplace/mutations/useBuy";
import useGetSolanaRate from "../../../../hooks/queries/solana/useGetSolanaRate";
import { useMarketplace } from "../../services/Main";
import { useSnackbar } from "notistack";
import useGetMarketplaceDetails from "../../../../hooks/queries/marketplace/state/useGetMarketplaceDetails";
import { getBuyerPrice } from "../../../../utils";
import Skeleton from "@mui/material/Skeleton";

const MarketplaceDialogActionBox = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { publicKey } = useWallet();
  const { data: solanaRate } = useGetSolanaRate();
  const {
    nftInfoModalOpen,
    nftInfoModalData,
    nftModalSubmitLoading,
    marketplaceId,
    setNftModalSubmitLoading,
    hideNftInfoModal,
  } = useMarketplace();
  const { mutateAsync: delist, isLoading: delistLoading } =
    useDelist(marketplaceId);
  const { data: marketplaceDetails } = useGetMarketplaceDetails(marketplaceId);
  const { mutateAsync: buy, isLoading: buyLoading } = useBuy(marketplaceId);
  const [isEdit, setIsEdit] = useState(false);

  const handleDelist = async () => {
    if (!nftInfoModalData?.listingInfo?.listingId) return;
    await delist(nftInfoModalData);
    hideNftInfoModal();
  };

  const handleBuy = async () => {
    if (!nftInfoModalData?.listingInfo?.listingId) return;
    if (!publicKey) {
      enqueueSnackbar({
        variant: "info",
        message: "Login to gain access",
      });
      return;
    }
    await buy(nftInfoModalData);
    hideNftInfoModal();
  };

  useEffect(() => {
    setNftModalSubmitLoading(delistLoading || buyLoading);
  }, [delistLoading, buyLoading, setNftModalSubmitLoading]);

  useEffect(() => {
    setIsEdit(false);
  }, [nftInfoModalOpen]);

  const buyerPrice = useMemo(() => {
    if (
      typeof nftInfoModalData?.listingInfo?.price === "undefined" ||
      typeof marketplaceDetails?.marketplaceFee === "undefined" ||
      typeof nftInfoModalData?.royalty?.basis_points === "undefined"
    ) {
      return undefined;
    }
    return getBuyerPrice(
      nftInfoModalData?.listingInfo?.price,
      marketplaceDetails?.marketplaceFee,
      nftInfoModalData?.royalty?.basis_points / 100
    );
  }, [
    marketplaceDetails?.marketplaceFee,
    nftInfoModalData?.listingInfo?.price,
    nftInfoModalData?.royalty?.basis_points,
  ]);

  return (
    <Box
      sx={{
        p: 2,
        borderRadius: "5px",
        position: "relative",
        zIndex: 1,
        mb: 3,
        "&::before": {
          content: '""',
          position: "absolute",
          borderRadius: "inherit",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundColor: (theme) => theme.palette.background.default,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          pointerEvents: "none",
          zIndex: -1,
        },
      }}
    >
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Typography component="div" color="text.secondary" fontWeight={100}>
            <Box
              display="inline-flex"
              mr={0.5}
              sx={{ verticalAlign: "middle" }}
            >
              {nftInfoModalData?.listingInfo ? (
                <SellRoundedIcon fontSize="inherit" color="inherit" />
              ) : (
                <StorefrontRoundedIcon fontSize="inherit" color="inherit" />
              )}
            </Box>
            {nftInfoModalData?.listingInfo ? "Listed for" : "Not listed"}
          </Typography>
          {typeof nftInfoModalData?.royalty?.basis_points !== "undefined" && (
            <Typography
              variant="subtitle2"
              fontWeight={100}
              color="warning.light"
            >
              Enforced royalty: {nftInfoModalData?.royalty?.basis_points / 100}%
            </Typography>
          )}
        </Stack>
        {nftInfoModalData?.listingInfo ? (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <SolanaLogo />
              {typeof buyerPrice === "undefined" ? (
                <Skeleton width={120} height={30} />
              ) : (
                <>
                  <Typography variant="h5" fontWeight={600}>
                    {buyerPrice?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 4,
                    })}
                  </Typography>
                  {!!solanaRate &&
                    typeof nftInfoModalData?.listingInfo?.price ===
                      "number" && (
                      <Typography color="text.secondary">
                        (~
                        {(buyerPrice * solanaRate).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                        )
                      </Typography>
                    )}
                </>
              )}
            </Stack>
            {nftInfoModalData?.listingInfo?.sellerAddress ===
            publicKey?.toString() ? (
              isEdit ? (
                <ListSettings isEdit={isEdit} setIsEdit={setIsEdit} />
              ) : (
                <Stack direction="row" spacing={1} mt={2}>
                  <Button
                    variant="base"
                    color="secondary"
                    fullWidth
                    disabled={nftModalSubmitLoading}
                    onClick={() => setIsEdit(true)}
                  >
                    Edit
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="error"
                    fullWidth
                    loading={nftModalSubmitLoading}
                    onClick={handleDelist}
                  >
                    Delist
                  </LoadingButton>
                </Stack>
              )
            ) : (
              <LoadingButton
                loading={nftModalSubmitLoading}
                variant="gradient"
                fullWidth
                onClick={handleBuy}
                sx={{ mt: 1.5 }}
              >
                Buy now
              </LoadingButton>
            )}
          </>
        ) : (
          <ListSettings />
        )}
      </>
      {!nftModalSubmitLoading && <div className="shimmer" />}
    </Box>
  );
};

export default MarketplaceDialogActionBox;
