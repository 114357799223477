import { StyledGridClaimedTableHead } from "../../../../components/StyledComponents";

const ClaimedRewardsHead = () => {
  return (
    <>
      <StyledGridClaimedTableHead
        sx={{ justifyContent: "flex-start", ml: "38px" }}
      >
        Name
      </StyledGridClaimedTableHead>
      <StyledGridClaimedTableHead>Type</StyledGridClaimedTableHead>
      <StyledGridClaimedTableHead>Expiration date</StyledGridClaimedTableHead>
      <StyledGridClaimedTableHead />
    </>
  );
};

export default ClaimedRewardsHead;
