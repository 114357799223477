import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import { RegularRowProps } from "./types";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import BoosterIcon from "../../../../components/images/Booster";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { StyledGridTableCell } from "../../../../components/StyledComponents";
import Skeleton from "@mui/material/Skeleton";
import { formatNumberIntoView } from "../../../../utils";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import Tooltip from "@mui/material/Tooltip";
import TokenAmount from "../../../../components/TokenAmount/TokenAmount";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const RegularRow = ({
  data,
  plan,
  tokenInfo,
  isItemSelected,
  onItemSelect,
  onUnsubscribe,
  onShowLevelsModal,
}: RegularRowProps) => {
  const handleShowLevelsModal = () => {
    if (!plan?.maturityActive) return;
    onShowLevelsModal({
      baseDailyReward: data?.baseDailyReward,
      step: plan?.StakingDuration,
      levels: plan?.maturityLevels,
      currentLevel: data?.level,
    });
  };

  return (
    <Box
      sx={{
        display: "contents",
        "&:hover": {
          "& > div": {
            background: (theme) =>
              alpha(
                theme.palette.action.hover,
                theme.palette.action.activatedOpacity
              ),
          },
        },
      }}
      key={data?.id}
    >
      <StyledGridTableCell
        sx={{
          p: 0,
          borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
          borderBottomLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          onChange={() => {
            onItemSelect(data?.id);
          }}
          disabled={!data?.otherData?.SupportsAutoStaking}
        />
      </StyledGridTableCell>
      <StyledGridTableCell sx={{ justifyContent: "flex-start" }}>
        <Stack direction="row" alignItems="center" spacing={1} maxWidth="100%">
          <Avatar
            src={data?.otherData?.manifest?.image}
            alt={data?.otherData?.manifest?.name}
            sx={{ width: 60, height: 60, flexShrink: 0 }}
            variant="rounded"
          >
            <InsertPhotoRoundedIcon sx={{ fontSize: "2rem" }} />
          </Avatar>
          <Box minWidth={0} whiteSpace="nowrap">
            <Stack direction="row" alignItems="center" spacing={0.5} mb={0.5}>
              {!!data?.otherData?.MultiplierInfo && (
                <Stack width={12} flexShrink={0}>
                  <BoosterIcon width="100%" />
                </Stack>
              )}
              <Typography variant="body2" fontWeight="bold">
                {data?.otherData?.manifest?.name}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Tooltip
                title={`$${tokenInfo?.symbol?.toUpperCase()}`}
                placement="top"
              >
                <Avatar
                  src={tokenInfo?.uri}
                  alt={tokenInfo?.name}
                  sx={{
                    width: 18,
                    height: 18,
                  }}
                  variant="square"
                >
                  <InsertPhotoRoundedIcon sx={{ fontSize: "1rem" }} />
                </Avatar>
              </Tooltip>
              <Typography
                component="div"
                variant="caption"
                lineHeight={1.175}
                textAlign="left"
              >
                {plan ? plan?.PlanTitle : <Skeleton width={100} />}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </StyledGridTableCell>
      <StyledGridTableCell>
        <TokenAmount
          amount={
            data?.dailyReward === 0
              ? 0
              : data?.dailyReward.toLocaleString("en-US", {
                  maximumFractionDigits: data?.decimals,
                })
          }
          img={tokenInfo?.uri}
          tokenLabel={`$${tokenInfo?.symbol}`}
        />
      </StyledGridTableCell>
      <StyledGridTableCell>
        <Typography fontWeight="bold">
          {data?.level ? data?.level : "―"}
        </Typography>
        {plan?.maturityActive && (
          <Tooltip title="Info about level">
            <IconButton
              size="small"
              sx={{
                ml: 0.375,
                color: "text.secondary",
                p: 0.25,
                fontSize: "0.875rem",
              }}
              onClick={handleShowLevelsModal}
            >
              <InfoOutlinedIcon fontSize="inherit" color="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </StyledGridTableCell>
      <StyledGridTableCell>
        <TokenAmount
          amount={
            data?.earnings === 0
              ? 0
              : formatNumberIntoView(data?.earnings, data?.decimals)
          }
          img={tokenInfo?.uri}
          tokenLabel={`$${tokenInfo?.symbol}`}
        />
      </StyledGridTableCell>
      <StyledGridTableCell
        sx={{
          borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
          borderBottomRightRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Button
          color="error"
          size="small"
          onClick={onUnsubscribe}
          disabled={!data?.otherData?.SupportsAutoStaking || isItemSelected}
        >
          Unsubscribe
        </Button>
      </StyledGridTableCell>
    </Box>
  );
};

export default RegularRow;
