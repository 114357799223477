import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import EastRoundedIcon from "@mui/icons-material/EastRounded";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import useGetTopCommunities from "../../../hooks/queries/regular/useGetTopCommunities";
import {
  CommunityCardMini,
  CommunityCardMiniSkeleton,
} from "../../../components/CommunityCardMini";

const TopSubscriptions = () => {
  const navigate = useNavigate();
  const { data: topCommunities, isLoading } = useGetTopCommunities();

  const columns = topCommunities
    ? Array.from({ length: Math.ceil(topCommunities.length / 5) }, (_, i) =>
        topCommunities.slice(i * 5, i * 5 + 5)
      )
    : [];

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        sx={{ px: { xs: 1.5, md: 3 } }}
      >
        <Typography variant="h6" fontWeight="bold">
          Popular Subscriptions
        </Typography>
        <Button
          variant="text"
          color="inherit"
          endIcon={<EastRoundedIcon />}
          onClick={() => {
            navigate("/c");
          }}
        >
          View all
        </Button>
      </Stack>
      <Box sx={{ paddingX: { md: 3 } }}>
        <Stack
          direction="row"
          sx={{ overflowX: "auto" }}
          paddingX={{ xs: 1.5, md: 0 }}
        >
          {isLoading
            ? [1, 2, 3].map((column) => {
                return (
                  <Box
                    marginLeft={column === 1 ? 0 : 1.5}
                    minWidth={320}
                    width="calc(33.3333% - 6px)"
                    key={column}
                  >
                    {[1, 2, 3, 4, 5].map((c) => (
                      <CommunityCardMiniSkeleton key={column * c} />
                    ))}
                  </Box>
                );
              })
            : columns.map((column, columnI) => {
                return (
                  <Box
                    marginLeft={columnI === 0 ? 0 : 1.5}
                    minWidth={320}
                    width="calc(33.3333% - 6px)"
                    key={columnI}
                  >
                    {column?.map((c, i) => (
                      <CommunityCardMini
                        img={c?.profile?.profileImage}
                        number={columnI * 5 + i + 1}
                        name={c?.profile?.projectName}
                        floor={c?.floorPrice}
                        staked={c?.totalStaked}
                        total={c?.TVL}
                        onClick={() => {
                          navigate(`/c/${c?.route}`);
                        }}
                        key={c?.communityId}
                      />
                    ))}
                  </Box>
                );
              })}
        </Stack>
      </Box>
    </>
  );
};

export default TopSubscriptions;
