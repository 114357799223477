import { useMutation } from "@tanstack/react-query";
import { SubscriptionBooster, SubscriptionToken } from "../../../../../types";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { useWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from "notistack";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";
import useRefetchAllSubscriptions from "../state/useRefetchAllSubscriptions";

type Props = {
  onSuccess: () => void;
};

export default function useEditBoosterMutation({ onSuccess }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const editBoosterFn = useHttpsCallable("boosters/apply");
  const { data: communityInfo } = useGetCommunityInfo();
  const { publicKey } = useWallet();
  const refetchSubs = useRefetchAllSubscriptions();

  return useMutation(
    async (tokens: {
      multiplierDocId: SubscriptionBooster["id"];
      addStakedTokenDocIds: SubscriptionToken["id"][];
      removeStakedTokenDocIds: SubscriptionToken["id"][];
    }) => {
      const newSubsTxRes = (await editBoosterFn({
        projectId: communityInfo?.accountId,
        vaultId: communityInfo?.communityId,
        walletAddress: publicKey?.toString(),
        ...tokens,
      })) as any;

      if (!newSubsTxRes?.data?.success) {
        throw new Error("Something went wrong. Try again later");
      }
      return newSubsTxRes?.data;
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Booster settings successfully applied",
        });
        refetchSubs();
        onSuccess();
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
}
