import { StyledMarketplaceNftListBox } from "../../../components/StyledComponents";
import { Fragment, useCallback, useEffect, useRef } from "react";
import MarketplaceNftListItem from "./MarketplaceNftListItem";
import Stack from "@mui/material/Stack";
import Loader from "../../../components/Loader/Loader";
import Typography from "@mui/material/Typography";
import Empty from "../../../components/Empty/Empty";
import MarketplaceNftListItemSkeleton from "./MarketplaceNftListItemSkeleton";
import { useMarketplace } from "../services/Main";
import { useExploreMarketplace } from "../services/Explore";

const ExploreMode = () => {
  const { viewMaxWidth } = useMarketplace();
  const {
    exploreList,
    exploreLoading,
    exploreNextPageLoadingLimit,
    exploreNextPage,
  } = useExploreMarketplace();
  const bottomRef = useRef<HTMLDivElement>(null);

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && entry.intersectionRatio === 1) {
        exploreNextPage();
      }
    },
    [exploreNextPage]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback);

    let observerRefValue: HTMLDivElement | null = null;
    if (bottomRef.current) {
      observer.observe(bottomRef.current);
      observerRefValue = bottomRef.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [observerCallback]);

  if (exploreLoading) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        height="100%"
        spacing={1}
      >
        <Loader size={40} />
        <Typography fontWeight={900}>Loading..</Typography>
      </Stack>
    );
  }

  if (!exploreList?.length) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        height="100%"
        spacing={1}
      >
        <Empty text="Nothing to show" imgWidth={500} />
      </Stack>
    );
  }

  return (
    <>
      <StyledMarketplaceNftListBox maxItemWidth={viewMaxWidth}>
        {exploreList?.map((nft) => (
          <MarketplaceNftListItem data={nft} key={nft?.mintAddress} />
        ))}
        {!!exploreNextPageLoadingLimit &&
          Array.from(Array(exploreNextPageLoadingLimit).keys()).map((i) => (
            <MarketplaceNftListItemSkeleton key={i} />
          ))}
      </StyledMarketplaceNftListBox>
      <div ref={bottomRef} />
    </>
  );
};

export default ExploreMode;
