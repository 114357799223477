import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { copyTextToClipboard, stringToShorten } from "../../../../utils";
import { useState } from "react";
import Link from "@mui/material/Link";

const DetailsSolscan = ({
  type,
  value,
}: {
  type: "token" | "account";
  value: string;
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    await copyTextToClipboard(value);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Tooltip placement="top" title="View on Solscan">
        <a
          href={`https://solscan.io/${type}/${value}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://magiceden.io/_next/image?url=https%3A%2F%2Fnext.cdn.magiceden.dev%2F_next%2Fstatic%2Fmedia%2Fsolscan.cbd7e3f5.png&w=16&q=75"
            alt="Solscan"
            width={12}
          />
        </a>
      </Tooltip>
      <Tooltip
        placement="top"
        title={isCopied ? "Copied!" : "Copy to clypboard"}
      >
        <Link
          component="button"
          color="inherit"
          fontWeight={600}
          underline="none"
          variant="body1"
          onClick={handleCopyClick}
        >
          {stringToShorten(value)}
        </Link>
      </Tooltip>
    </Stack>
  );
};

export default DetailsSolscan;
