import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

type Props = {
  text: { title: string; description: string };
  retry: () => void;
};

const VerifyError = ({ text, retry }: Props) => {
  return (
    <Box mt={2}>
      <Alert
        variant="filled"
        severity="error"
        sx={{ mb: 2, textAlign: "left" }}
      >
        <AlertTitle>{text?.title}</AlertTitle>
        {text?.description}
      </Alert>
      <Button onClick={retry}>Try again</Button>
    </Box>
  );
};

export default VerifyError;
