import LanguageIcon from "@mui/icons-material/Language";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import XIcon from "@mui/icons-material/X";
import Discord from "../../../components/images/Discord";
import { ClaimableDetails, ProjectInfoData } from "../../../types";
import Tooltip from "@mui/material/Tooltip";
import { Fragment } from "react";
import Divider from "@mui/material/Divider";

const SOCIALS = [
  { key: "Twitter", tooltip: "X", Icon: XIcon },
  { key: "Discord", tooltip: "Discord", Icon: Discord },
  { key: "Website", tooltip: "Website", Icon: LanguageIcon },
];

type Props = {
  loading: boolean;
  profile?: ProjectInfoData["profile"] | ClaimableDetails["communityProfile"];
};

const MarketplaceLinks = ({ loading, profile }: Props) => {
  const theme = useTheme();
  if (loading) {
    return (
      <Stack direction="row" spacing={0.5}>
        {SOCIALS.map((i, index) => (
          <Fragment key={index}>
            <Stack
              justifyContent="center"
              alignItems="center"
              height={26}
              width={26}
            >
              <Skeleton variant="circular" height={18} width={18} />
            </Stack>
            {index !== SOCIALS.length - 1 && <Divider orientation="vertical" />}
          </Fragment>
        ))}
      </Stack>
    );
  }

  if (
    !profile?.showDiscord &&
    !profile?.showTwitter &&
    !profile?.showWebsite &&
    !profile?.showSecondaryMarket
  )
    return null;

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {!!profile &&
        SOCIALS.map(({ key, tooltip, Icon }, index) => {
          const showProp = `show${key}` as
            | "showTwitter"
            | "showDiscord"
            | "showWebsite";
          if (profile?.[showProp]) {
            const iconProps =
              showProp === "showDiscord"
                ? { fill: theme.palette.text.secondary, width: "1em" }
                : { fontSize: "inherit" };
            const linkProp = `link${key}` as
              | "linkTwitter"
              | "linkDiscord"
              | "linkWebsite";
            const link = profile?.[linkProp];
            const href =
              link.match(/^http?:\/\//i) || link.match(/^https?:\/\//i)
                ? link
                : `https://${link}`;
            return (
              <Fragment key={key}>
                <Tooltip title={tooltip}>
                  <IconButton
                    size="small"
                    LinkComponent="a"
                    href={href}
                    target="_blank"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    <Icon {...(iconProps as any)} />
                  </IconButton>
                </Tooltip>
                {index !== SOCIALS.length - 1 && (
                  <Divider orientation="vertical" flexItem />
                )}
              </Fragment>
            );
          }
          return null;
        })}
    </Stack>
  );
};

export default MarketplaceLinks;
