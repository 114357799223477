import Box from "@mui/material/Box";
import { Order, Plan } from "../../../../types";
import { useCallback, useEffect, useState } from "react";
import BoostersHead from "./BoostersHead";
import { RowData } from "./types";
import BoostersRow from "./BoostersRow";
import Empty from "../../../../components/Empty/Empty";
import { useWallet } from "@solana/wallet-adapter-react";
import Stack from "@mui/material/Stack";
import useUnsubscribeMutation from "../../../../hooks/queries/community/subscriptions/mutations/useUnsubscribeMutation";
import TableToolbar from "../../TableToolbar";
import { StyledGridTable } from "../../../../components/StyledComponents";
import BoostersSkeleton from "./BoostersSkeleton";
import Typography from "@mui/material/Typography";
import { useCommunity } from "../../../../services/CommunityService";
import CustomWalletButton from "../../../../components/CustomWalletButton";
import useGetCommunityPlans from "../../../../hooks/queries/community/info/useGetCommunityPlans";
import useGetSubscriptions from "../../../../hooks/queries/community/subscriptions/state/useGetSubscriptions";

export default function Boosters() {
  const { publicKey } = useWallet();
  const { data: communityPlans } = useGetCommunityPlans();
  const { data: subscriptionsInfo, isLoading: subscriptionsInfoLoading } =
    useGetSubscriptions();
  const { selectedBoostersFilterPlan, setSelectedBoostersFilterPlan } =
    useCommunity();

  const { mutateAsync: unsubscribeMutation } = useUnsubscribeMutation({
    onSuccess: () => {
      setSelected([]);
    },
    isBooster: true,
  });

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof RowData>("boosted");
  const [selected, setSelected] = useState<string[]>([]);
  const [finalRows, setFinalRows] = useState<RowData[] | undefined>();
  const [planFilterList, setPlanFilterList] = useState<
    { id: Plan["AStakingProgramId"]; title: Plan["PlanTitle"] }[]
  >([]);

  const setupFinalRows = useCallback(() => {
    const rows: RowData[] = [];
    subscriptionsInfo?.boosters?.forEach((token) => {
      if (
        selectedBoostersFilterPlan &&
        selectedBoostersFilterPlan !== token?.PlanId
      )
        return;
      rows.push({
        id: token?.id,
        planId: token?.PlanId,
        boosted: token?.ActiveNumber,
        otherData: { ...token },
      });
    });

    if (order === "asc") {
      setFinalRows(rows.sort((a, b) => (a[orderBy] > b[orderBy] ? 1 : -1)));
    } else {
      setFinalRows(rows.sort((a, b) => (a[orderBy] > b[orderBy] ? -1 : 1)));
    }
  }, [order, orderBy, selectedBoostersFilterPlan, subscriptionsInfo?.boosters]);

  useEffect(() => {
    setupFinalRows();
  }, [setupFinalRows]);

  useEffect(() => {
    const incomeFilterPlansIds: string[] = [];
    subscriptionsInfo?.boosters?.forEach((token: any) => {
      if (!incomeFilterPlansIds.includes(token?.PlanId)) {
        incomeFilterPlansIds.push(token?.PlanId);
      }
    });
    setPlanFilterList(
      incomeFilterPlansIds?.length === 0
        ? []
        : incomeFilterPlansIds.map((id) => {
            const plan = communityPlans?.plans?.[id];
            return {
              id: plan?.AStakingProgramId || "",
              title: plan?.PlanTitle || "",
            };
          })
    );
    if (setSelectedBoostersFilterPlan) {
      setSelectedBoostersFilterPlan("");
    }
  }, [
    communityPlans?.plans,
    setSelectedBoostersFilterPlan,
    subscriptionsInfo?.boosters,
  ]);

  useEffect(() => {
    return () => {
      setOrder("asc");
      setOrderBy("boosted");
      setFinalRows(undefined);
      setSelected([]);
    };
  }, [subscriptionsInfo]);

  const handleRequestSort = (property: keyof RowData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allSelected: string[] = [];
      finalRows?.forEach((n) => {
        if (!n?.otherData?.SupportsAutoStaking) return;
        allSelected.push(n.id);
      });
      setSelected(allSelected);
      return;
    }
    setSelected([]);
  };

  const onItemSelect = (id: RowData["id"]) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  return (
    <Box sx={{ mb: 2 }} id="boosters">
      <TableToolbar
        numSelected={selected.length}
        planFilterList={planFilterList}
        onAction={() => {
          unsubscribeMutation(selected);
        }}
        filterSelected={selectedBoostersFilterPlan}
        onFilterPlan={(id) => {
          if (!setSelectedBoostersFilterPlan) return;
          setSelectedBoostersFilterPlan(id);
        }}
      />
      {publicKey ? (
        subscriptionsInfoLoading || typeof finalRows === "undefined" ? (
          <BoostersSkeleton />
        ) : (
          <>
            {subscriptionsInfo?.boosters?.length === 0 ? (
              <Empty text="You don't have any subscriptions" />
            ) : (
              <Box sx={{ overflowX: "auto" }}>
                <StyledGridTable
                  sx={{
                    gridTemplateColumns:
                      "48px minmax(150px, 1fr) repeat(4, auto)",
                    minWidth: "650px",
                  }}
                >
                  <BoostersHead
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    numSelected={selected.length}
                    rowCount={finalRows?.length}
                    order={order}
                    orderBy={orderBy}
                    disabledCheckbox={finalRows?.every(
                      (i) => !i?.otherData?.SupportsAutoStaking
                    )}
                  />
                  {finalRows?.map((token) => {
                    return (
                      <BoostersRow
                        key={token?.id}
                        data={token}
                        plan={
                          communityPlans?.boosters?.[token?.otherData?.PlanId]
                        }
                        isItemSelected={selected.includes(token?.id)}
                        onItemSelect={onItemSelect}
                        onUnsubscribe={() => unsubscribeMutation([token?.id])}
                      />
                    );
                  })}
                </StyledGridTable>
              </Box>
            )}
          </>
        )
      ) : (
        <Box sx={{ my: 3, px: { xs: 1.5, md: 3 } }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            textAlign="center"
            color="text.secondary"
          >
            Login to gain access
          </Typography>
          <Stack pt={2} alignItems="center">
            <CustomWalletButton variant="gradient" />
          </Stack>
        </Box>
      )}
    </Box>
  );
}
