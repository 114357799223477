import Typography from "@mui/material/Typography";
import { StyledContractBox } from "../StyledComponents";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

const ContractSkeleton = () => {
  return (
    <Box pr={3}>
      <StyledContractBox>
        <Box p={2}>
          <Typography variant="h6" mb={3} fontWeight="bold">
            <Skeleton />
          </Typography>
          <Box mb={2}>
            {[1, 2].map((i) => (
              <Typography variant="body1" mb={1} key={i}>
                <Skeleton />
              </Typography>
            ))}
          </Box>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="body1" mb={2} paragraph fontWeight="bold">
            <Skeleton />
          </Typography>
          <Box mb={4}>
            {[1, 2].map((i) => (
              <Typography variant="body2" mb={1} key={i}>
                <Skeleton />
              </Typography>
            ))}
          </Box>
          <Skeleton width="100%" height={36} />
        </Box>
      </StyledContractBox>
    </Box>
  );
};

export default ContractSkeleton;
