import Stack from "@mui/material/Stack";
import {
  StyledMarketplaceNftCardImage,
  StyledMarketplaceNftCardImageInfo,
} from "../../../components/StyledComponents";
import Typography from "@mui/material/Typography";
import SolanaLogo from "../../../components/images/SolanaLogo";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const MarketplaceNftListItemSkeleton = () => {
  return (
    <Box>
      <StyledMarketplaceNftCardImage className="image">
        <Skeleton width="100%" height="100%" />
        <StyledMarketplaceNftCardImageInfo>
          <Typography variant="body2">
            <Skeleton width={40} variant="text" />
          </Typography>
          <Typography variant="body2">
            <Skeleton width={40} variant="text" />
          </Typography>
        </StyledMarketplaceNftCardImageInfo>
      </StyledMarketplaceNftCardImage>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mt={0.5}
      >
        <Stack direction="row" alignItems="center" spacing={0.75} ml={0.5}>
          <SolanaLogo fontSize={12} />
          <Typography>
            <Skeleton width={60} variant="text" />
          </Typography>
        </Stack>
        <Skeleton
          width={18}
          variant="circular"
          sx={{ my: "5px !important", mr: "5px !important" }}
        />
      </Stack>
    </Box>
  );
};

export default MarketplaceNftListItemSkeleton;
