import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import SubscriptionsTokensRow from "./ClaimedRewardsRow";
import SubscriptionsTokensHead from "./ClaimedRewardsHead";
import SubscriptionsTokensSkeleton from "./ClaimedRewardsSkeleton";
import { StyledGridClaimedTable } from "../../../../components/StyledComponents";
import { RowData } from "./types";
import useGetClaimedRewards from "../../../../hooks/queries/community/earnings/state/useGetClaimedRewards";
import { useCommunity } from "../../../../services/CommunityService";
import { ClaimedReward } from "../../../../types";
import useGetSubscriptions from "../../../../hooks/queries/community/subscriptions/state/useGetSubscriptions";

const ClaimedRewards = () => {
  const { data: claimedRewards, isLoading: claimedRewardsLoading } =
    useGetClaimedRewards();
  const { data: subscriptionsInfo, dataUpdatedAt } = useGetSubscriptions();
  const [finalRows, setFinalRows] = useState<RowData[] | undefined>();
  const { setRewardDetailsData } = useCommunity();

  useEffect(() => {
    if (!claimedRewards) {
      setFinalRows(undefined);
      return;
    }

    const incomeDataRows = claimedRewards?.map((redeemable) => ({
      name: redeemable?.tokenMetadata?.name,
      campaignTitle: redeemable?.campaignTitle,
      mintAddress: redeemable?.mintAddress,
      image: redeemable?.tokenMetadata?.image,
      redeemExpirationDate: redeemable?.redeemExpirationDate,
      rewardType: redeemable?.rewardType,
      status: redeemable?.status,
      campaignId: redeemable?.campaignId,
    }));

    setFinalRows(incomeDataRows);
  }, [claimedRewards, dataUpdatedAt, subscriptionsInfo]);

  const handleRedeem = (
    campaignId: string,
    redeemable: {
      mintAddress: string;
      status: ClaimedReward["status"];
      redeemedImage?: string;
    }
  ) => {
    if (!setRewardDetailsData) return;
    setRewardDetailsData({ campaignId, redeemable });
  };

  if (claimedRewardsLoading || typeof finalRows === "undefined") {
    return <SubscriptionsTokensSkeleton />;
  }

  if (finalRows && finalRows?.length > 0) {
    return (
      <Box sx={{ overflowX: "auto" }} mt={1.5}>
        <StyledGridClaimedTable>
          <SubscriptionsTokensHead />
          {finalRows?.map((token) => {
            return (
              <SubscriptionsTokensRow
                data={token}
                key={token?.mintAddress}
                onRedeem={handleRedeem}
              />
            );
          })}
        </StyledGridClaimedTable>
      </Box>
    );
  }

  return null;
};

export default ClaimedRewards;
