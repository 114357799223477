import Box from "@mui/material/Box";
import CreateCommunityBanner from "./CreateCommunityBanner";
import TopSubscriptions from "./TopSubscriptions/TopSubscriptions";
import TrendingExperiences from "./TrendingExperiences/TrendingExperiences";
import Carousel from "./Carousel/Carousel";

const Home = () => {
  return (
    <>
      <Box px={{ xs: 0, md: 3 }} pt={{ xs: 0, md: 1.5 }} mb={{ xs: 2, md: 5 }}>
        <Carousel />
      </Box>
      <Box mb={{ xs: 2, md: 5 }}>
        <TopSubscriptions />
      </Box>
      <TrendingExperiences />
      <CreateCommunityBanner />
    </>
  );
};

export default Home;
