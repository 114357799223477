import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { MarketplaceDetailsResponse } from "../../../../types";

const useGetMarketplaceDetails = (marketplaceId?: string) => {
  const { enqueueSnackbar } = useSnackbar();

  const getDetails = async () => {
    const response = await fetch(
      "https://us-central1-nft-anybodies.cloudfunctions.net/marketplace/v1/api/analytics/details",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: { marketplaceId } }),
      }
    );
    const { data }: { data: MarketplaceDetailsResponse } =
      await response.json();
    if (!data?.success) {
      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    }
    return data.data;
  };

  return useQuery(["marketplace", marketplaceId], getDetails, {
    staleTime: 30 * 60 * 1000,
    enabled: !!marketplaceId,
    onError: (err: any) => {
      enqueueSnackbar({
        variant: "error",
        message: err?.message || "Something went wrong. Try again, please",
      });
    },
  });
};

export default useGetMarketplaceDetails;
