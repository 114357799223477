import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Manifest, SubscribeRequestResponse } from "../../../../../types";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import { useSnackbar } from "notistack";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";
import useRefetchAllSubscriptions from "../state/useRefetchAllSubscriptions";

type Props = {
  contractId?: string;
  isBooster?: boolean;
  onSuccess: () => void;
};

export default function useSubscribeMutation({
  contractId,
  isBooster,
  onSuccess,
}: Props) {
  const queryClient = useQueryClient();
  const { data: communityInfo } = useGetCommunityInfo();
  const { publicKey, signAllTransactions } = useWallet();
  const refetchSubs = useRefetchAllSubscriptions();
  const { enqueueSnackbar } = useSnackbar();
  const subTxFbFn = useHttpsCallable("subscriptions/subscribe/request");
  const unsubFbFn = useHttpsCallable("subscriptions/subscribe/process");

  return useMutation(
    async (selected: Manifest["mintAddress"][]) => {
      const newSubsTxRes = await subTxFbFn({
        accountId: communityInfo?.accountId,
        communityId: communityInfo?.communityId,
        tokens: selected.map((mintAddress) => ({
          contractId,
          mintAddress,
        })),
        walletAddress: publicKey?.toString(),
        type: isBooster ? "booster" : "subsciption",
      });
      const newSubsTxResData = newSubsTxRes?.data as SubscribeRequestResponse;
      if (!newSubsTxResData?.success) {
        throw new Error(
          `${newSubsTxResData?.error?.title}\n${newSubsTxResData?.error?.description}`
        );
      }
      if (!signAllTransactions) {
        throw new Error("Can't create transaction");
      }

      const signedTransactions = await signAllTransactions(
        newSubsTxResData?.transactionRequests?.map((tx: any) =>
          Transaction.from(tx?.rawTransaction?.data)
        )
      );

      const res = (await unsubFbFn({
        transactionRequests: signedTransactions.map((transaction, index) => {
          const buf = transaction.serialize({
            requireAllSignatures: false,
            verifySignatures: false,
          });
          return {
            rawTransaction: Array.from(buf),
            requestId: newSubsTxResData?.transactionRequests[index].requestId,
          };
        }),
      })) as any;
      if (!res?.data?.success) {
        throw new Error("Something went wrong. Try again later");
      }
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Subscription successfully created",
        });
        queryClient.invalidateQueries({
          queryKey: ["boosters/available"],
          exact: false,
          refetchType: "all",
        });
        queryClient.invalidateQueries({
          queryKey: ["subscriptions/available"],
          exact: false,
          refetchType: "all",
        });
        refetchSubs();
        onSuccess();
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
}
