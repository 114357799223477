import Box from "@mui/material/Box";
import { RewardListItem } from "../../../types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import moment from "moment";
import { useCommunity } from "../../../services/CommunityService";
import Tooltip from "@mui/material/Tooltip";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import Stack from "@mui/material/Stack";
import {
  StyleRewardCustomLinearProgress,
  StyledRewardCardImage,
  StyledRewardCardImageStatus,
} from "../../../components/StyledComponents";
import { useWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from "notistack";

type Props = { reward: RewardListItem };

const RewardsItem = ({ reward }: Props) => {
  const { publicKey } = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const endDate = moment(reward?.claimExpirationDate);
  const { setRewardDetailsData } = useCommunity();

  const handleClick = () => {
    if (!setRewardDetailsData) return;
    if (!publicKey) {
      enqueueSnackbar({
        variant: "info",
        message: "Login to gain access",
      });
      return;
    }
    setRewardDetailsData({ campaignId: reward?._id });
  };

  let rewardButtonOptions = {
    variant: "contained",
    text: "Claim now",
    color: "primary",
  };
  if (reward?.status === "ended" || reward?.claimPercent >= 100) {
    rewardButtonOptions = {
      variant: "base",
      text: "Details",
      color: "primary",
    };
  } else if (reward?.autoRedeem) {
    rewardButtonOptions = {
      variant: "contained",
      text: "Redeem now",
      color: "secondary",
    };
  }

  return (
    <Stack height="100%">
      <Box mb={1.5}>
        <StyledRewardCardImage
          sx={{
            backgroundImage: `url('${reward?.profile?.bannerImage}')`,
          }}
        >
          {(reward?.status === "ended" || reward?.claimPercent >= 100) && (
            <StyledRewardCardImageStatus>
              <Typography variant="h6" fontWeight={900}>
                {reward?.claimPercent >= 100 ? "Sold Out" : "Ended"}
              </Typography>
            </StyledRewardCardImageStatus>
          )}
        </StyledRewardCardImage>
        <Typography variant="subtitle2" mt={1} lineHeight={1}>
          {reward?.profile?.title}
        </Typography>
        <Typography fontWeight={700}>{reward?.tokenMetadata?.name}</Typography>
        <Tooltip title="Campaign end date" placement="top">
          <Box display="inline-flex" alignItems="center" color="text.secondary">
            <EventBusyRoundedIcon fontSize="small" />
            <Typography variant="subtitle2" ml={0.5}>
              {endDate?.format("DD MMMM YYYY")}
            </Typography>
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{ marginTop: "auto" }}>
        <Box sx={{ position: "relative", mb: 0.5 }}>
          <Typography
            variant="caption"
            fontWeight={700}
            textAlign="right"
            component="div"
            color="primary.main"
          >{`${Math.round(reward?.claimPercent)}% claimed`}</Typography>
          <StyleRewardCustomLinearProgress
            variant="determinate"
            value={reward?.claimPercent}
          />
        </Box>
        <Button
          variant={rewardButtonOptions.variant as "contained" | "base"}
          color={rewardButtonOptions.color as "primary" | "secondary"}
          fullWidth
          sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          onClick={handleClick}
        >
          {rewardButtonOptions.text}
        </Button>
      </Box>
    </Stack>
  );
};

export default RewardsItem;
