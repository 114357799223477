import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import { EllipsisTypography } from "../../../components/StyledComponents";
import MarketplaceStats from "./MarketplaceStats/MarketplaceStats";
import useGetMarketplaceDetails from "../../../hooks/queries/marketplace/state/useGetMarketplaceDetails";
import Skeleton from "@mui/material/Skeleton";
import { useMarketplace } from "../services/Main";
import CommunityLinks from "../../Community/CommunityInfo/CommunityLinks";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const MarketplaceHeader = () => {
  const { marketplaceId } = useMarketplace();
  const { data: marketplaceDetails, isLoading: marketplaceDetailsLoading } =
    useGetMarketplaceDetails(marketplaceId);

  return (
    <>
      <Divider />
      <Box p={1}>
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          alignItems={{
            xs: "flex-start",
            sm: "center",
          }}
          justifyContent="space-between"
          gap={2}
        >
          <Stack direction="row" spacing={2}>
            <Avatar
              src={marketplaceDetails?.profile?.image?.url}
              alt="Logo"
              variant="rounded"
              sx={{ width: 60, height: 60 }}
            >
              <InsertPhotoRoundedIcon fontSize="large" />
            </Avatar>
            <Stack justifyContent="space-evenly">
              {marketplaceDetailsLoading ? (
                <EllipsisTypography variant="h6">
                  <Skeleton width={140} variant="text" />
                </EllipsisTypography>
              ) : (
                <Typography variant="h6" fontWeight="bold">
                  {marketplaceDetails?.profile?.title}
                </Typography>
              )}
              <CommunityLinks
                loading={marketplaceDetailsLoading}
                profile={marketplaceDetails?.links}
              />
            </Stack>
          </Stack>
          <MarketplaceStats />
        </Stack>
      </Box>
    </>
  );
};

export default MarketplaceHeader;
