import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

const MarketplaceStatsItem = ({
  title,
  value,
  isLoading,
}: {
  title: string;
  value?: string;
  isLoading: boolean;
}) => {
  return (
    <Box sx={{ paddingY: 1, whiteSpace: "nowrap" }}>
      <Typography variant="h6" fontWeight={900} lineHeight={1} mb={0.5}>
        {isLoading ? <Skeleton width={40} height={19} /> : value}
      </Typography>
      <Typography
        color="text.secondary"
        variant="overline"
        lineHeight={1}
        component="div"
      >
        {title}
      </Typography>
    </Box>
  );
};

export default MarketplaceStatsItem;
