import { useWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from "notistack";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { SubscriptionByWalletResponse } from "../../../../../types";
import { useQuery } from "@tanstack/react-query";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";

const useGetSubscriptions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const path = "subscriptions/list";
  const { publicKey } = useWallet();
  const fbFn = useHttpsCallable(path);
  const { data: communityInfo } = useGetCommunityInfo();

  const getSubscriptionsList = async () => {
    const { data } = await fbFn({
      accountId: communityInfo?.accountId,
      communityId: communityInfo?.communityId,
      walletAddress: publicKey?.toString(),
    });
    const res = data as SubscriptionByWalletResponse;
    if (!res?.success) {
      throw new Error(`${res?.error?.title}\n${res?.error?.description}`);
    }
    return res;
  };

  return useQuery(
    [
      path,
      communityInfo?.accountId,
      communityInfo?.communityId,
      publicKey?.toString(),
    ],
    getSubscriptionsList,
    {
      staleTime: 5 * 60 * 1000,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      enabled:
        !!communityInfo?.accountId &&
        !!communityInfo?.communityId &&
        !!publicKey,
    }
  );
};

export default useGetSubscriptions;
