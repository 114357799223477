import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { MainTheme } from "./config/mui";
import { AppState } from "./services";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { notistackComponents, notistackIcons } from "./config/notistack";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={MainTheme}>
    <SnackbarProvider
      autoHideDuration={3000}
      maxSnack={3}
      preventDuplicate
      iconVariant={notistackIcons}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      Components={notistackComponents}
    >
      <AppState>
        <App />
      </AppState>
    </SnackbarProvider>
  </ThemeProvider>
);
