import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 32"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M.174 12.104H34.61v.419c0 4.252.003 8.502 0 12.754-.002 3.378-2.598 5.878-6.112 5.88-7.408.003-14.816.003-22.224 0-3.487-.002-6.095-2.508-6.098-5.853-.003-4.264 0-8.528 0-12.793V12.104Zm13.608 7.167v.003c-.4 0-.8-.012-1.2.004-.644.025-1.12.36-1.29.882-.179.548.02 1.082.555 1.46.621.441 1.242.88 1.876 1.304.202.135.254.264.172.49a80.495 80.495 0 0 0-.711 2.072c-.218.657-.05 1.21.458 1.557.49.334 1.075.307 1.65-.088.617-.423 1.236-.845 1.837-1.287.204-.15.339-.136.534.006.571.416 1.157.814 1.738 1.218.65.452 1.235.5 1.75.145.522-.36.664-.91.42-1.636-.225-.667-.447-1.334-.683-1.997-.073-.205-.048-.328.153-.46.623-.413 1.233-.849 1.843-1.28.588-.416.797-.948.606-1.515-.188-.557-.683-.868-1.422-.876-.718-.006-1.435-.015-2.152.005-.31.008-.463-.07-.552-.376-.192-.662-.432-1.31-.656-1.963-.23-.67-.683-1.029-1.3-1.035-.63-.008-1.097.359-1.333 1.048-.23.678-.464 1.356-.68 2.04-.068.212-.179.293-.412.284-.4-.017-.8-.005-1.2-.005h-.001ZM.102 10.786c.08-1.084.042-2.157.256-3.182.529-2.53 2.958-4.376 5.647-4.414.687-.01 1.375 0 2.117 0 0 .666-.01 1.31.003 1.953.03 1.385 1.2 2.487 2.63 2.492 1.435.006 2.62-1.087 2.658-2.467.018-.645.003-1.292.003-1.96h7.95c0 .62-.006 1.249.001 1.877.011.849.362 1.547 1.077 2.046.854.596 1.789.683 2.734.237.93-.439 1.428-1.19 1.476-2.184.032-.646.006-1.295.006-1.938 3.572-.438 6.862.679 7.798 4.511.235.962.146 1.996.208 3.03H.102Z"
    />
    <path
      fill="#fff"
      d="M12.093 3.504c0 .516.007 1.033 0 1.549a1.301 1.301 0 0 1-1.315 1.308c-.736.005-1.322-.553-1.329-1.293-.01-1.045-.01-2.09 0-3.137.008-.742.592-1.294 1.333-1.288.72.005 1.293.554 1.31 1.271.012.53.002 1.06.002 1.589ZM25.338 3.52c0 .515.008 1.033-.002 1.548a1.3 1.3 0 0 1-1.33 1.293 1.298 1.298 0 0 1-1.314-1.308 179.705 179.705 0 0 1 0-3.098c.007-.75.57-1.306 1.308-1.312.753-.007 1.326.558 1.337 1.328.007.516.002 1.033.002 1.549Z"
    />
  </svg>
);
export default SvgComponent;
