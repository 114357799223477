import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useGetRewardsList from "../../../hooks/queries/community/rewards/state/useGetRewards";
import RewardsItem from "./RewardsItem";

const Rewards = () => {
  const {
    data: rewardsList,
    isLoading: rewardsListLoading,
    isError,
  } = useGetRewardsList();

  if (rewardsListLoading || rewardsList?.length === 0 || isError) return null;

  return (
    <Box px={{ xs: 1.5, md: 3 }} py={3}>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        Campaigns
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1.5, md: 3 }}>
        {rewardsList?.map((reward) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={reward?._id}>
              <RewardsItem reward={reward} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Rewards;
