import { useSnackbar } from "notistack";
import { BoosterPlan, GetBoostableTokensResponse } from "../../../../../types";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";
import useGetSubscriptions from "./useGetSubscriptions";
import { useWallet } from "@solana/wallet-adapter-react";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { useQuery } from "@tanstack/react-query";

const useEligableToBoostList = (planId?: BoosterPlan["AStakingProgramId"]) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: communityInfo } = useGetCommunityInfo();
  const { data: subscriptionsInfo } = useGetSubscriptions();
  const { publicKey } = useWallet();
  const path = "boosters/state";
  const fbFnBoostersList = useHttpsCallable(path);

  const getList = async () => {
    const list = subscriptionsInfo?.stakedTokens;
    const { data } = await fbFnBoostersList({
      accountId: communityInfo?.accountId,
      communityId: communityInfo?.communityId,
      contractId: planId,
      tokenDocs: list,
    });
    const availableNfts = data as GetBoostableTokensResponse;
    if (!availableNfts?.success) {
      throw new Error(
        `${availableNfts?.error?.title}\n${availableNfts?.error?.description}`
      );
    }
    return list?.length
      ? list.filter(
          (i) =>
            i?.mintAddress &&
            availableNfts?.boostableTokens.includes(i?.mintAddress)
        )
      : [];
  };

  return useQuery(
    [
      path,
      communityInfo?.accountId,
      communityInfo?.communityId,
      planId,
      subscriptionsInfo?.stakedTokens,
    ],
    getList,
    {
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
      enabled:
        !!publicKey &&
        !!communityInfo?.accountId &&
        !!communityInfo?.communityId &&
        !!planId &&
        !!subscriptionsInfo?.stakedTokens,
      refetchOnMount: false,
    }
  );
};

export default useEligableToBoostList;
