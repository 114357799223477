import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { MarketplaceServiceActivityProps } from "../types";
import {
  MarketplaceActivityItem,
  MarketplaceActivityResponse,
} from "../../../types";
import useWebSocket from "react-use-websocket";
import { WS_URLS } from "../../../constants";
import { timeSince } from "../../../utils";
import { useMarketplace } from "./Main";
import useLocalStorage from "../../../hooks/useLocalStorage";

const LIMIT = 20;

const getActivity = async (
  payload: {
    skip: number;
    limit: number;
    marketplaceId: string;
    logTypes?: string[];
  },
  signal?: AbortSignal
) => {
  const response = await fetch(
    "https://us-central1-nft-anybodies.cloudfunctions.net/marketplace/v1/api/analytics/activity",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      signal,
      body: JSON.stringify({
        data: payload,
      }),
    }
  );
  const { data }: { data: MarketplaceActivityResponse } = await response.json();
  if (!data?.success) {
    throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
  }
  return data?.data;
};

const ServiceContext = createContext<MarketplaceServiceActivityProps>(
  {} as MarketplaceServiceActivityProps
);

export function useMarketplaceActivity() {
  return useContext(ServiceContext);
}

export const MarketplaceActivityService = ({ children }: PropsWithChildren) => {
  const didUnmount = useRef(false);
  const { handleToggleActivity, marketplaceId } = useMarketplace();
  const [activityFilter, setActivityFilter] = useLocalStorage(
    "activityFilter",
    {
      logTypes: ["list", "delist", "buy", "reprice"],
    }
  );
  const [activityLoading, setActivityLoading] = useState(true);
  const [activityList, setActivityList] = useState<
    MarketplaceServiceActivityProps["activityList"]
  >([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [nextPageLoadingLimit, setNextPageLoadingLimit] = useState(0);

  useWebSocket(`${WS_URLS.marketplace + marketplaceId}/logs`, {
    onMessage: (event: WebSocketEventMap["message"]) => {
      const messageData = JSON.parse(event.data) as MarketplaceActivityItem;
      if (!messageData) {
        return;
      }

      addActivity(messageData);
    },
    onOpen: () => {
      console.info("WebSocket connection established.");
    },
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => didUnmount.current === false,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  const activityNextPage = useCallback(
    async (limit: number = LIMIT) => {
      if (
        !marketplaceId ||
        activityLoading ||
        !hasNextPage ||
        !!nextPageLoadingLimit
      )
        return;
      setNextPageLoadingLimit(limit);
      const data = await getActivity({
        marketplaceId,
        skip: activityList.length,
        limit,
        ...activityFilter,
      });
      setHasNextPage(data?.length === limit);
      setActivityList((currentState) => {
        return [...currentState, ...data];
      });
      setNextPageLoadingLimit(0);
    },
    [
      activityFilter,
      activityList.length,
      activityLoading,
      hasNextPage,
      marketplaceId,
      nextPageLoadingLimit,
    ]
  );

  const addActivity = (data: MarketplaceActivityItem) => {
    if (!activityFilter.logTypes.includes(data.type)) return;
    setActivityList((currentState) => [data, ...currentState]);
  };

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActivityList((currentState) =>
        currentState.map((i) => {
          if (typeof i?.created === "undefined") return i;
          const timePassed = timeSince(i?.created);
          return { ...i, timePassed };
        })
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [activityList]);

  useEffect(() => {
    if (!marketplaceId || !activityLoading) return;
    const abortController = new AbortController();
    const signal = abortController.signal;
    (async () => {
      const data = await getActivity(
        {
          marketplaceId,
          skip: 0,
          limit: LIMIT,
          ...activityFilter,
        },
        signal
      );
      setHasNextPage(data?.length === LIMIT);
      setActivityList(data);
      setActivityLoading(false);
    })();

    return () => {
      abortController.abort();
    };
  }, [activityFilter, activityLoading, marketplaceId]);

  return (
    <ServiceContext.Provider
      value={{
        handleToggleActivity,
        activityLoading,
        setActivityLoading,
        activityList,
        activityNextPage,
        hasNextPage,
        nextPageLoadingLimit,
        activityFilter,
        setActivityFilter,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
