import { Swiper, SwiperSlide } from "swiper/react";
import "./Carousel.scss";
import { Autoplay, Pagination } from "swiper";
import Box from "@mui/material/Box";
import { useRef, useState } from "react";
import { SwiperSlideInner } from "../../../components/StyledComponents";
import prntBg from "../../../assets/banners/prnt/bg.jpg";
import prnt from "../../../assets/banners/prnt/logo.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const CAROUSEL_ITEMS = [
  {
    background: prntBg,
    logo: prnt,
    link: "/announcement/prnt",
  },
];

const Carousel = () => {
  const navigate = useNavigate();
  const progressCircleRef = useRef<SVGSVGElement>(null);
  const [time, setTime] = useState(0);

  const onAutoplayTimeLeft = (s: any, t: number, p: number) => {
    if (!progressCircleRef) return;
    progressCircleRef?.current?.style?.setProperty(
      "--progress",
      (1 - p).toString()
    );
    if (time !== t) {
      setTime(Math.ceil(t / 1000));
    }
  };

  return (
    <Box
      borderRadius={{
        xs: 0,
        md: 3,
      }}
      overflow="hidden"
    >
      <Swiper
        className="landing-carousel"
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        grabCursor
        spaceBetween={16}
        autoplay={{ delay: 7000, disableOnInteraction: false }}
        onAutoplayTimeLeft={
          CAROUSEL_ITEMS.length > 1 ? onAutoplayTimeLeft : undefined
        }
        loop
      >
        {CAROUSEL_ITEMS.map((item, index) => (
          <SwiperSlide key={index}>
            <SwiperSlideInner
              sx={{
                height: { xs: "35dvh", sm: "40dvh", md: "50dvh", lg: "60dvh" },
                backgroundImage: `url(${item.background})`,
              }}
            >
              <Box
                maxWidth="100%"
                height="50%"
                margin="auto"
                px={{ lg: 5, md: 3, xs: 1.5 }}
                sx={{
                  "& img": { maxHeight: "100%" },
                }}
              >
                <img src={item.logo} alt="Anybodies x PRNT" />
              </Box>
              <Box textAlign="center">
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    navigate("/announcement/prnt");
                  }}
                  size="large"
                  sx={{
                    width: 240,
                    fontWeight: 900,
                    textTransform: "uppercase",
                  }}
                >
                  Read more
                </Button>
              </Box>
            </SwiperSlideInner>
          </SwiperSlide>
        ))}
        {CAROUSEL_ITEMS.length > 1 && (
          <div className="autoplay-progress" slot="container-end">
            <svg ref={progressCircleRef} viewBox="0 0 48 48">
              <circle cx="24" cy="24" r="20" />
            </svg>
            <span>{time}s</span>
          </div>
        )}
      </Swiper>
    </Box>
  );
};

export default Carousel;
