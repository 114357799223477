import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  FilterMobileButtons,
  StyledFilterHeader,
  StyledFilterToggleButtonGroup,
  StyledMarketplaceToolbar,
  StyledMarketplaceToolbarInner,
} from "../../../components/StyledComponents";
import { useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import ToggleButton from "@mui/material/ToggleButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { useWallet } from "@solana/wallet-adapter-react";
import { OrderBy, OwnerFilter } from "../types";
import useGetMarketplaceDetails from "../../../hooks/queries/marketplace/state/useGetMarketplaceDetails";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  OWNER_TYPES,
  SORT_BY,
  useExploreMarketplace,
} from "../services/Explore";
import { useMarketplace } from "../services/Main";
import Loader from "../../../components/Loader/Loader";

const MarketplaceFilter = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const { filterOpen, handleToggleFilter, marketplaceId } = useMarketplace();
  const { changeFilter, filter, clearFilters } = useExploreMarketplace();
  const { data: marketplaceDetails, isLoading: marketplaceDetailsLoading } =
    useGetMarketplaceDetails(marketplaceId);
  const { publicKey } = useWallet();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const selectSortMenuOpen = Boolean(anchorEl);

  const handleOrderButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOrderItemClick = (
    event: React.MouseEvent<HTMLElement>,
    value: OrderBy
  ) => {
    changeFilter("orderBy", value);
    setAnchorEl(null);
  };

  const handleChangeListType = (
    event: React.MouseEvent<HTMLElement>,
    value: OwnerFilter
  ) => {
    if (!value) return;
    if (value === OwnerFilter.Self) {
      if (!publicKey) {
        enqueueSnackbar({
          variant: "info",
          message: "You must be logged in to use this filter",
        });
        return;
      }
      changeFilter("sellerAddresses", [publicKey?.toString()]);
    } else {
      changeFilter("sellerAddresses", []);
    }
  };

  const handleAttributeChange = (attrName: string, value: string[]) => {
    if (value.length === 0) {
      const copyState = JSON.parse(
        JSON.stringify(filter.filterByTokenAttribute)
      );
      delete copyState?.[attrName];
      changeFilter("filterByTokenAttribute", copyState);
      return;
    }
    changeFilter("filterByTokenAttribute", {
      ...filter.filterByTokenAttribute,
      [attrName]: value,
    });
  };

  const selectedSortLabel = useMemo(
    () =>
      SORT_BY.find(
        ({ value }) =>
          value.field === filter?.orderBy?.field &&
          value.order === filter?.orderBy?.order
      )?.label,
    [filter?.orderBy?.field, filter?.orderBy?.order]
  );

  const sortedAttributes = useMemo(
    () =>
      Object.keys(marketplaceDetails?.attributeMap || {})?.sort((a, b) =>
        a.localeCompare(b)
      ),
    [marketplaceDetails?.attributeMap]
  );

  return (
    <StyledMarketplaceToolbar type="filter" open={filterOpen}>
      <StyledFilterHeader>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6" fontWeight={900}>
            Filters
          </Typography>
          {Object.keys(filter.filterByTokenAttribute)?.length > 0 &&
            !isMobile && (
              <Chip
                label="Clear"
                size="small"
                color="error"
                onClick={clearFilters}
              />
            )}
        </Stack>
        <IconButton size="small" onClick={handleToggleFilter}>
          <CloseRoundedIcon />
        </IconButton>
      </StyledFilterHeader>
      <StyledMarketplaceToolbarInner>
        <Stack spacing={2}>
          {!!publicKey && (
            <Box>
              <StyledFilterToggleButtonGroup
                value={
                  filter?.sellerAddresses?.length > 0
                    ? OwnerFilter.Self
                    : OwnerFilter.All
                }
                exclusive
                fullWidth
                size="small"
                onChange={handleChangeListType}
              >
                {OWNER_TYPES.map(({ label, value }) => (
                  <ToggleButton value={value} fullWidth key={value}>
                    {label}
                  </ToggleButton>
                ))}
              </StyledFilterToggleButtonGroup>
            </Box>
          )}
          <Box>
            <Typography fontWeight="bold" mb={1}>
              Sort by
            </Typography>
            <div>
              <Button
                endIcon={<ImportExportOutlinedIcon />}
                fullWidth
                variant="base"
                color="secondary"
                onClick={handleOrderButtonClick}
              >
                {selectedSortLabel}
              </Button>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={selectSortMenuOpen}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                {SORT_BY.map((option) => (
                  <MenuItem
                    key={option.label}
                    selected={option.label === selectedSortLabel}
                    onClick={(event) =>
                      handleOrderItemClick(event, option.value)
                    }
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Box>
          {marketplaceDetailsLoading ? (
            <Stack alignItems="center" justifyContent="center" height="100%">
              <Loader size={32} />
            </Stack>
          ) : (
            sortedAttributes?.length > 0 && (
              <Box>
                <Typography fontWeight={700}>Attributes</Typography>
                {!!marketplaceDetails?.attributeMap &&
                  sortedAttributes?.map((attrName) => {
                    const attrInfo = marketplaceDetails?.attributeMap?.[
                      attrName
                    ]
                      ?.sort((a, b) => a.value.localeCompare(b.value))
                      ?.map((i) => i.value);

                    return (
                      <Autocomplete
                        key={attrName}
                        value={filter.filterByTokenAttribute?.[attrName] || []}
                        filterSelectedOptions
                        options={attrInfo || []}
                        onChange={(_, value) =>
                          handleAttributeChange(
                            attrName,
                            value.map((i) => i)
                          )
                        }
                        multiple
                        disablePortal
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={attrName}
                            size="small"
                            margin="dense"
                          />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option}>
                              {option}
                            </li>
                          );
                        }}
                        renderTags={(tagValue, getTagProps) => {
                          return tagValue.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              key={option}
                              label={option}
                              size="small"
                            />
                          ));
                        }}
                      />
                    );
                  })}
              </Box>
            )
          )}
        </Stack>
      </StyledMarketplaceToolbarInner>
      <FilterMobileButtons direction="row" spacing={1}>
        <Button
          fullWidth
          variant="text"
          color="inherit"
          disabled={Object.keys(filter.filterByTokenAttribute)?.length === 0}
          onClick={clearFilters}
        >
          Clear
        </Button>
        <Button color="secondary" fullWidth onClick={handleToggleFilter}>
          Done
        </Button>
      </FilterMobileButtons>
    </StyledMarketplaceToolbar>
  );
};

export default MarketplaceFilter;
