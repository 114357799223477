import Typography from "@mui/material/Typography";
import { ManifestAttribute } from "../../../types";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { StyledMarketplaceNftInfoDialogAttribute } from "../../../components/StyledComponents";

const Attributes = ({ attributes }: { attributes?: ManifestAttribute[] }) => {
  return (
    <>
      <Typography fontWeight={700}>Attributes</Typography>
      <Divider sx={{ mb: 1, mt: 0.5 }} />
      <Stack direction="row" flexWrap="wrap" gap={1} mb={3}>
        {attributes?.map(({ trait_type, value }) => (
          <StyledMarketplaceNftInfoDialogAttribute key={trait_type}>
            <Typography
              variant="overline"
              color="text.secondary"
              lineHeight={1}
              component="div"
              mb={1}
            >
              {trait_type}
            </Typography>
            <Typography fontWeight={600} lineHeight={1}>
              {value}
            </Typography>
          </StyledMarketplaceNftInfoDialogAttribute>
        ))}
      </Stack>
    </>
  );
};

export default Attributes;
