import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Unlock from "../../../components/images/Unlock";
import TrendingExperiencesVideoplayer from "./TrendingExperiencesVideoplayer";
import { StyledUnlockYourBrandExperience } from "../../../components/StyledComponents";
import { TRENDING_EXPERIENCES } from "../../../constants";

const TrendingExperiences = () => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        sx={{ px: { xs: 1.5, md: 3 } }}
      >
        <Typography variant="h6" fontWeight="bold">
          Trending Experiences
        </Typography>
      </Stack>
      <Box sx={{ paddingX: { xs: 1.5, md: 3 } }}>
        <Grid container columnSpacing={{ xs: 2.5, md: 5 }} rowSpacing={3}>
          {TRENDING_EXPERIENCES.map(({ title, description, src, thumb }, i) => (
            <Grid item xs={12} sm={6} lg={3} key={i}>
              <TrendingExperiencesVideoplayer src={src} thumb={thumb} />
              <Typography variant="h6" fontWeight={800} mt={1}>
                {title}
              </Typography>
              <Typography fontWeight={500}>{description}</Typography>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} lg={3}>
            <StyledUnlockYourBrandExperience>
              <Box textAlign="center" mb={3}>
                <Unlock width={78} />
                <Typography
                  variant="h6"
                  textAlign="center"
                  fontWeight="bold"
                  mt={2}
                >
                  Unlock your brand's experiences
                </Typography>
              </Box>
              <Button
                fullWidth
                size="large"
                sx={{
                  textTransform: "uppercase",
                }}
                onClick={() => {
                  window.open("https://forms.gle/2feKsqEan6RAHM6N7", "_blank");
                }}
              >
                Apply
              </Button>
            </StyledUnlockYourBrandExperience>
            <Typography
              variant="h6"
              fontWeight={800}
              mt={1}
              display={{ xs: "none", sm: "block" }}
            >
              Experience the power of web-3
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TrendingExperiences;
