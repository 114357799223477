import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { CheckEligibilityResponse } from "../../../../../types";
import { useWallet } from "@solana/wallet-adapter-react";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";

const useCheckRewardEligibility = (campaignId: string | undefined) => {
  const path = "rewards/check-eligibility";
  const { communityName } = useParams();
  const { data: communityInfo } = useGetCommunityInfo();
  const { publicKey } = useWallet();
  const fn = useHttpsCallable(path);

  const checkEligibility = async () => {
    const { data } = await fn({
      accountId: communityInfo?.accountId,
      communityId: communityInfo?.communityId,
      campaignId,
      walletAddress: publicKey?.toString(),
    });
    return data as CheckEligibilityResponse;
  };

  return useQuery(
    [path, communityName, publicKey?.toString()],
    checkEligibility,
    {
      staleTime: 30 * 1000, // 30 sec
      enabled:
        !!campaignId &&
        !!communityInfo?.accountId &&
        !!communityInfo?.communityId &&
        !!publicKey,
    }
  );
};

export default useCheckRewardEligibility;
