import Stack from "@mui/material/Stack";
import {
  StyledMarketplaceNftCardImage,
  StyledMarketplaceNftCardImageHoverContainer,
  StyledMarketplaceNftCardImageInfo,
  StyledMarketplaceNftCard,
} from "../../../components/StyledComponents";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MarketplaceNftData } from "../../../types";
import { useWallet } from "@solana/wallet-adapter-react";
import useDelist from "../../../hooks/queries/marketplace/mutations/useDelist";
import CircularProgress from "@mui/material/CircularProgress";
import useBuy from "../../../hooks/queries/marketplace/mutations/useBuy";
import SolanaLogo from "../../../components/images/SolanaLogo";
import { extractNftNumber, getBuyerPrice } from "../../../utils";
import { useMarketplace } from "../services/Main";
import { useSnackbar } from "notistack";
import { alpha } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import useGetMarketplaceDetails from "../../../hooks/queries/marketplace/state/useGetMarketplaceDetails";
import Skeleton from "@mui/material/Skeleton";
import { useMemo } from "react";

const MarketplaceNftListItem = ({ data }: { data: MarketplaceNftData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { publicKey } = useWallet();
  const { showNftInfoModal, marketplaceId } = useMarketplace();
  const { mutateAsync: delist, isLoading: delistLoading } =
    useDelist(marketplaceId);
  const { mutateAsync: buy, isLoading: buyLoading } = useBuy(marketplaceId);
  const { data: marketplaceDetails } = useGetMarketplaceDetails(marketplaceId);

  const handleShowInfoModal = () => {
    showNftInfoModal(data);
  };

  const handleDelist = async () => {
    if (!data?.listingInfo?.listingId) return;
    delist(data);
  };

  const handleBuy = async () => {
    if (!data?.listingInfo?.listingId) return;
    if (!publicKey) {
      enqueueSnackbar({
        variant: "info",
        message: "Login to gain access",
      });
      return;
    }
    buy(data);
  };

  const isLoading = delistLoading || buyLoading;
  const isUserListing = !!(
    publicKey && publicKey?.toString() === data?.listingInfo?.sellerAddress
  );
  const buyerPrice = useMemo(() => {
    if (
      typeof data?.listingInfo?.price === "undefined" ||
      typeof marketplaceDetails?.marketplaceFee === "undefined" ||
      typeof data?.royalty?.basis_points === "undefined"
    ) {
      return undefined;
    }
    return getBuyerPrice(
      data?.listingInfo?.price,
      marketplaceDetails?.marketplaceFee,
      data?.royalty?.basis_points / 100
    );
  }, [
    data?.listingInfo?.price,
    data?.royalty?.basis_points,
    marketplaceDetails?.marketplaceFee,
  ]);

  return (
    <StyledMarketplaceNftCard
      className={isLoading ? "loading" : ""}
      isUserListing={isUserListing}
    >
      <StyledMarketplaceNftCardImage className="image">
        <Avatar
          variant="square"
          src={data?.links?.image}
          alt={data?.name}
          sx={{ width: "100%", height: "100%" }}
        >
          <InsertPhotoRoundedIcon sx={{ width: "75%", height: "75%" }} />
        </Avatar>
        <StyledMarketplaceNftCardImageHoverContainer className="info">
          {data?.listingInfo ? (
            isUserListing ? (
              <Button
                color="error"
                disabled={delistLoading}
                disableElevation={false}
                size="small"
                sx={{
                  textTransform: "uppercase",
                  "&.Mui-disabled": {
                    backgroundColor: "#9d9d9d",
                    color: "rgba(0, 0, 0, 0.6)",
                  },
                }}
                onClick={handleDelist}
              >
                {delistLoading && (
                  <CircularProgress color="inherit" size="1em" sx={{ mr: 1 }} />
                )}
                <span>{delistLoading ? "Delisting..." : "Delist"}</span>
              </Button>
            ) : (
              <Button
                color="secondary"
                disabled={buyLoading}
                disableElevation={false}
                size="small"
                sx={{
                  textTransform: "uppercase",
                  "&.Mui-disabled": {
                    backgroundColor: "#9d9d9d",
                    color: "rgba(0, 0, 0, 0.6)",
                  },
                }}
                onClick={handleBuy}
              >
                {buyLoading && (
                  <CircularProgress color="inherit" size="1em" sx={{ mr: 1 }} />
                )}
                <span>{buyLoading ? "Buying..." : "Buy now"}</span>
              </Button>
            )
          ) : (
            <Button
              color="success"
              disableElevation={false}
              size="small"
              sx={{
                textTransform: "uppercase",
              }}
              onClick={handleShowInfoModal}
            >
              <span>List now</span>
            </Button>
          )}
        </StyledMarketplaceNftCardImageHoverContainer>
        <StyledMarketplaceNftCardImageInfo>
          {data?.tokenRankDetails?.rank && (
            <Typography variant="body2">
              {`⍜ ${data?.tokenRankDetails?.rank}`}
            </Typography>
          )}
          <Typography variant="body2">
            {extractNftNumber(data?.name)}
          </Typography>
        </StyledMarketplaceNftCardImageInfo>
      </StyledMarketplaceNftCardImage>
      {data?.listingInfo && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          mt={0.5}
        >
          <Stack direction="row" alignItems="center" spacing={0.75} ml={0.5}>
            <SolanaLogo fontSize={12} />
            <Typography>
              {typeof buyerPrice === "undefined" ? (
                <Skeleton width={60} variant="text" />
              ) : (
                buyerPrice?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 4,
                })
              )}
            </Typography>
          </Stack>
          <IconButton
            size="small"
            sx={{
              color: (theme) =>
                isUserListing
                  ? alpha(theme.palette.primary.main, 0.75)
                  : "text.secondary",
            }}
            onClick={handleShowInfoModal}
          >
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Stack>
      )}
    </StyledMarketplaceNftCard>
  );
};

export default MarketplaceNftListItem;
