import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import {
  StyledGridTable,
  StyledGridTableCell,
} from "../../../../components/StyledComponents";
import ClaimedRewardsHead from "./ClaimedRewardsHead";

const ClaimedRewardsSkeleton = () => {
  return (
    <Box sx={{ overflowX: "auto" }} mt={1.5}>
      <StyledGridTable
        sx={{
          gridTemplateColumns: "minmax(130px, 1fr) repeat(3, auto)",
          minWidth: "500px",
        }}
      >
        <ClaimedRewardsHead />
        <Box
          sx={{
            display: "contents",
          }}
        >
          <StyledGridTableCell
            sx={{
              justifyContent: "flex-start",
              borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
              borderBottomLeftRadius: (theme) =>
                `${theme.shape.borderRadius}px`,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              maxWidth="100%"
            >
              <Skeleton width={30} height={30} />
              <Box textAlign="left" maxWidth={`calc(100% - 38px)`}>
                <Skeleton width={100} height={16} sx={{ mb: 0.75 }} />
                <Skeleton width={40} height={14} />
              </Box>
            </Stack>
          </StyledGridTableCell>
          <StyledGridTableCell>
            <Skeleton width={60} />
          </StyledGridTableCell>
          <StyledGridTableCell>
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton width={60} />
            </Stack>
          </StyledGridTableCell>
          <StyledGridTableCell
            sx={{
              borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
              borderBottomRightRadius: (theme) =>
                `${theme.shape.borderRadius}px`,
            }}
          >
            <Skeleton width={82} height={30} />
          </StyledGridTableCell>
        </Box>
      </StyledGridTable>
    </Box>
  );
};

export default ClaimedRewardsSkeleton;
