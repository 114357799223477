import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModalTransition from "../../../helpers/ModalTransition";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Attributes from "./Attributes";
import Details from "./Details";
import MarketplaceDialogActionBox from "./MarketplaceDialogActionBox";
import { useMarketplace } from "../services/Main";

const MarketplaceNftInfoDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    nftInfoModalOpen,
    hideNftInfoModal,
    nftInfoModalData,
    nftModalSubmitLoading,
  } = useMarketplace();

  return (
    <Dialog
      open={nftInfoModalOpen}
      onClose={nftModalSubmitLoading ? undefined : hideNftInfoModal}
      keepMounted
      fullScreen={fullScreen}
      TransitionComponent={ModalTransition}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: 1000,
        },
      }}
    >
      <DialogTitle component="div" py={{ xs: 1, sm: 2 }} px={{ xs: 1, sm: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6" fontWeight={900}>
              {nftInfoModalData?.name}
            </Typography>
            {nftInfoModalData?.tokenRankDetails?.rank && (
              // <Tooltip placement="top" title="Top 35% rarity">
              <Chip
                label={`⍜ ${nftInfoModalData?.tokenRankDetails?.rank}`}
                size="small"
                // component="a"
                // href="https://moonrank.app/collection/toys_r_us/GXdXAjbttcwdqdj4UUuXQvx9DusRM1GmccS9p63ZWube"
                // target="_blank"
                // clickable
              />
              // </Tooltip>
            )}
          </Stack>
          <IconButton
            disabled={nftModalSubmitLoading}
            onClick={hideNftInfoModal}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pb: { xs: 1, sm: 3 }, px: { xs: 1, sm: 3 } }}>
        <Grid container spacing={{ xs: 1.5, sm: 3 }}>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
                aspectRatio: "1/1",
                img: {
                  width: "100%",
                },
              }}
            >
              <img
                src={nftInfoModalData?.links?.image}
                alt={nftInfoModalData?.name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <MarketplaceDialogActionBox />
            <Attributes attributes={nftInfoModalData?.attributes} />
            <Details
              mintAddress={nftInfoModalData?.mintAddress}
              owner={
                nftInfoModalData?.ownership?.owner ||
                nftInfoModalData?.listingInfo?.sellerAddress
              }
              tokenStandard={nftInfoModalData?.token_standard}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MarketplaceNftInfoDialog;
