import { useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import useList from "../../../../hooks/queries/marketplace/mutations/useList";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SolanaLogo from "../../../../components/images/SolanaLogo";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { useMarketplace } from "../../services/Main";
import useGetMarketplaceDetails from "../../../../hooks/queries/marketplace/state/useGetMarketplaceDetails";
import Link from "@mui/material/Link";
import { getBuyerPrice, percentageChange } from "../../../../utils";
import Divider from "@mui/material/Divider";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Tooltip from "@mui/material/Tooltip";

type Inputs = {
  price: number | null;
};

const ListSettings = ({
  isEdit,
  setIsEdit,
}: {
  isEdit?: boolean;
  setIsEdit?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    nftModalSubmitLoading,
    hideNftInfoModal,
    nftInfoModalOpen,
    nftInfoModalData,
    marketplaceId,
    setNftModalSubmitLoading,
  } = useMarketplace();
  const { data: marketplaceDetails } = useGetMarketplaceDetails(marketplaceId);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm<Inputs>({
    defaultValues: { price: nftInfoModalData?.listingInfo?.price },
  });
  const { mutateAsync: list, isLoading: listLoading } = useList(marketplaceId);
  const listPrice = watch("price");

  const onSubmit = async (data: Inputs) => {
    if (!nftInfoModalData || !data.price) return;
    await list({
      mintAddress: nftInfoModalData?.mintAddress,
      solAmount: data.price,
    });
    enqueueSnackbar({
      variant: "success",
      message: isEdit
        ? "Price was updated successfully!"
        : "Listing completed successfully!",
    });
    hideNftInfoModal();
    setValue("price", null);
  };

  useEffect(() => {
    reset({
      price:
        nftInfoModalData?.listingInfo?.price ||
        marketplaceDetails?.floorInfo?.floorPrice,
    });
  }, [
    nftInfoModalOpen,
    marketplaceDetails?.floorInfo?.floorPrice,
    nftInfoModalData?.listingInfo?.price,
    reset,
  ]);

  useEffect(() => {
    setNftModalSubmitLoading(listLoading);
  }, [listLoading, setNftModalSubmitLoading]);

  const buyerPrice = useMemo(() => {
    if (
      !listPrice ||
      typeof nftInfoModalData?.royalty?.basis_points === "undefined" ||
      marketplaceDetails?.marketplaceFee === undefined
    ) {
      return 0;
    }
    return getBuyerPrice(
      listPrice,
      marketplaceDetails?.marketplaceFee,
      nftInfoModalData?.royalty?.basis_points / 100
    );
  }, [
    listPrice,
    marketplaceDetails?.marketplaceFee,
    nftInfoModalData?.royalty?.basis_points,
  ]);

  const floorDiff = useMemo(() => {
    if (
      !listPrice ||
      typeof marketplaceDetails?.floorInfo?.floorPrice === "undefined"
    ) {
      return 0;
    }
    return percentageChange(
      marketplaceDetails?.floorInfo?.floorPrice,
      listPrice
    );
  }, [listPrice, marketplaceDetails?.floorInfo?.floorPrice]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Divider sx={{ my: 1.5 }} />
      {isEdit && (
        <Typography fontWeight={700} mb={1}>
          Update price
        </Typography>
      )}
      <Box mb={2}>
        <TextField
          {...register("price", {
            valueAsNumber: true,
            min: {
              value: 0.001,
              message: "Minimum is 0.001",
            },
          })}
          inputProps={{ step: 0.001 }}
          error={!!errors.price}
          helperText={!!errors.price && errors.price.message}
          type="number"
          required
          fullWidth
          label={isEdit ? "New list price" : "List price"}
          margin="none"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SolanaLogo fontSize={20} />
              </InputAdornment>
            ),
            endAdornment:
              floorDiff < -10 ? (
                <InputAdornment position="end">
                  <Tooltip
                    title={`Your list price is ${Math.abs(
                      floorDiff
                    ).toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}% below floor (${
                      marketplaceDetails?.floorInfo?.floorPrice
                    })`}
                    arrow
                    leaveDelay={500}
                  >
                    <WarningRoundedIcon color="warning" />
                  </Tooltip>
                </InputAdornment>
              ) : null,
          }}
          variant="filled"
          disabled={listLoading}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={1.25}
        >
          <Typography variant="body2" color="text.secondary">
            Maker Fee
          </Typography>
          <Typography
            fontWeight={700}
            color={
              marketplaceDetails?.marketplaceFee === 0
                ? "success.main"
                : "inherit"
            }
          >
            {marketplaceDetails?.marketplaceFee === 0 ? (
              "Free"
            ) : (
              <>
                {marketplaceDetails?.marketplaceFee?.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
                %
              </>
            )}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2" color="text.secondary">
            Buyer sees
          </Typography>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography fontWeight={700}>
              {buyerPrice
                ? buyerPrice?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                  })
                : 0}
            </Typography>
            <SolanaLogo fontSize={14} />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2" color="text.secondary">
            You receive
          </Typography>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography fontWeight={700}>
              {listPrice
                ? listPrice?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  })
                : 0}
            </Typography>
            <SolanaLogo fontSize={14} />
          </Stack>
        </Stack>
      </Box>
      <Stack direction="row" spacing={1}>
        {isEdit && (
          <Button
            variant="base"
            color="secondary"
            fullWidth
            disabled={nftModalSubmitLoading}
            onClick={() => {
              if (!setIsEdit) return;
              setIsEdit(false);
            }}
          >
            Cancel
          </Button>
        )}
        <LoadingButton
          variant="gradient"
          fullWidth
          type="submit"
          loading={nftModalSubmitLoading}
        >
          {isEdit ? "Update" : "List now"}
        </LoadingButton>
      </Stack>
      <Typography
        variant="caption"
        color="text.secondary"
        component="div"
        lineHeight={1.2}
        mt={1}
      >
        By clicking "{isEdit ? "Update" : "List now"}", you agree to the
        Anybodies{" "}
        <Link href="#" color="inherit">
          Terms of services
        </Link>
        .
      </Typography>
    </form>
  );
};

export default ListSettings;
