import Stack from "@mui/material/Stack";
import useGetMarketplaceWalletProfile from "../../../hooks/queries/marketplace/state/useGetMarketplaceWalletProfile";
import Loader from "../../../components/Loader/Loader";
import Typography from "@mui/material/Typography";
import { StyledMarketplaceNftListBox } from "../../../components/StyledComponents";
import MarketplaceNftListItem from "./MarketplaceNftListItem";
import Empty from "../../../components/Empty/Empty";
import { useMarketplace } from "../services/Main";

const ListMode = () => {
  const { viewMaxWidth, marketplaceId } = useMarketplace();
  const { data: walletProfile, isLoading: walletProfileLoading } =
    useGetMarketplaceWalletProfile(marketplaceId);

  if (walletProfileLoading) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        height="100%"
        spacing={1}
      >
        <Loader size={40} />
        <Typography fontWeight={900}>Loading..</Typography>
      </Stack>
    );
  }

  if (!walletProfile?.length) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        height="100%"
        spacing={1}
      >
        <Empty text="Nothing to show" imgWidth={500} />
      </Stack>
    );
  }

  return (
    <StyledMarketplaceNftListBox maxItemWidth={viewMaxWidth}>
      {walletProfile?.map((nft) => (
        <MarketplaceNftListItem data={nft} key={nft?.mintAddress} />
      ))}
    </StyledMarketplaceNftListBox>
  );
};

export default ListMode;
