import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Discord from "../../components/images/Discord";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { verifyDiscordRedirect } from "../../utils";

const VerifyDiscord = () => {
  return (
    <Box pt={2} px={{ xs: 1.5, md: 3 }}>
      <Divider sx={{ mb: 2 }} />
      <Box
        sx={{
          backgroundColor: "#5864f2",
          p: { xs: 1, sm: 2 },
          borderRadius: "5px",
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          gap={{ xs: 1, sm: 2 }}
        >
          <Box flexShrink={0} width={48} height={48}>
            <Discord fill="#FFF" />
          </Box>
          <Typography variant="h6" textAlign={{ xs: "center", sm: "left" }}>
            Verify your Discord account to gain access to the roles and rewards
          </Typography>
          <Button
            variant="base"
            color="secondary"
            sx={{ ml: "auto", width: { xs: "100%", sm: "auto" } }}
            onClick={verifyDiscordRedirect}
          >
            Verify
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default VerifyDiscord;
