import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {
  CommunityCard,
  CommunityCardSkeleton,
} from "../components/CommunityCard";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { useHttpsCallable } from "../services/FirebaseService";
import { ExploreCommunitiesResponse } from "../types";
import { useNavigate } from "react-router-dom";

const LIMIT = 12;
const path = "communities/list";

const ExploreCommunities = () => {
  const navigate = useNavigate();
  const fbFn = useHttpsCallable(path);
  const fetchCommunities = async (page: number = 0) => {
    const { data } = await fbFn({ skip: page * LIMIT, limit: LIMIT });
    const res = data as ExploreCommunitiesResponse;
    if (!res?.success) {
      throw new Error(`Error`);
    }
    return {
      nextPage: res?.communities?.length < LIMIT ? false : page + 1,
      response: res?.communities,
    };
  };

  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery([path], (ctx) => fetchCommunities(ctx?.pageParam), {
      getNextPageParam: (lastPage) => lastPage.nextPage,
      staleTime: 30 * 60 * 1000,
    });

  return (
    <Box px={{ xs: 1.5, md: 3 }} mb={3}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Communities
      </Typography>
      <Grid container spacing={2}>
        {data?.pages.map((group, i) => (
          <Fragment key={i}>
            {group?.response?.map((c) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={c?.communityId}>
                <CommunityCard
                  communityName={c?.displayName || c?.communityName}
                  profileImage={c?.profileImage}
                  coverImage={c?.coverImage}
                  onClick={() => {
                    navigate(`/c/${c?.route}`);
                  }}
                />
              </Grid>
            ))}
          </Fragment>
        ))}

        {(isLoading || isFetchingNextPage) &&
          Array.from(Array(LIMIT).keys()).map((i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
              <CommunityCardSkeleton />
            </Grid>
          ))}
      </Grid>
      {hasNextPage && (
        <Stack justifyContent="center" mt={2}>
          <Button
            variant="base"
            color="secondary"
            disabled={isFetchingNextPage}
            onClick={() => {
              fetchNextPage();
            }}
          >
            Show more
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default ExploreCommunities;
