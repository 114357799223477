import { useWallet } from "@solana/wallet-adapter-react";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";
import { useQueryClient } from "@tanstack/react-query";

const useRefetchAllSubscriptions = () => {
  const { data: communityInfo } = useGetCommunityInfo();
  const { publicKey } = useWallet();
  const queryClient = useQueryClient();
  const subscribtionsKey = [
    "subscriptions/list",
    communityInfo?.accountId,
    communityInfo?.communityId,
    publicKey?.toString(),
  ];
  const communitiesListKey = ["communities/by-wallet", publicKey?.toString()];
  return () => {
    queryClient.invalidateQueries({
      queryKey: subscribtionsKey,
      exact: true,
      refetchType: "active",
    });
    queryClient.refetchQueries(subscribtionsKey);
    queryClient.invalidateQueries({
      queryKey: communitiesListKey,
      exact: true,
      refetchType: "active",
    });
    queryClient.refetchQueries(communitiesListKey);
  };
};

export default useRefetchAllSubscriptions;
