import "./styles.scss";

const SmallCoin = () => {
  return (
    <div className="coin-wrap">
      <div className="coin">
        <div className="coin__front-backface" />
        <div className="coin__back-backface" />
        <div className="coin__front">$</div>
        <div className="coin__back">$</div>
      </div>
    </div>
  );
};

export default SmallCoin;
