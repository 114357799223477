import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77 77"
    fill="none"
    {...props}
  >
    <g fill="#fff" clipPath="url(#a)">
      <path d="M23.662 73.742c-3.858-1.609-7.302-3.872-10.379-6.683-6.53-5.97-10.65-13.321-12.023-22.065-1.716-10.925.801-20.87 7.594-29.63C13.776 9.012 20.156 4.702 27.84 2.341c2.514-.774 5.087-1.247 7.706-1.472.163-.014.356.05.464-.15h5.653c.108.198.3.136.464.148a34.15 34.15 0 0 1 5.343.843c9.773 2.395 17.49 7.725 23.103 16.107 1.899 2.834 3.408 5.837 4.412 9.097-.807.21-1.266.899-1.879 1.374-1.026.796-1.835 1.852-2.973 2.516-1.034-3.808-2.603-7.373-4.91-10.585-4.301-5.99-9.95-10.113-17.023-12.285-4.626-1.42-9.333-1.806-14.092-1.068-8.103 1.255-14.918 4.962-20.11 11.338C7.445 26.252 5.22 35.44 7.274 45.632c.581 2.878 1.601 5.602 2.967 8.2 2.693 5.117 6.495 9.203 11.368 12.31 2.012 1.282 4.182 2.239 6.375 3.152-1.14 1.319-2.56 2.337-3.802 3.544-.282.275-.513.507-.517.907l-.004-.002Z" />
      <path d="M23.662 73.742c.004-.4.235-.632.517-.907 1.243-1.207 2.663-2.225 3.803-3.544.262.07.529.13.787.213 8.068 2.585 16.045 2.273 23.65-1.428 11.7-5.697 17.871-15.247 18.689-28.229.193-3.059-.277-6.068-.975-9.044 1.14-.664 1.947-1.72 2.973-2.516.613-.475 1.072-1.163 1.88-1.374.662 2.053 1.223 4.127 1.529 6.271.127.885.081 1.802.413 2.655v5.953c-.332.86-.28 1.782-.417 2.671-1.203 7.815-4.504 14.61-9.89 20.387-3.981 4.272-8.706 7.462-14.165 9.547a37.682 37.682 0 0 1-16.37 2.406c-4.322-.316-8.466-1.297-12.424-3.063v.002Z" />
      <path d="M39.512 43.994c-1.122.78-1.8 1.935-2.752 2.826-.804.752-1.45 1.667-2.2 2.48-1.384 1.502-2.85 2.932-4.172 4.485a126.895 126.895 0 0 1-3.816 4.25c-1.822 1.96-3.474 2.137-5.722.653-1.416-.935-2.38-2.184-2.716-3.892-.279-1.408.169-2.546 1.187-3.479 3.526-3.234 7.064-6.454 10.6-9.678 1.26-1.152 2.525-2.3 3.844-3.498-1.432-.514-2.802-1.013-4.183-1.494-.59-.205-1.093-.47-1.193-1.17-.1-.7.305-1.092.816-1.46 2.213-1.59 4.403-3.216 6.63-4.785.555-.392.765-.831.752-1.49-.058-2.7-.076-5.404-.128-8.106-.012-.617.13-1.124.678-1.462.553-.34 1.029-.133 1.518.17 2.38 1.479 4.552 3.247 6.803 4.903.549.404 1.02.468 1.643.255 2.495-.857 5.003-1.68 7.509-2.512.21-.07.425-.14.646-.169 1.074-.151 1.746.634 1.486 1.689-.557 2.237-1.462 4.361-2.148 6.555-.028.091-.074.179-.117.265-.802 1.547-.364 2.79.745 4.053 1.174 1.334 2.154 2.842 3.202 4.286.348.48.68.979.959 1.5.554 1.038-.046 2.03-1.237 2.02-2.727-.02-5.453-.05-8.18-.12-.724-.02-1.183.206-1.6.807-1.5 2.156-3.055 4.274-4.59 6.406-.13.181-.261.364-.412.527-.758.81-1.881.607-2.263-.43-.477-1.296-.915-2.609-1.374-3.912-.054-.155-.136-.3-.215-.477v.004ZM37.42 39.27a.926.926 0 0 0-.738.277c-.44.423-.899.831-1.358 1.227a261.078 261.078 0 0 0-5.696 5.101c-.54.498-1.054 1.037-1.633 1.484-1.302 1.006-2.408 2.232-3.698 3.246-.364.286-.332.515.004.823.62.571 1.223 1.164 1.783 1.79.378.422.599.46 1.01 0 2.604-2.927 5.24-5.821 7.864-8.729 1.046-1.16 2.078-2.33 3.128-3.486.338-.374.436-.782.227-1.228-.171-.364-.472-.57-.893-.503v-.002Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.739.72h76.189v76.19H.739z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
