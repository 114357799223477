import { useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { sign } from "tweetnacl";
import { useHttpsCallable } from "../../services/FirebaseService";
import Loader from "../../components/Loader/Loader";
import { useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import VerifySuccess from "./VerifySuccess";
import VerifyError from "./VerifyError";
import VerifyAction from "./VerifyAction";
import Divider from "@mui/material/Divider";
import { verifyDiscordRedirect } from "../../utils";
import useGetDiscordVerified from "../../hooks/queries/integrations/discord/state/useGetDiscordVerified";
import { useQueryClient } from "@tanstack/react-query";
import CustomWalletButton from "../../components/CustomWalletButton";

const Verify = () => {
  const queryClient = useQueryClient();
  const verifyFn = useHttpsCallable("verify/request");
  const { data: verifiedInfo, isLoading: verifiedInfoLoading } =
    useGetDiscordVerified();
  const { publicKey, signMessage } = useWallet();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingMessage, setLoadingMessage] = useState("");
  const [isLedger, setIsLedger] = useState(false);
  const [code, setCode] = useState("");
  const [params] = useSearchParams();
  const [error, setError] = useState<
    undefined | { title: string; description: string }
  >();

  useEffect(() => {
    setLoadingMessage(
      verifiedInfoLoading && publicKey ? "Checking the verification status" : ""
    );
  }, [verifiedInfoLoading, publicKey]);

  useEffect(() => {
    if (publicKey && verifiedInfo && !verifiedInfo?.verified && !code) {
      verifyDiscordRedirect();
    }
  }, [verifiedInfo, code, publicKey]);

  useEffect(() => {
    const codeQ = params.get("code");
    if (codeQ) {
      setCode(codeQ);
    }
    // eslint-disable-next-line
  }, []);

  const sendToConfirmation = async (signatureArray?: number[]) => {
    const res = await verifyFn({
      signatureArray,
      walletAddress: publicKey?.toString(),
      code,
      isLedger,
    });
    const data = res.data as {
      success: boolean;
      error: { description: string; title: string };
      code: number;
    };
    if (!data?.success) {
      setError({
        title: data?.error?.title,
        description: data?.error?.description,
      });
      return;
    }
    queryClient.invalidateQueries({
      queryKey: ["verify/state"],
      exact: false,
      refetchType: "all",
    });
    queryClient.refetchQueries(["verify/state", publicKey?.toString()]);
  };

  const requestSignMessage = async () => {
    setLoadingMessage("May take a while, trust the process");
    if (isLedger) {
      setLoadingMessage("");
      await sendToConfirmation();
      return;
    }
    let message = new TextEncoder().encode(
      `Verify your wallet address for: ${publicKey?.toString()}`
    );
    message = new Uint8Array(Array.from(message));
    try {
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error("Wallet not connected!");
      // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage)
        throw new Error("Wallet does not support message signing!");

      // Sign the bytes using the wallet
      const signature = await signMessage(message);
      // Verify that the bytes were signed using the private key that matches the known public key
      if (!sign.detached.verify(message, signature, publicKey?.toBytes()))
        throw new Error("Invalid signature!");

      await sendToConfirmation(Array.from(signature));
    } catch (error: any) {
      enqueueSnackbar({
        variant: "error",
        message: error?.message || "Something went wrong. Try again, please",
      });
    } finally {
      setLoadingMessage("");
    }
  };

  return (
    <Stack
      sx={{ paddingX: { xs: 1.5, md: 3 }, paddingY: 3, textAlign: "center" }}
      alignItems="center"
    >
      <Typography variant="h4" fontWeight={600} mb={2}>
        Verify your identity
      </Typography>
      <Typography>
        Verify your Discord account + Wallet to gain access
        <br />
        to the roles and rewards
      </Typography>
      <Divider sx={{ width: "100%", marginY: 2 }} />
      {loadingMessage ? (
        <Stack alignItems="center" spacing={2} marginY={4}>
          <Loader />
          <Typography>{loadingMessage}</Typography>
        </Stack>
      ) : (
        <>
          {publicKey ? (
            <>
              {verifiedInfo?.verified && <VerifySuccess />}
              {error && (
                <VerifyError text={error} retry={verifyDiscordRedirect} />
              )}
              {!verifiedInfo?.verified && !error && (
                <VerifyAction
                  setIsLedger={setIsLedger}
                  handleVerify={requestSignMessage}
                  loading={!!loadingMessage}
                />
              )}
            </>
          ) : (
            <Box mt={2}>
              <CustomWalletButton />
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};

export default Verify;
