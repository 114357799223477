import { useRef, useState } from "react";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import {
  StyledTrendingExperiencesVideoplayer,
  StyledTrendingExperiencesVideoplayerHandler,
} from "../../../components/StyledComponents";

const TrendingExperiencesVideoplayer = ({
  src,
  thumb,
}: {
  src: string;
  thumb: string;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playing, setPlaying] = useState(false);

  const videoHandler = (control: "play" | "pause") => {
    if (control === "play") {
      videoRef?.current?.play();
    } else if (control === "pause") {
      videoRef?.current?.pause();
    }
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  return (
    <StyledTrendingExperiencesVideoplayer>
      <StyledTrendingExperiencesVideoplayerHandler
        sx={{
          opacity: playing ? 0 : 1,
        }}
        onClick={() => {
          videoHandler(playing ? "pause" : "play");
        }}
      >
        <PlayCircleOutlineRoundedIcon
          color="inherit"
          sx={{ width: "25%", height: "auto" }}
        />
      </StyledTrendingExperiencesVideoplayerHandler>
      <video
        playsInline
        preload="metadata"
        muted
        poster={thumb}
        ref={videoRef}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={() => {
          videoRef?.current?.load();
        }}
      >
        <source src={src} type="video/mp4" />
      </video>
    </StyledTrendingExperiencesVideoplayer>
  );
};

export default TrendingExperiencesVideoplayer;
