import Box from "@mui/material/Box";
import { Order } from "../../../../types";
import { TokensRowData } from "./types";
import SubscriptionsTokensRow from "./TokensRow";
import SubscriptionsTokensSkeleton from "./TokensSkeleton";
import { StyledGridTokensTable } from "../../../../components/StyledComponents";
import TokensHead from "./TokensHead";

type Props = {
  loading: boolean;
  rows?: TokensRowData[];
  sort: {
    order: Order;
    orderBy: keyof TokensRowData;
  };
  onSort: (property: keyof TokensRowData) => void;
  withdraw: (id: TokensRowData["id"]) => void;
};

const Tokens = ({ loading, rows, sort, onSort, withdraw }: Props) => {
  if (loading || typeof rows === "undefined") {
    return <SubscriptionsTokensSkeleton />;
  }

  if (rows && rows?.length > 0) {
    return (
      <Box sx={{ overflowX: "auto" }} mt={1.5}>
        <StyledGridTokensTable>
          <TokensHead
            onRequestSort={onSort}
            order={sort.order}
            orderBy={sort.orderBy}
          />
          {rows?.map((token) => {
            return (
              <SubscriptionsTokensRow
                data={token}
                key={token?.id}
                onWithdraw={() => {
                  withdraw(token?.id);
                }}
              />
            );
          })}
        </StyledGridTokensTable>
      </Box>
    );
  }

  return null;
};

export default Tokens;
