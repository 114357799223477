import { CommunityService } from "./CommunityService";
import { FirebaseService } from "./FirebaseService";
import { WalletService } from "./WalletService";

export const AppState = ({ children }: any) => {
  return (
    <FirebaseService>
      <WalletService>
        <CommunityService>{children}</CommunityService>
      </WalletService>
    </FirebaseService>
  );
};
