import { useParams } from "react-router-dom";
import { useHttpsCallable } from "../../../../services/FirebaseService";
import {
  CommunityPlansByRouteResponse,
  CommunityPlansByRouteResult,
} from "../../../../types";
import { useQuery } from "@tanstack/react-query";

const useGetCommunityPlans = () => {
  const path = "community/plans";
  const { communityName } = useParams();
  const fbFn = useHttpsCallable(path);

  const getCommunityPlans = async () => {
    const { data } = await fbFn({ route: communityName });
    const res = data as CommunityPlansByRouteResponse;
    if (!res?.success) {
      throw new Error("Community not found");
    }
    return data as CommunityPlansByRouteResult;
  };

  return useQuery([path, communityName], getCommunityPlans, {
    staleTime: 30 * 60 * 1000,
    enabled: !!communityName,
  });
};

export default useGetCommunityPlans;
