import Rewards from "../Rewards/Rewards";
import Earnings from "../Earnings/Earnings";

const RewardsTab = () => {
  return (
    <>
      <Earnings />
      <Rewards />
    </>
  );
};

export default RewardsTab;
