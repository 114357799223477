import { useParams } from "react-router-dom";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { useQuery } from "@tanstack/react-query";
import { LoyaltyQuestsDetailResponse } from "../../../../../types";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";

const useGetCommunityQuestsDetail = () => {
  const path = "quests/detail";
  const { communityName } = useParams();
  const { data: communityInfo } = useGetCommunityInfo();
  const fn = useHttpsCallable(path);

  const getCommunityQuestsDetail = async () => {
    const { data } = await fn({
      accountId: communityInfo?.accountId,
      communityId: communityInfo?.communityId,
    });
    const res = data as LoyaltyQuestsDetailResponse;
    if (!res?.success) {
      throw new Error("Error");
    }
    return res?.data;
  };

  return useQuery([path, communityName], getCommunityQuestsDetail, {
    staleTime: 30 * 60 * 1000,
    enabled: !!communityInfo?.accountId && !!communityInfo?.communityId,
  });
};

export default useGetCommunityQuestsDetail;
