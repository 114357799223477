import { createContext, useContext, useState } from "react";
import { CommunityServiceType, Plan } from "../types";

const CommunityContext = createContext<CommunityServiceType>({});

export function useCommunity() {
  return useContext(CommunityContext);
}

export const CommunityService = ({ children }: any) => {
  const [nftModalData, setNftModalData] =
    useState<CommunityServiceType["nftModalData"]>();
  const [contractLevelsModalData, setContractLevelsModalData] =
    useState<CommunityServiceType["contractLevelsModalData"]>();
  const [loadingModal, setLoadingModal] =
    useState<CommunityServiceType["loadingModal"]>(null);
  const [editBoosterModalData, setEditBoosterModalData] =
    useState<CommunityServiceType["editBoosterModalData"]>();
  const [selectedFilterPlan, setSelectedFilterPlan] = useState("");
  const [selectedBoostersFilterPlan, setSelectedBoostersFilterPlan] =
    useState("");
  const [rewardDetailsData, setRewardDetailsData] =
    useState<CommunityServiceType["rewardDetailsData"]>();
  const [campaignContractModalData, setCampaignContractModalData] = useState<
    Plan | undefined
  >();

  const value = {
    nftModalData,
    contractLevelsModalData,
    setContractLevelsModalData,
    setNftModalData,
    loadingModal,
    setLoadingModal,
    editBoosterModalData,
    setEditBoosterModalData,
    selectedFilterPlan,
    setSelectedFilterPlan,
    selectedBoostersFilterPlan,
    setSelectedBoostersFilterPlan,
    rewardDetailsData,
    setRewardDetailsData,
    campaignContractModalData,
    setCampaignContractModalData,
  };

  return (
    <CommunityContext.Provider value={value}>
      {children}
    </CommunityContext.Provider>
  );
};
