import { useParams } from "react-router-dom";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";

const useGetQuestsProgress = () => {
  const path = "quests/by-wallet";
  const { publicKey } = useWallet();
  const { communityName } = useParams();
  const { data: communityInfo } = useGetCommunityInfo();
  const fn = useHttpsCallable(path);

  const getQuestsProcess = async () => {
    const { data } = await fn({
      walletAddress: publicKey?.toString(),
      accountId: communityInfo?.accountId,
      communityId: communityInfo?.communityId,
    });
    const res = data as any;
    if (!res?.success) {
      throw new Error("Error");
    }
    return res?.data;
  };

  return useQuery(
    [path, communityName, publicKey?.toString()],
    getQuestsProcess,
    {
      staleTime: 10 * 60 * 1000,
      enabled:
        !!publicKey &&
        !!communityInfo?.accountId &&
        !!communityInfo?.communityId,
    }
  );
};

export default useGetQuestsProgress;
