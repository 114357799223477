import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";
import { useSnackbar } from "notistack";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { MutationDefaultResponse } from "../../../../../types";
import { sign } from "tweetnacl";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";

export default function useClaimRewardMutation() {
  const queryClient = useQueryClient();
  const txFbFn = useHttpsCallable("rewards/claim/request");
  const processFbFn = useHttpsCallable("rewards/claim/process");
  const { data: communityInfo } = useGetCommunityInfo();
  const { publicKey, signTransaction, signMessage } = useWallet();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async (campaignId: string) => {
      const res = await txFbFn({
        accountId: communityInfo?.accountId,
        communityId: communityInfo?.communityId,
        campaignId,
        walletAddress: publicKey?.toString(),
      });
      const reqTxRes = res?.data as any;
      if (!reqTxRes?.success) {
        throw new Error(
          `${reqTxRes?.error?.title}\n${reqTxRes?.error?.description}`
        );
      }

      let finalRes;

      if (reqTxRes?.data?.hasOwnProperty("signedMessage")) {
        let message = new TextEncoder().encode(reqTxRes?.data?.signedMessage);
        message = new Uint8Array(Array.from(message));
        // `publicKey` will be null if the wallet isn't connected
        if (!publicKey) throw new Error("Wallet not connected!");
        // `signMessage` will be undefined if the wallet doesn't support it
        if (!signMessage)
          throw new Error("Wallet does not support message signing!");

        // Sign the bytes using the wallet
        const signature = await signMessage(message);
        // Verify that the bytes were signed using the private key that matches the known public key
        if (!sign.detached.verify(message, signature, publicKey?.toBytes()))
          throw new Error("Invalid signature!");

        finalRes = await processFbFn({
          accountId: communityInfo?.accountId,
          communityId: communityInfo?.communityId,
          rawTransaction: Array.from(signature),
          requestId: reqTxRes?.data?.requestId,
        });
      } else {
        const transaction = Transaction.from(
          reqTxRes?.data?.rawTransaction?.data
        );
        if (!signTransaction) {
          throw new Error("Can't create transaction");
        }
        const signedTransaction = await signTransaction(transaction);
        finalRes = await processFbFn({
          accountId: communityInfo?.accountId,
          communityId: communityInfo?.communityId,
          rawTransaction: Object.values(signedTransaction.serialize()),
          requestId: reqTxRes?.data?.requestId,
        });
      }
      const finalResData = finalRes?.data as MutationDefaultResponse;
      if (!finalResData?.success) {
        throw new Error(
          `${finalResData?.error?.title}\n${finalResData?.error?.description}`
        );
      }
      await queryClient.invalidateQueries({
        queryKey: ["rewards/by-id", communityInfo?.communityId, campaignId],
        exact: true,
        refetchType: "active",
      });
      await queryClient.invalidateQueries({
        queryKey: ["rewards/list", communityInfo?.communityId],
        exact: true,
        refetchType: "active",
      });
      await queryClient.invalidateQueries({
        queryKey: [
          "rewards/claimed-list",
          communityInfo?.accountId,
          communityInfo?.communityId,
          publicKey?.toString(),
        ],
        exact: true,
        refetchType: "active",
      });
      return finalRes?.data;
    },
    {
      onSuccess: () => {
        enqueueSnackbar({
          variant: "success",
          message: "Claiming completed successfully",
        });
      },
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
}
