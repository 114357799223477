import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import {
  EllipsisTypography,
  StyledCommunityMiniCard,
} from "./StyledComponents";
import { formatNumberIntoView } from "../utils";
import TokenAmount from "./TokenAmount/TokenAmount";
import solanaPng from "../assets/images/solana.png";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";

type Props = {
  img: string;
  number: number;
  name: string;
  floor: number;
  staked: number;
  total: number;
  onClick: () => void;
};

export const CommunityCardMini = ({
  img,
  number,
  name,
  floor,
  staked,
  total,
  onClick,
}: Props) => {
  return (
    <StyledCommunityMiniCard>
      <CardActionArea className="inner" onClick={onClick}>
        <Box className="img-box">
          <Avatar
            className="img"
            src={img}
            alt={name}
            sx={{ width: "100%", height: "100%" }}
            variant="square"
          >
            <InsertPhotoRoundedIcon sx={{ fontSize: "3rem" }} />
          </Avatar>
          <Box className="number">
            <Typography
              color="text.secondary"
              fontSize="0.75rem"
              fontWeight={500}
            >
              {number}
            </Typography>
          </Box>
        </Box>
        <Box className="content">
          <Box className="content__left">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <EllipsisTypography fontWeight={600} variant="subtitle1">
                {name}
              </EllipsisTypography>
              <VerifiedRoundedIcon color="primary" fontSize="small" />
            </Stack>
            <Box fontSize="0.75rem" color="text.secondary">
              <Stack
                direction="row"
                fontSize="0.75rem"
                color="text.secondary"
                alignItems="baseline"
                spacing={0.5}
              >
                <Typography variant="overline" lineHeight={1}>
                  FP
                </Typography>
                <TokenAmount
                  img={solanaPng}
                  tokenLabel="$SOL"
                  amount={formatNumberIntoView(floor)}
                />
              </Stack>
            </Box>
          </Box>
          <Box textAlign="right">
            <Stack
              direction="row"
              spacing={0.5}
              alignItems="baseline"
              justifyContent="flex-end"
            >
              <Typography fontWeight={600} variant="subtitle1">
                {formatNumberIntoView(staked, 0)}
              </Typography>
              <Typography fontSize="0.75rem" color="text.secondary">
                subs
              </Typography>
            </Stack>
            <Stack
              direction="row"
              fontSize="0.75rem"
              color="text.secondary"
              alignItems="baseline"
              justifyContent="flex-end"
              spacing={0.5}
            >
              <Typography variant="overline" lineHeight={1}>
                TVL
              </Typography>
              <TokenAmount
                img={solanaPng}
                tokenLabel="$SOL"
                amount={total?.toLocaleString("en-US", {
                  maximumFractionDigits: 0,
                })}
              />
            </Stack>
          </Box>
        </Box>
      </CardActionArea>
    </StyledCommunityMiniCard>
  );
};

export const CommunityCardMiniSkeleton = () => {
  return (
    <StyledCommunityMiniCard>
      <Box className="inner">
        <Box className="img-box">
          <Skeleton width={88} height={88} />
        </Box>
        <Box className="content">
          <Box className="content__left">
            <Skeleton height="1.2rem" width={80} sx={{ mb: 1 }} />
            <Skeleton height="1rem" width={60} />
          </Box>
          <Stack alignItems="flex-end">
            <Skeleton height="1.2rem" width={80} sx={{ mb: 1 }} />
            <Skeleton height="1rem" width={60} />
          </Stack>
        </Box>
      </Box>
    </StyledCommunityMiniCard>
  );
};
