import { Dispatch, Ref, SetStateAction } from "react";
import { MarketplaceActivityItem, MarketplaceNftData } from "../../types";

export type FilterType = {
  marketplaceIds: string[];
  skip: number;
  limit: number;
  status?: string[];
  orderBy: OrderBy;
  filterByTokenAttribute: {
    [trait_type: string]: string[];
  };
  sellerAddresses: string[];
  buyerAddresses: string[];
  mintAddresses: string[];
};

export type OrderBy = {
  field: "price" | "created";
  order: "desc" | "asc";
};

export enum OwnerFilter {
  All = "All",
  Self = "Self",
}

export enum ViewMode {
  Explore = "Explore",
  List = "List",
}

export type MarketplaceServiceProps = {
  filterOpen: boolean;
  handleToggleFilter: () => void;
  activityOpen: boolean;
  handleToggleActivity: () => void;
  nftInfoModalOpen: boolean;
  showNftInfoModal: (data: MarketplaceNftData) => void;
  hideNftInfoModal: () => void;
  nftInfoModalData?: MarketplaceNftData;
  viewMaxWidth: number;
  setViewMaxWidth: Dispatch<SetStateAction<number>>;
  viewMode: ViewMode;
  setViewMode: Dispatch<SetStateAction<ViewMode>>;
  marketplaceId?: string;
  nftModalSubmitLoading: boolean;
  setNftModalSubmitLoading: Dispatch<SetStateAction<boolean>>;
  nftListBoxRef: Ref<HTMLDivElement>;
};

export type MarketplaceServiceExploreProps = {
  filter: FilterType;
  changeFilter: (
    key: keyof FilterType,
    value: FilterType[keyof FilterType]
  ) => void;
  exploreLoading: boolean;
  exploreList: MarketplaceNftData[];
  exploreNextPage: (limit?: number) => void;
  hasExploreNextPage: boolean;
  exploreNextPageLoadingLimit: number;
  clearFilters: () => void;
};

export type ActivityFilterLogTypes = "list" | "delist" | "buy" | "reprice";

export type ActivityFilter = {
  logTypes: ActivityFilterLogTypes[];
};

export type MarketplaceServiceActivityProps = {
  handleToggleActivity: () => void;
  activityLoading: boolean;
  setActivityLoading: Dispatch<SetStateAction<boolean>>;
  activityList: (MarketplaceActivityItem & { timePassed?: string })[];
  activityNextPage: (limit?: number) => void;
  hasNextPage: boolean;
  nextPageLoadingLimit: number;
  activityFilter: ActivityFilter;
  setActivityFilter: Dispatch<SetStateAction<ActivityFilter>>;
};
