import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomWalletButton from "../CustomWalletButton";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import Loader from "../Loader/Loader";
import Box from "@mui/material/Box";
import RedeemRoundedIcon from "@mui/icons-material/RedeemRounded";
import Button from "@mui/material/Button";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import useGetQuestsProgress from "../../hooks/queries/community/quests/state/useGetQuestsProgress";
import { useWallet } from "@solana/wallet-adapter-react";

const QuestsState = () => {
  const { publicKey } = useWallet();
  const { isFetching, refetch } = useGetQuestsProgress();
  const getContent = () => {
    if (!publicKey) {
      return (
        <>
          <AutoAwesomeRoundedIcon />
          <Typography variant="h6" textAlign={{ xs: "center", sm: "left" }}>
            Login to get rewarded
          </Typography>
          <Box ml={{ sm: "auto" }}>
            <CustomWalletButton variant="gradient" />
          </Box>
        </>
      );
    }
    if (isFetching) {
      return (
        <>
          <Loader size={36} />
          <Typography variant="h6" textAlign={{ xs: "center", sm: "left" }}>
            In the process of getting the status of passing quests
          </Typography>
        </>
      );
    }
    return (
      <>
        <RedeemRoundedIcon />
        <Typography variant="h6" textAlign={{ xs: "center", sm: "left" }}>
          Your current progress presented below
        </Typography>
        <Button
          startIcon={<CachedRoundedIcon />}
          variant="base"
          color="secondary"
          sx={{ ml: { sm: "auto" } }}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
      </>
    );
  };

  return (
    <Paper elevation={0} sx={{ p: 1.5 }}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        gap={1.5}
      >
        {getContent()}
      </Stack>
    </Paper>
  );
};
export default QuestsState;
