import { TokensRowData } from "./types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import TrendingDownRoundedIcon from "@mui/icons-material/TrendingDownRounded";
import { formatNumberIntoView } from "../../../../utils";
import Skeleton from "@mui/material/Skeleton";
import {
  StyledGridTokensTableCell,
  StyledGridTokensTableRow,
} from "../../../../components/StyledComponents";
import Tooltip from "@mui/material/Tooltip";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const TokensRow = ({
  data,
  onWithdraw,
}: {
  data: TokensRowData;
  onWithdraw: () => void;
}) => {
  return (
    <StyledGridTokensTableRow key={data?.id}>
      <StyledGridTokensTableCell
        sx={{
          justifyContent: "flex-start",
          borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
          borderBottomLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1} maxWidth="100%">
          {data?.asset?.img ? (
            <>
              <Box width={30} overflow="hidden" flexShrink={0}>
                <img src={data?.asset?.img} alt={data?.asset?.label} />
              </Box>
              <Box
                textAlign="left"
                maxWidth={{ xs: "100%", sm: "calc(100% - 38px)" }}
              >
                <Typography fontWeight="bold" lineHeight={1}>
                  {data?.quantity === 0
                    ? ``
                    : formatNumberIntoView(data?.quantity, data?.decimals)}
                </Typography>
                <Typography variant="caption">
                  ${data?.asset?.symbol}
                </Typography>
              </Box>
            </>
          ) : (
            <Skeleton width={30} height={30} variant="circular" />
          )}
        </Stack>
      </StyledGridTokensTableCell>
      <StyledGridTokensTableCell>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          {data?.poolBalance < data?.quantity && (
            <Tooltip title="Insufficient funds in the pool" placement="top">
              <WarningRoundedIcon color="warning" fontSize="small" />
            </Tooltip>
          )}
          <Typography fontWeight="bold" className="pool">
            {data?.poolBalance?.toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Stack>
      </StyledGridTokensTableCell>
      <StyledGridTokensTableCell>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          {data?.change ? (
            <>
              <Typography fontWeight="bold">
                {data?.change > 0 && "+"}
                {formatNumberIntoView(data?.change)}%
              </Typography>
              {data?.change >= 0 ? (
                <TrendingUpRoundedIcon color="success" />
              ) : (
                <TrendingDownRoundedIcon color="error" />
              )}
            </>
          ) : (
            <Typography fontWeight="bold">―</Typography>
          )}
        </Stack>
      </StyledGridTokensTableCell>
      <StyledGridTokensTableCell>
        <Typography fontWeight="bold" className="dollar-amount">
          {data?.usdAmount
            ? formatNumberIntoView(data?.usdAmount, data?.decimalsUsd)
            : "―"}
        </Typography>
      </StyledGridTokensTableCell>
      <StyledGridTokensTableCell
        sx={{
          borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
          borderBottomRightRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Button size="small" onClick={onWithdraw}>
          Withdraw
        </Button>
      </StyledGridTokensTableCell>
    </StyledGridTokensTableRow>
  );
};

export default TokensRow;
