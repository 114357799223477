import Button from "@mui/material/Button";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import useLineClamp from "use-line-clamp";
import Box from "@mui/material/Box";

type Props = {
  text: string;
  lines?: number;
} & TypographyProps;

const ExpandableTypography = ({ text, lines = 2, ...props }: Props) => {
  const ref = useRef<HTMLSpanElement>(null);
  const clamps = useLineClamp(ref, { lines });
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((state) => !state);
  };

  useEffect(() => {
    setShowMore(false);
    // this is just a crutch to make this crap work in modal windows (clamps is not correct in them, you need to trigger it)
    window.dispatchEvent(new Event("resize"));
  }, [text]);

  return (
    <Box>
      <Typography
        variant="body1"
        ref={ref}
        style={{
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: showMore ? "unset" : lines,
        }}
        {...props}
      >
        {text}
      </Typography>
      {clamps && (
        <Button
          variant="text"
          color="inherit"
          size="small"
          component="span"
          sx={{
            p: 0.5,
            position: "relative",
            lineHeight: 1,
          }}
          disableRipple
          onClick={toggleShowMore}
        >
          Show {showMore ? "less" : "more"}
        </Button>
      )}
    </Box>
  );
};

export default ExpandableTypography;
