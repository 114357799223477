import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { stringToShorten } from "../utils";

type Props = {
  variant?: "gradient";
};

const CustomWalletButton = ({ variant }: Props) => {
  const { publicKey, wallet } = useWallet();

  return (
    <WalletMultiButton
      className={
        `${variant === "gradient" ? "wallet-multi-gradient" : ""}` +
        (!wallet || publicKey ? " hide-wallet-icon" : "")
      }
    >
      <Stack direction="row" alignItems="center" spacing={1.5}>
        {publicKey ? (
          <AccountCircleRoundedIcon />
        ) : wallet ? null : (
          <LoginRoundedIcon />
        )}
        <Typography fontWeight={600}>
          {publicKey ? stringToShorten(publicKey?.toString()) : "Login"}
        </Typography>
      </Stack>
    </WalletMultiButton>
  );
};

export default CustomWalletButton;
