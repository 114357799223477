import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={136}
    height={114}
    viewBox="0 0 134 114"
    fill="none"
    {...props}
  >
    <path
      fill="#ffe000"
      d="M.5 98.445V59.95c.247-.502.504-.995.737-1.5 7.317-15.854 14.584-31.729 21.968-47.555C26.25 4.37 31.517.88 38.752.851 58.261.777 77.77.777 97.28.851c7.182.029 12.446 3.486 15.473 9.96 7.412 15.857 14.7 31.771 21.992 47.685.441.967.713 2.103.716 3.161.05 11.6.057 23.199 0 34.798a17.423 17.423 0 0 1-.681 4.668c-2.109 7.412-8.653 12.146-16.704 12.149-33.394.008-66.788.004-100.183 0-.395 0-.79 0-1.185-.021-5.236-.229-9.42-2.497-12.686-6.547-1.948-2.42-2.897-5.271-3.521-8.259ZM121.677 57.05c-.176-.427-.25-.628-.339-.819-6.23-13.504-12.47-27.005-18.686-40.517-1.218-2.642-3.285-3.697-6.11-3.694-19.03.022-38.055.025-57.084 0-3.024-.003-5.024 1.274-6.29 4.047-6.061 13.247-12.189 26.459-18.292 39.688-.176.384-.339.776-.515 1.182.218.06.303.106.388.106 3.56.007 7.119.003 10.679.014 4.946.014 8.473 2.586 9.913 7.292a1614.181 1614.181 0 0 1 5.715 19.029c.388 1.323 1.051 1.845 2.423 1.841 16.348-.028 32.693-.028 49.04 0 1.376 0 2.04-.518 2.424-1.845 1.81-6.233 3.697-12.446 5.574-18.662 1.545-5.108 4.996-7.655 10.319-7.659h10.845l-.004-.003Z"
    />
    <path
      fill="#ffe000"
      d="M90.174 26.485a.003.003 0 0 1-.003-.004c0-.436 0-.883-.081-1.309-.621-3.407-3.31-5.648-6.784-5.655-5.204-.01-10.407 0-15.61 0-5.204 0-10.478-.014-15.717.008-3.856.01-6.78 2.924-6.791 6.77-.018 7.471-.014 14.943 0 22.412.007 3.862 2.967 6.794 6.826 6.808 1.933.007 3.87.043 5.8-.014 1.132-.032 1.93.399 2.607 1.28 1.227 1.602 2.519 3.162 3.789 4.735a4.245 4.245 0 0 0 1.714 1.277c1.873.773 3.91.307 5.207-1.256 1.37-1.647 2.7-3.33 4.033-5.01.553-.698 1.241-1.03 2.141-1.019 1.95.021 3.902.007 5.853.007 4.106 0 7.02-2.892 7.02-6.985.004-7.347 0-14.694 0-22.042a.004.004 0 0 0-.004-.003Zm-14.83 17.62a.004.004 0 0 1 .001.005 2.263 2.263 0 0 1-2.185 1.273c-.603-.038-1.108-.3-1.531-.726-1.19-1.196-2.4-2.375-3.57-3.592-.322-.331-.477-.285-.773.018a233.06 233.06 0 0 1-3.613 3.62c-.656.645-1.446.853-2.328.567-.882-.285-1.393-.924-1.535-1.834-.12-.766.163-1.411.706-1.954 1.196-1.193 2.37-2.403 3.588-3.57.353-.34.303-.505-.018-.816a169.74 169.74 0 0 1-3.655-3.654c-.691-.713-.843-1.577-.437-2.48.388-.861 1.09-1.306 2.04-1.327.698-.018 1.248.279 1.728.766 1.189 1.2 2.395 2.378 3.57 3.588.282.289.423.271.695-.008 1.203-1.234 2.427-2.444 3.648-3.661.66-.657 1.45-.85 2.324-.576.907.286 1.419.953 1.546 1.884.098.745-.173 1.376-.702 1.905-1.207 1.203-2.4 2.424-3.627 3.61-.31.3-.275.454.01.737a214.333 214.333 0 0 1 3.69 3.69c.698.715.836 1.67.426 2.53-.001.001 0 .004.002.005Z"
    />
  </svg>
);
export default SvgComponent;
