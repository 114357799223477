import { useQuery } from "@tanstack/react-query";
import { useHttpsCallable } from "../../../services/FirebaseService";
import { SolanaExchangeRateResponse } from "../../../types";

const useGetSolanaRate = () => {
  const path = "solana/rate";
  const fbFn = useHttpsCallable(path);

  const getSolanaRate = async () => {
    const { data } = await fbFn();
    const res = data as SolanaExchangeRateResponse;
    if (!res?.success) {
      throw new Error(`${res?.error?.title}\n${res?.error?.description}`);
    }
    return res?.data?.usdValue || 0;
  };

  return useQuery([path], getSolanaRate, {
    staleTime: 5 * 60 * 1000,
  });
};

export default useGetSolanaRate;
