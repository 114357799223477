import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 43"
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M6.553 12.701c.026-5.805 4.926-10.448 10.991-10.415 5.906.034 10.788 4.75 10.764 10.4-.026 5.907-4.884 10.51-11.063 10.484-5.862-.025-10.717-4.78-10.692-10.469Zm11.094.723c-.747.067-1.312.097-1.871.174-.3.041-.596.142-.879.252-.973.38-1.525 1.172-1.308 2.081.154.65.406 1.322.794 1.866.75 1.054 1.86 1.678 3.21 1.614 1.728-.084 3.253-1.454 3.627-3.095.294-1.295-.22-2.202-1.555-2.566-.706-.193-1.455-.24-2.017-.328l-.001.002Zm-5.655-1.443c.417 0 .766-.33.774-.731.008-.402-.332-.743-.748-.755-.438-.013-.805.334-.799.753.006.402.355.732.773.732Zm10.106-.779c-.022.399.306.752.724.778.414.026.787-.283.822-.683.036-.416-.31-.789-.746-.802-.418-.013-.778.305-.8.706v.001Z"
    />
    <path
      fill="#fff"
      d="M9.068 21.546c2.392 2.037 5.184 3.12 8.39 3.114 3.19-.006 5.968-1.084 8.38-3.145 2.263 3.282 3.36 6.835 3.235 10.756-5.318-1.15-9.58 4.065-6.928 8.835-3.14.959-6.248.949-9.419.003 1.198-2.307 1.175-4.545-.444-6.602-1.606-2.042-3.821-2.69-6.409-2.235-.63-2.366 1.185-8.466 3.195-10.727Z"
    />
    <path
      fill="#fff"
      d="M7.312 42.568c-2.563-.007-4.652-2.026-4.646-4.49.007-2.46 2.11-4.466 4.677-4.459 2.566.006 4.651 2.023 4.645 4.49-.007 2.463-2.108 4.466-4.677 4.46v-.002ZM32.197 38.087c.003 2.465-2.087 4.478-4.653 4.482-2.564.003-4.666-2.007-4.669-4.468-.003-2.464 2.088-4.478 4.654-4.482 2.568-.004 4.665 2.003 4.668 4.466v.002ZM28.079 18.924c3.065 1.463 5.323 3.557 6.282 6.752.144.48.183 1.018.135 1.518-.15 1.55-1.43 2.361-3.243 2.116-.502-.068-.796-.343-.882-.825-.496-2.775-1.568-5.331-3.203-7.669-.188-.269-.232-.45.013-.716.318-.345.573-.744.898-1.177v.001ZM6.806 18.959c.358.465.707.89 1.013 1.344.061.091-.002.317-.082.431-1.571 2.261-2.635 4.72-3.176 7.389-.019.09-.034.183-.05.275-.145.839-.162.885-1.046.916-.508.018-1.046-.004-1.528-.142C.75 28.83.15 27.656.372 26.26c.272-1.719 1.173-3.135 2.336-4.418 1.13-1.247 2.47-2.225 4.099-2.883h-.001ZM5.605 9.045C4.058 7.986 3.324 6.611 3.472 4.861 3.625 3.047 4.939 1.528 6.717 1 8.43.49 10.39.992 11.483 2.242 8.66 3.787 6.7 6.024 5.604 9.045ZM29.243 9c-1.08-2.966-3.024-5.204-5.839-6.743.79-1.11 2.772-1.712 4.422-1.347a4.576 4.576 0 0 1 3.566 3.86c.223 1.624-.706 3.483-2.15 4.23ZM17.441 14.908c.6.084 1.198.13 1.774.258.505.112.61.354.488.832-.016.06-.029.121-.047.18-.313.981-1.439 1.838-2.315 1.762-1.025-.088-2.033-1.031-2.224-2.059-.077-.413.141-.641.507-.712.597-.116 1.207-.176 1.816-.26h.001Z"
    />
  </svg>
);
export default SvgComponent;
