import { useHttpsCallable } from "../../../services/FirebaseService";
import { TopCommunitiesResponse } from "../../../types";
import { useQuery } from "@tanstack/react-query";

const useGetTopCommunities = () => {
  const path = "communities/top";
  const fbFn = useHttpsCallable(path);

  const getCommunityInfo = async () => {
    const { data } = await fbFn();
    const res = data as TopCommunitiesResponse;
    if (!res?.success) {
      throw new Error(`Cannot get top communities`);
    }
    return res.communities;
  };

  return useQuery([path], getCommunityInfo, {
    staleTime: 30 * 60 * 1000,
  });
};
export default useGetTopCommunities;
