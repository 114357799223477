import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ExpandableTypography from "../ExpandableTypography";
import Timeline from "@mui/lab/Timeline";
import { timelineItemClasses } from "@mui/lab/TimelineItem";
import Divider from "@mui/material/Divider";
import QuestStep from "./QuestStep";
import Box from "@mui/material/Box";
import { LoyaltyQuest } from "../../types";
import { useWallet } from "@solana/wallet-adapter-react";
import useGetQuestsProgress from "../../hooks/queries/community/quests/state/useGetQuestsProgress";

type Props = { quest: LoyaltyQuest };

const Quest = ({ quest }: Props) => {
  const { publicKey } = useWallet();
  const { data: questsProgress } = useGetQuestsProgress();
  return (
    <Box pr={{ xs: 1.5, md: 3 }}>
      <Card sx={{ minWidth: 350, maxWidth: 500 }}>
        {quest?.banner?.assets?.[0]?.url && (
          <CardMedia
            sx={{ aspectRatio: "16/9" }}
            image={quest?.banner?.assets?.[0]?.url}
          />
        )}
        <CardContent sx={{ p: 1, "&:last-child": { pb: 2 } }}>
          <Typography variant="h6" fontWeight={700}>
            {quest?.title}
          </Typography>
          <ExpandableTypography text={quest?.description} />
          {quest?.missions?.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Timeline
                sx={{
                  m: 0,
                  p: 0,
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {quest?.missions?.map((mission, i) => {
                  let type: "completed" | "progress" | "incomplete" =
                    "incomplete";
                  if (publicKey && questsProgress) {
                    if (questsProgress?.[quest?._id]?.[mission?.ruleTreeId]) {
                      type = "completed";
                    } else if (
                      i === 0 ||
                      questsProgress?.[quest?._id]?.[
                        quest?.missions?.[i - 1]?.ruleTreeId
                      ]
                    ) {
                      type = "progress";
                    }
                  }
                  return (
                    <QuestStep
                      title={mission?.title}
                      description={mission?.description}
                      type={type}
                      isLast={i + 1 === quest?.missions?.length}
                      key={i}
                    />
                  );
                })}
              </Timeline>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Quest;
