import { useSnackbar } from "notistack";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import { MarketplaceWalletProfileResponse } from "../../../../types";

const useGetMarketplaceWalletProfile = (marketplaceId?: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { publicKey } = useWallet();

  const getProfile = async () => {
    const response = await fetch(
      "https://us-central1-nft-anybodies.cloudfunctions.net/marketplace/v1/api/interface/wallet-profile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            walletAddress: publicKey?.toString(),
            marketplaceId,
          },
        }),
      }
    );
    const { data }: { data: MarketplaceWalletProfileResponse } =
      await response.json();
    if (!data?.success) {
      throw new Error(`${data?.error?.title}\n${data?.error?.description}`);
    }
    return data.data?.eligableForListing || [];
  };

  return useQuery(
    ["marketplace-wallet-profile", publicKey?.toString()],
    getProfile,
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!marketplaceId && !!publicKey,
      onError: (err: any) => {
        enqueueSnackbar({
          variant: "error",
          message: err?.message || "Something went wrong. Try again, please",
        });
      },
    }
  );
};

export default useGetMarketplaceWalletProfile;
