import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Quest from "../../../components/Quest/Quest";
import Stack from "@mui/material/Stack";
import QuestsState from "../../../components/Quest/QuestsState";
import QuestSkeleton from "../../../components/Quest/QuestSkeleton";
import useGetCommunityQuestsDetail from "../../../hooks/queries/community/quests/state/useGetCommunityQuestsDetail";

type Props = { isActive: boolean };

const LoyaltyTab = ({ isActive }: Props) => {
  const { data: questsDetails, isLoading: questsDetailsLoading } =
    useGetCommunityQuestsDetail();

  return (
    <Box display={isActive ? "block" : "none"}>
      <Box p={{ xs: 1.5, md: 3 }}>
        <QuestsState />
      </Box>
      <Typography variant="h5" fontWeight="bold" mb={2} px={{ xs: 1.5, md: 3 }}>
        Loyalty program
      </Typography>
      <Box sx={{ overflowX: "auto" }} pl={{ xs: 1.5, md: 3 }}>
        <Stack direction="row">
          {questsDetailsLoading
            ? [1, 2].map((i) => <QuestSkeleton key={i} />)
            : questsDetails?.map((quest) => (
                <Quest quest={quest} key={quest?._id} />
              ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default LoyaltyTab;
