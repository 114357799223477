import { styled } from "@mui/material/styles";
import { MaterialDesignContent } from "notistack";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const StyledSnackbar = (borderColor: string, background: string) =>
  styled(MaterialDesignContent)(({ theme }) => ({
    "&.notistack-MuiContent": {
      whiteSpace: "pre-line",
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 5,
      padding: "4px 16px",
      fontWeight: 600,
      backdropFilter: "blur(2px)",
      borderColor,
      background,
      marginTop: 52,
      [theme.breakpoints.down("sm")]: {
        marginTop: 44,
      },
    },
  }));

export const notistackComponents = {
  error: StyledSnackbar(
    "#FF2A4E",
    "linear-gradient(90deg, #a42c3d 0%, rgba(35,35,35,0.6) 30%)"
  ),
  info: StyledSnackbar(
    "#4C9AE4",
    "linear-gradient(90deg, #315043 0%, rgba(35,35,35,0.6) 30%)"
  ),
  success: StyledSnackbar(
    "#11F695",
    "linear-gradient(90deg, #315043 0%, rgba(35,35,35,0.6) 30%)"
  ),
};

export const notistackIcons = {
  success: <CheckCircleOutlineRoundedIcon sx={{ color: "#11F695", mr: 2 }} />,
  error: <HighlightOffRoundedIcon sx={{ color: "#FF2A4E", mr: 2 }} />,
  info: <InfoOutlinedIcon sx={{ color: "#4C9AE4", mr: 2 }} />,
};
