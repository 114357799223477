import { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

interface PageTabsProps {
  tabs: { [key: string]: boolean };
  active: string;
  setActive: Dispatch<SetStateAction<string>>;
}

export const PageTabs = ({ tabs, active, setActive }: PageTabsProps) => {
  return (
    <Box
      sx={{
        paddingX: {
          md: 3,
        },
        position: "sticky",
        top: {
          xs: 55,
          sm: 63,
        },
        zIndex: 10,
        backgroundColor: "#191a1f",
      }}
    >
      <Tabs
        aria-label="Main tabs"
        value={active}
        variant="scrollable"
        // scrollButtons
        onChange={(e, newValue) => {
          setActive(newValue);
        }}
      >
        {Object.keys(tabs).map((label) => {
          if (!tabs[label]) return null;
          return (
            <Tab
              label={label}
              value={label.toLowerCase()}
              key={label}
              sx={{ width: 125 }}
            />
          );
        })}
      </Tabs>
      <Divider />
    </Box>
  );
};

export const PageTabsSkeleton = () => {
  return (
    <Box
      sx={{
        paddingX: {
          md: 3,
        },
        position: "sticky",
        top: {
          xs: 55,
          sm: 63,
        },
        zIndex: 10,
        backgroundColor: "#191a1f",
      }}
    >
      <Stack direction="row">
        {Array.from(Array(2).keys()).map((i) => (
          <Box py={1.5} px={2} key={i}>
            <Skeleton sx={{ width: 93 }} />
          </Box>
        ))}
      </Stack>
      <Divider />
    </Box>
  );
};
