import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

interface TokenAmountProps {
  img?: string | undefined;
  tokenLabel?: string;
  amount: string | number;
}

const TokenAmount = ({ img, tokenLabel, amount }: TokenAmountProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        "& img": {
          width: "0.875em",
        },
      }}
    >
      {img && tokenLabel ? (
        <Tooltip title={tokenLabel} placement="top">
          <img src={img} alt={tokenLabel} />
        </Tooltip>
      ) : (
        <Skeleton width="1em" height="1em" variant="circular" />
      )}
      <Typography fontWeight={500} ml="0.375em" fontSize="inherit">
        {amount}
      </Typography>
    </Stack>
  );
};

export default TokenAmount;
