import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import AdjustRoundedIcon from "@mui/icons-material/AdjustRounded";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Typography from "@mui/material/Typography";
import { TokenDelegationPhase } from "../../types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Stack from "@mui/material/Stack";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

type Props = {
  phase: TokenDelegationPhase;
  type: "Completed" | "In progress" | "Locked";
  currentPhaseEndDate?: Date;
  isLast: boolean;
  status?: "live" | "ended";
  index: number;
  currentPhase: number;
};

const Phase = ({
  phase,
  type,
  currentPhaseEndDate,
  isLast,
  status,
  index,
  currentPhase,
}: Props) => {
  const theme = useTheme();

  const CONFIG = {
    Completed: {
      dotColor: theme.palette.text.primary,
      dotBg: theme.palette.success.main,
      cardBg: "linear-gradient(90deg, #315043 0%, rgba(35,35,35,0.6) 30%)",
      cardBorderColor: "#11F695",
    },
    "In progress": {
      dotColor: theme.palette.background.default,
      dotBg: theme.palette.primary.main,
      cardBg: "linear-gradient(90deg, #3b3926 0%, rgba(35,35,35,0.6) 30%)",
      cardBorderColor: theme.palette.primary.main,
    },
    Locked: {
      dotColor: theme.palette.text.disabled,
      dotBg: theme.palette.text.secondary,
      cardBg: "linear-gradient(90deg, #404040 0%, rgba(35,35,35,0.6) 30%)",
      cardBorderColor: "#939393",
    },
  };

  return (
    <>
      {phase?.entryRuleDescription && (
        <TimelineItem sx={{ minHeight: 0 }}>
          <TimelineSeparator sx={{ mt: 2 }}>
            <TimelineDot
              sx={{
                backgroundColor:
                  status === "ended" && index - 1 === currentPhase
                    ? theme.palette.error.main
                    : CONFIG[
                        type === "Completed" || type === "In progress"
                          ? "Completed"
                          : "Locked"
                      ].dotBg,
                mt: 0,
                mb: 2,
              }}
            />
            <TimelineConnector
              sx={{
                backgroundColor:
                  type === "Completed" || type === "In progress"
                    ? theme.palette.success.main
                    : "default",
              }}
            />
          </TimelineSeparator>
          <TimelineContent
            sx={{
              mt: 0,
              mb: 2.5,
              ml: 1.5,
              p: 0,
            }}
          >
            <Box py={1.25}>
              {index === currentPhase + 1 && currentPhaseEndDate ? (
                status === "ended" ? (
                  <Chip
                    size="small"
                    variant="outlined"
                    icon={<ErrorRoundedIcon />}
                    color="error"
                    label="Failed"
                    sx={{ fontWeight: 700 }}
                  />
                ) : (
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <CalendarMonthRoundedIcon fontSize="small" />
                    <Typography fontWeight={700}>
                      {new Date(currentPhaseEndDate)?.toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )}
                    </Typography>
                  </Stack>
                )
              ) : (
                <Chip
                  size="small"
                  variant="outlined"
                  icon={
                    type === "Completed" || type === "In progress" ? (
                      <CheckCircleRoundedIcon />
                    ) : (
                      <LockRoundedIcon />
                    )
                  }
                  color={
                    type === "Completed" || type === "In progress"
                      ? "success"
                      : "default"
                  }
                  label={
                    type === "Completed" || type === "In progress"
                      ? "Passed"
                      : type
                  }
                  sx={{ fontWeight: 700 }}
                />
              )}
              <Typography mt={1}>{phase?.entryRuleDescription}</Typography>
            </Box>
          </TimelineContent>
        </TimelineItem>
      )}
      <TimelineItem sx={{ minHeight: 0 }}>
        <TimelineSeparator sx={{ mt: 2 }}>
          <TimelineDot
            sx={{
              color: CONFIG[type].dotColor,
              backgroundColor: CONFIG[type].dotBg,
              mt: 0,
              mb: 2,
            }}
          />
          {!isLast && (
            <TimelineConnector
              sx={{
                backgroundColor:
                  type === "Completed" ? theme.palette.success.main : "default",
              }}
            />
          )}
        </TimelineSeparator>
        <TimelineContent
          sx={{
            mt: 0,
            mb: isLast ? 0 : 2.5,
            ml: 1.5,
            p: 0,
          }}
        >
          <Box
            sx={{
              borderColor: CONFIG[type].cardBorderColor,
              background: CONFIG[type].cardBg,
              backdropFilter: "blur(2px)",
              borderRadius: "5px !important",
              borderWidth: 1,
              borderStyle: "solid",
              p: 1.25,
            }}
          >
            <Chip
              size="small"
              icon={
                type === "Completed" ? (
                  <CheckCircleRoundedIcon />
                ) : type === "In progress" ? (
                  <AdjustRoundedIcon />
                ) : (
                  <LockRoundedIcon />
                )
              }
              color={
                type === "Completed"
                  ? "success"
                  : type === "In progress"
                  ? "primary"
                  : "default"
              }
              label={type}
              sx={{ mb: 1 }}
            />
            <Typography variant="body2" component="div">
              Daily reward:{" "}
              <strong>
                {phase?.dailyReward} token{phase?.dailyReward > 1 ? "s" : ""}
              </strong>
              <br />
              Duration:{" "}
              <strong>
                {phase?.duration} day{phase?.duration > 1 ? "s" : ""}
              </strong>
              <br />
              Maximum subscriptions: <strong>{phase?.maxSubscriptions}</strong>
              {phase?.maxSubscriptionsPerWallet && (
                <>
                  <br />
                  Maximum subscriptions <strong>per wallet</strong>:{" "}
                  <strong>{phase?.maxSubscriptionsPerWallet}</strong>
                </>
              )}
              {phase?.ruleDescription && (
                <>
                  <br />
                  <strong>{phase?.ruleDescription}</strong>
                </>
              )}
            </Typography>
          </Box>
        </TimelineContent>
      </TimelineItem>
    </>
  );
};

export default Phase;
