import Toolbar from "@mui/material/Toolbar";
import Logo from "../../../components/images/Logo";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import {
  StyledAppBar,
  StyledLogoIcon,
  StyledLogoName,
} from "../../../components/StyledComponents";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import SearchBar from "./SearchBar";
import Box from "@mui/material/Box";
import CustomWalletButton from "../../../components/CustomWalletButton";

interface HeaderProps {
  setMobileOpen: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ setMobileOpen }: HeaderProps) => {
  const location = useLocation();
  const shouldHideMenu = location.pathname.startsWith("/m/");
  const navigate = useNavigate();
  const [searchBarVisible, setSearchBarVisible] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((state) => !state);
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 1, ...(!shouldHideMenu && { display: { md: "none" } }) }}
        >
          <MenuIcon />
        </IconButton>
        <Stack
          flexDirection="row"
          alignItems="center"
          onClick={() => {
            navigate("/");
          }}
          sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
        >
          <StyledLogoIcon>
            <Logo width={22} />
          </StyledLogoIcon>
          <StyledLogoName>ANYBODIES</StyledLogoName>
        </Stack>
        <Box sx={{ flexGrow: 1, mr: 2 }} />
        <SearchBar
          visible={searchBarVisible}
          hide={() => setSearchBarVisible(false)}
        />
        <Box sx={{ flexGrow: 1, ml: 2 }} />
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            sx={{
              display: {
                md: "none",
              },
            }}
            onClick={() => {
              setSearchBarVisible(true);
              setMobileOpen(false);
            }}
          >
            <SearchIcon />
          </IconButton>
          <CustomWalletButton />
        </Stack>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
