import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import Skeleton from "@mui/material/Skeleton";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";

const QuestSkeleton = () => {
  const MISSIONS = 3;
  return (
    <Box pr={{ xs: 1.5, md: 3 }}>
      <Card sx={{ minWidth: 350 }}>
        <Skeleton
          sx={{ width: "100%", height: "auto", aspectRatio: "16/9" }}
          variant="rectangular"
        />
        <CardContent sx={{ p: 1, "&:last-child": { pb: 1 } }}>
          <Typography variant="h6" mb={1}>
            <Skeleton />
          </Typography>
          <Typography>
            <Skeleton sx={{ mb: 0.5 }} />
            <Skeleton />
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Timeline
            sx={{
              m: 0,
              p: 0,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {Array.from(Array(MISSIONS).keys()).map((i) => (
              <TimelineItem sx={{ minHeight: 0 }} key={i}>
                <TimelineSeparator sx={{ mt: 0.5 }}>
                  <Skeleton
                    variant="circular"
                    width={34}
                    height={34}
                    sx={{ mb: 0.5 }}
                  />
                  {i + 1 !== MISSIONS && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    mt: 0,
                    mb: i + 1 === MISSIONS ? 0 : 2.5,
                    ml: 1.5,
                    p: 0,
                  }}
                >
                  <Box
                    sx={{
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#939393",
                      background:
                        "linear-gradient(90deg, #404040 0%, rgba(35,35,35,0.6) 30%)",
                      backdropFilter: "blur(2px)",
                      borderRadius: "5px !important",
                    }}
                  >
                    <Skeleton height={42} />
                  </Box>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </CardContent>
      </Card>
    </Box>
  );
};

export default QuestSkeleton;
