import { useQuery } from "@tanstack/react-query";
import { useHttpsCallable } from "../../../../../services/FirebaseService";
import { RewardListResponse } from "../../../../../types";
import useGetCommunityInfo from "../../info/useGetCommunityInfo";

const useGetRewardsList = () => {
  const path = "rewards/list";
  const { data: communityInfo } = useGetCommunityInfo();
  const fn = useHttpsCallable(path);

  const getRewardsList = async () => {
    const { data } = await fn({
      accountId: communityInfo?.accountId,
      communityId: communityInfo?.communityId,
    });
    const res = data as RewardListResponse;
    if (!res?.success) {
      throw new Error("Error");
    }
    return res?.data;
  };

  return useQuery([path, communityInfo?.communityId], getRewardsList, {
    staleTime: 30 * 60 * 1000,
    enabled: !!communityInfo?.accountId && !!communityInfo?.communityId,
  });
};

export default useGetRewardsList;
