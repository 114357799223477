import CommunityInfo from "./CommunityInfo/CommunityInfo";
import { useEffect, useState } from "react";
import { PageTabs, PageTabsSkeleton } from "./PageTabs";
import { useCommunity } from "../../services/CommunityService";
import MembershipsTab from "./Tabs/MembershipsTab";
import BoostersTab from "./Tabs/BoostersTab";
import ModalSelectNftForSubscription from "../../modals/ModalSelectNft/ModalSelectNftForSubscription";
import ModalLevels from "../../modals/ModalLevels/ModalLevels";
import LoadingModal from "../../modals/LoadingModal";
import ModalEditBooster from "../../modals/ModalSelectNft/ModalEditBooster";
import { useParams } from "react-router-dom";
import useGetCommunityStats from "../../hooks/queries/community/info/useGetCommunityStats";
import Box from "@mui/material/Box";
import LoyaltyTab from "./Tabs/LoyaltyTab";
import RewardDetails from "../../modals/RewardDetails/RewardDetails";
import RewardsTab from "./Tabs/RewardsTab";
import useGetCommunityInfo from "../../hooks/queries/community/info/useGetCommunityInfo";
import ModalCampaignContract from "../../modals/ModalCampaignContract/ModalCampaignContract";

const TABS = {
  Memberships: true,
  Boosters: false,
  Rewards: true,
  Loyalty: false,
};

const CommunityPage = () => {
  const { communityName } = useParams();
  const { data: communityInfo, isLoading: communityInfoLoading } =
    useGetCommunityInfo();
  const { data: communityStats } = useGetCommunityStats();

  const {
    editBoosterModalData,
    setEditBoosterModalData,
    nftModalData,
    setNftModalData,
    contractLevelsModalData,
    setContractLevelsModalData,
    loadingModal,
    setLoadingModal,
    rewardDetailsData,
    setRewardDetailsData,
    campaignContractModalData,
    setCampaignContractModalData,
  } = useCommunity();

  const [tabs, setTabs] = useState({});
  const [activeTab, setActiveTab] = useState("memberships");

  useEffect(() => {
    if (!communityInfo) return;
    const tabs = { ...TABS };
    if (communityInfo?.vaultLegacy?.ShowMultiplier) {
      tabs.Boosters = true;
    }
    if (communityInfo?.hasLoyalty) {
      tabs.Loyalty = true;
    }
    setTabs(tabs);
  }, [communityInfo]);

  useEffect(() => {
    return () => {
      setTabs({});
    };
  }, [communityName]);

  return (
    <>
      <CommunityInfo
        loading={communityInfoLoading}
        data={{
          profile: communityInfo?.profile,
          communityStats,
        }}
      />
      {Object.keys(tabs)?.length ? (
        <PageTabs tabs={tabs} active={activeTab} setActive={setActiveTab} />
      ) : (
        <PageTabsSkeleton />
      )}
      <MembershipsTab isActive={activeTab === "memberships"} />
      <Box display={activeTab === "rewards" ? "block" : "none"}>
        <RewardsTab />
      </Box>
      {communityInfo?.vaultLegacy?.ShowMultiplier && (
        <BoostersTab isActive={activeTab === "boosters"} />
      )}
      {communityInfo?.hasLoyalty && (
        <LoyaltyTab isActive={activeTab === "loyalty"} />
      )}
      <ModalEditBooster
        data={editBoosterModalData}
        hide={() => {
          if (!setEditBoosterModalData) return;
          setEditBoosterModalData(undefined);
        }}
      />
      <ModalSelectNftForSubscription
        data={nftModalData}
        hide={() => {
          if (!setNftModalData) return;
          setNftModalData(undefined);
        }}
      />
      <ModalLevels
        data={contractLevelsModalData}
        hide={() => {
          if (!setContractLevelsModalData) return;
          setContractLevelsModalData(undefined);
        }}
      />
      <ModalCampaignContract
        plan={campaignContractModalData}
        hide={() => {
          if (!setCampaignContractModalData) return;
          setCampaignContractModalData(undefined);
        }}
      />
      <LoadingModal
        data={loadingModal}
        hide={() => {
          if (!setLoadingModal) return;
          setLoadingModal(null);
        }}
      />
      <RewardDetails
        data={rewardDetailsData}
        hide={() => {
          if (!setRewardDetailsData) return;
          setRewardDetailsData(undefined);
        }}
      />
    </>
  );
};

export default CommunityPage;
