import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { CommunityByWalletItem } from "../../../types";
import ListItem from "@mui/material/ListItem";
import {
  DrawerListItemButton,
  EllipsisTypography,
} from "../../../components/StyledComponents";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";

type MenuCommunityListProps = {
  loading: boolean;
  title: string;
  list?: CommunityByWalletItem[];
  currentPath: string;
  hideMenu: () => void;
};

const MenuCommunityList = ({
  loading,
  title,
  list,
  currentPath,
  hideMenu,
}: MenuCommunityListProps) => {
  const navigate = useNavigate();

  if ((!list || !list?.length) && !loading) return null;

  return (
    <>
      <Divider />
      <Typography
        variant="subtitle2"
        fontWeight={600}
        sx={{ mt: 1.5, mb: 0.5, mx: 1.5 }}
      >
        {title}
      </Typography>
      <List sx={{ pt: 0, pb: 1.5 }}>
        {loading ? (
          <>
            <MenuCommunityItemSkeleton />
            <MenuCommunityItemSkeleton />
          </>
        ) : (
          list?.map(({ name, imageUrl, route }) => (
            <ListItem disablePadding key={route}>
              <DrawerListItemButton
                selected={currentPath === `/c/${route}`}
                onClick={() => {
                  hideMenu();
                  navigate(`/c/${route}`);
                }}
              >
                <Avatar src={imageUrl} alt={name} variant="square">
                  <InsertPhotoRoundedIcon sx={{ fontSize: "0.75em" }} />
                </Avatar>
                <EllipsisTypography>{name}</EllipsisTypography>
              </DrawerListItemButton>
            </ListItem>
          ))
        )}
      </List>
    </>
  );
};

export default MenuCommunityList;

const MenuCommunityItemSkeleton = () => {
  return (
    <Stack direction="row" spacing={1.5} px={1.5} py={0.75}>
      <Skeleton
        variant="rounded"
        width={22.28}
        height={22.28}
        sx={{ flexShrink: 0 }}
      />
      <Skeleton width="100%" height={22.28} />
    </Stack>
  );
};
