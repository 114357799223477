import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import { BoostersRowProps } from "./types";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  EllipsisTypography,
  StyledGridTableCell,
} from "../../../../components/StyledComponents";
import { BoosterPlan } from "../../../../types";
import { useCommunity } from "../../../../services/CommunityService";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";

const BoostersRow = ({
  data,
  plan,
  isItemSelected,
  onItemSelect,
  onUnsubscribe,
}: BoostersRowProps) => {
  const { setEditBoosterModalData } = useCommunity();
  return (
    <Box
      sx={{
        display: "contents",
        "&:hover": {
          "& > div": {
            background: (theme) =>
              alpha(
                theme.palette.action.hover,
                theme.palette.action.activatedOpacity
              ),
          },
        },
      }}
      key={data?.id}
    >
      <StyledGridTableCell
        sx={{
          p: 0,
          borderTopLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
          borderBottomLeftRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          onChange={() => {
            onItemSelect(data?.id);
          }}
          disabled={!data?.otherData?.SupportsAutoStaking}
        />
      </StyledGridTableCell>
      <StyledGridTableCell sx={{ justifyContent: "flex-start" }}>
        <Stack direction="row" alignItems="center" spacing={1} maxWidth="100%">
          <Avatar
            src={data?.otherData?.manifest?.image}
            alt={data?.otherData?.manifest?.name}
            sx={{ width: 60, height: 60, flexShrink: 0 }}
            variant="rounded"
          >
            <InsertPhotoRoundedIcon sx={{ fontSize: "3rem" }} />
          </Avatar>
          <EllipsisTypography variant="body2" fontWeight="bold">
            {data?.otherData?.manifest?.name}
          </EllipsisTypography>
        </Stack>
      </StyledGridTableCell>
      <StyledGridTableCell>
        <Typography variant="caption">
          {plan?.PlanTitle ? plan?.PlanTitle : <Skeleton width={100} />}
        </Typography>
      </StyledGridTableCell>
      <StyledGridTableCell>
        <Typography variant="caption">{data?.boosted}</Typography>
      </StyledGridTableCell>
      <StyledGridTableCell>
        <Typography variant="caption">
          {plan?.MultiplierAction ? (
            getBoostInfo(plan?.MultiplierAction, plan?.MultiplierValue)
          ) : (
            <Skeleton width={50} />
          )}
        </Typography>
      </StyledGridTableCell>
      <StyledGridTableCell
        sx={{
          borderTopRightRadius: (theme) => `${theme.shape.borderRadius}px`,
          borderBottomRightRadius: (theme) => `${theme.shape.borderRadius}px`,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            size="small"
            sx={{ whiteSpace: "nowrap" }}
            disabled={!data?.otherData?.SupportsAutoStaking}
            onClick={() => {
              if (!setEditBoosterModalData) return;
              setEditBoosterModalData({ booster: data?.otherData, plan });
            }}
          >
            {data?.boosted ? "Edit booster" : "Activate booster"}
          </Button>
          <Button
            color="error"
            size="small"
            onClick={onUnsubscribe}
            disabled={!data?.otherData?.SupportsAutoStaking || isItemSelected}
          >
            Unsubscribe
          </Button>
        </Stack>
      </StyledGridTableCell>
    </Box>
  );
};

export default BoostersRow;

const getBoostInfo = (
  MultiplierAction: BoosterPlan["MultiplierAction"],
  MultiplierValue: BoosterPlan["MultiplierValue"]
) => {
  switch (MultiplierAction) {
    case "multiplication":
      return (
        <>
          <strong>{MultiplierValue}</strong>x
        </>
      );
    case "addition":
      return (
        <>
          +<strong>{MultiplierValue}</strong>
        </>
      );
    case "fixed":
      return (
        <>
          <strong>{MultiplierValue}</strong> token
          {MultiplierValue > 1 ? "s" : ""} / day
        </>
      );

    default:
      return <></>;
  }
};
