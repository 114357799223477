import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useHttpsCallable } from "../../../../services/FirebaseService";
import { CommunityInfoByRouteResponse } from "../../../../types";
import { useQuery } from "@tanstack/react-query";

const useGetCommunityInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const path = "community/profile";
  const { communityName } = useParams();
  const fbFn = useHttpsCallable(path);

  const getCommunityInfo = async () => {
    const { data } = await fbFn({ route: communityName });
    const res = data as CommunityInfoByRouteResponse;
    if (!res?.success) {
      throw new Error(
        `Community ${communityName} not found. Please check the link. It's most likely incorrect`
      );
    }
    return res;
  };

  return useQuery([path, communityName], getCommunityInfo, {
    staleTime: 30 * 60 * 1000,
    enabled: !!communityName,
    onError: (err: any) => {
      enqueueSnackbar({
        variant: "error",
        message: err?.message || "Something went wrong. Try again, please",
      });
      navigate("/");
    },
  });
};

export default useGetCommunityInfo;
