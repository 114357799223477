import { ViewMode } from "../types";
import ListMode from "./ListMode";
import ExploreMode from "./ExploreMode";
import { useMarketplace } from "../services/Main";
import Box from "@mui/material/Box";

const MarketplaceNftList = () => {
  const { viewMode, nftListBoxRef } = useMarketplace();

  return (
    <Box
      px={1}
      pt={0.5}
      pb={1}
      sx={{ overflowY: { xs: "unset", sm: "auto" } }}
      flexGrow={1}
      ref={nftListBoxRef}
    >
      {viewMode === ViewMode.Explore ? <ExploreMode /> : <ListMode />}
    </Box>
  );
};

export default MarketplaceNftList;
