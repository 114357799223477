import Typography from "@mui/material/Typography";
import Tokens from "./Tokens/Tokens";
import { useWallet } from "@solana/wallet-adapter-react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CustomWalletButton from "../../../components/CustomWalletButton";
import ClaimedRewards from "./ClaimedRewards/ClaimedRewards";
import useGetCommunityTokens from "../../../hooks/queries/community/info/useGetCommunityTokens";
import useGetClaimedRewards from "../../../hooks/queries/community/earnings/state/useGetClaimedRewards";
import { Order } from "../../../types";
import { useEffect, useState } from "react";
import { TokensRowData } from "./Tokens/types";
import useWithdrawMutation from "../../../hooks/queries/community/earnings/mutations/useWithdrawMutation";
import { getDecimalsForInterval } from "../../../utils";
import Empty from "../../../components/Empty/Empty";
import useGetSubscriptions from "../../../hooks/queries/community/subscriptions/state/useGetSubscriptions";

const Earnings = () => {
  const { publicKey } = useWallet();
  const { data: claimedRewards, isLoading: claimedRewardsLoading } =
    useGetClaimedRewards();
  const { data: communityTokens, isLoading: communityTokensLoading } =
    useGetCommunityTokens();
  const { data: subscriptionsInfo, dataUpdatedAt } = useGetSubscriptions();
  const [tokensOrder, setTokensOrder] = useState<Order>("asc");
  const [tokensOrderBy, setTokensOrderBy] =
    useState<keyof TokensRowData>("quantity");
  const [tokensFinalRows, setTokensFinalRows] = useState<
    TokensRowData[] | undefined
  >();

  const { mutateAsync: withdrawMutation } = useWithdrawMutation();

  useEffect(() => {
    if (!tokensFinalRows?.length) return;
    const interval = setInterval(() => {
      setTokensFinalRows((rows) =>
        rows?.map((r) => {
          return {
            ...r,
            quantity: r?.quantity + r?.secondQuantity,
            usdAmount: r?.usdAmount + r?.secondUsdAmount,
          };
        })
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [tokensFinalRows]);

  useEffect(() => {
    if (!subscriptionsInfo) {
      setTokensOrder("asc");
      setTokensOrderBy("quantity");
      setTokensFinalRows(undefined);
      return;
    }

    const incomeDataRows = Object.keys(subscriptionsInfo?.tokenMap)
      ?.filter((tokenAddress) => {
        const tokenInfo = subscriptionsInfo?.tokenMap[tokenAddress];
        return (
          tokenInfo?.totalAccumulatedReward !== 0 ||
          tokenInfo?.amount !== 0 ||
          tokenInfo?.totalBonusMinuteReward !== 0 ||
          tokenInfo?.totalMinuteReward !== 0
        );
      })
      ?.map((tokenAddress) => {
        const tokenInfo = subscriptionsInfo?.tokenMap[tokenAddress];
        const tokenAsset = communityTokens?.[tokenAddress];
        const secondQuantity =
          (tokenInfo?.totalMinuteReward + tokenInfo?.totalBonusMinuteReward) /
          60;
        const secondUsdAmount = tokenAsset?.exchangeData?.value
          ? secondQuantity * tokenAsset?.exchangeData?.value
          : 0;
        const timePassed = (new Date().getTime() - dataUpdatedAt) / 1000;
        const quantity =
          tokenInfo?.amount +
          tokenInfo?.totalAccumulatedReward +
          secondQuantity * timePassed;
        const usdAmount =
          (tokenAsset?.exchangeData?.value
            ? quantity * tokenAsset?.exchangeData?.value
            : 0) +
          secondUsdAmount * timePassed;
        const decimals = getDecimalsForInterval(secondQuantity);
        const decimalsUsd = getDecimalsForInterval(secondUsdAmount);
        return {
          id: tokenAddress,
          asset: {
            label: tokenAsset?.name || "",
            symbol: tokenAsset?.symbol || "",
            img: tokenAsset?.uri || "",
          },
          quantity,
          change: tokenAsset?.exchangeData?.priceChange24h || 0,
          usdAmount,
          secondQuantity,
          secondUsdAmount,
          decimals,
          decimalsUsd,
          poolBalance: tokenInfo?.poolBalance < 0 ? 0 : tokenInfo?.poolBalance,
        };
      })
      .sort((a, b) => (a.quantity > b.quantity ? 1 : -1));
    setTokensFinalRows(incomeDataRows);
  }, [communityTokens, dataUpdatedAt, subscriptionsInfo]);

  const handleRequestSort = (property: keyof TokensRowData) => {
    const isAsc = tokensOrderBy === property && tokensOrder === "asc";
    setTokensOrder(isAsc ? "desc" : "asc");
    setTokensOrderBy(property);
    sortRows(property, isAsc);
  };

  const sortRows = (property: keyof TokensRowData, isAsc: boolean) => {
    if (isAsc) {
      setTokensFinalRows((state) =>
        state?.sort((a, b) => (a[property] > b[property] ? 1 : -1))
      );
    } else {
      setTokensFinalRows((state) =>
        state?.sort((a, b) => (a[property] > b[property] ? -1 : 1))
      );
    }
  };

  return (
    <Box px={{ xs: 1.5, md: 3 }}>
      <Typography variant="h5" fontWeight="bold" mt={3}>
        Earnings
      </Typography>
      {!claimedRewardsLoading &&
      !communityTokensLoading &&
      tokensFinalRows?.length === 0 &&
      claimedRewards?.length === 0 ? (
        <Box my={5}>
          <Empty text="You don't have any earnings" />
        </Box>
      ) : (
        <>
          {publicKey ? (
            <>
              <Tokens
                loading={communityTokensLoading}
                rows={tokensFinalRows}
                sort={{ order: tokensOrder, orderBy: tokensOrderBy }}
                onSort={handleRequestSort}
                withdraw={withdrawMutation}
              />
              <ClaimedRewards />
            </>
          ) : (
            <Box sx={{ p: { xs: 1.5, md: 3 } }}>
              <Typography
                variant="h6"
                fontWeight="bold"
                textAlign="center"
                color="text.secondary"
              >
                Login to gain access
              </Typography>
              <Stack pt={2} alignItems="center">
                <CustomWalletButton variant="gradient" />
              </Stack>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
export default Earnings;
