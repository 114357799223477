import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";

const VerifySuccess = () => {
  return (
    <Alert
      variant="filled"
      severity="success"
      sx={{ mb: 2, textAlign: "left" }}
    >
      <AlertTitle>Success!</AlertTitle>
      <Typography>
        You've been verified!
        <br />
        You can now earn new roles and rewards
      </Typography>
    </Alert>
  );
};

export default VerifySuccess;
